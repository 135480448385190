import { useState } from "react";
import findEmailPasswordStyles from "@components/FindEmailPassword/FindEmailPasswordStyle.module.scss";
import { VERIFICATION_TYPE } from "@utils/constants/api/verificationType";
import { useAuthCode } from "@hooks/user/useAuthCode";
import SingleConfirmModal from "@components/Common/Modal/Dialogs/SingleConfirmModal";
import InputField from "@components/SignUp/InputField";
import AuthCodeInput from "@components/Common/User/AuthCodeInput";
import PhoneNumberInput from "@components/Common/User/PhoneNumberInput";
import { useUserHandler } from "@hooks/user/useUserHandler";
import OverlayLoader from "@components/Common/Loader/OverlayLoader";
const FindPassword = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState("");
  const [formData, setFormData] = useState({
    email: "",
    phoneNumber: "",
    code: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isCheckEmailDuplication, setIsCheckEmailDuplicated] = useState(null);

  const {
    resendCount,
    isPhoneAuthSent,
    time,
    isCheckValid,
    sendAuthCode,
    checkAuthCode,
  } = useAuthCode(VERIFICATION_TYPE.PW_SEARCH);

  const { handleCheckEmailDuplication } = useUserHandler();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      await checkAuthCode({
        phoneNumber: formData.phoneNumber,
        code: formData.code,
        email: formData.email,
      });
    } catch (error) {
      console.log(error);
    } finally {
      setTimeout(() => {
        setIsLoading(false);
      }, 200);
    }
  };

  // 인증번호 전송 버튼 클릭 이벤트
  const handlePhoneAuthSend = () => {
    sendAuthCode({
      email: formData.email,
      phoneNumber: formData.phoneNumber,
    });
    setModalContent(
      <div className={findEmailPasswordStyles.modalContent}>
        <p>인증번호를 발송했습니다.</p>
        <p>인증번호가 오지 않으면 입력하신 정보가</p>
        <p>회원정보와 일치하는지 확인해 주세요.</p>
      </div>
    );
    setIsModalOpen(true);
  };

  const checkIsEmailExist = async () => {
    // TODO : 이메일 존재 여부 체크 api 연동

    try {
      await handleCheckEmailDuplication(formData.email);
      //응답값이 200이면 중복된 이메일이 없음 -> 존재하지 않는 이메일
      setIsCheckEmailDuplicated(true);
    } catch (error) {
      if (error && error.status === 409) {
        //응답값이 409이면 중복된 이메일이 있음 -> 존재하는 이메일
        setIsCheckEmailDuplicated(false);
      }
    }
  };

  return (
    <div className={findEmailPasswordStyles.findEmailFormContainer}>
      <InputField
        name="email"
        value={formData.email}
        onChange={handleChange}
        placeholder="이메일을 입력해 주세요."
        pattern="^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$"
        onBlur={checkIsEmailExist}
        error={
          isCheckEmailDuplication === true
            ? "해당 이메일 계정이 존재하지 않습니다."
            : ""
        }
      />
      <PhoneNumberInput
        phoneNumber={formData.phoneNumber}
        onChange={handleChange}
        onSendAuthCode={handlePhoneAuthSend}
        resendCount={resendCount}
      />

      <AuthCodeInput
        code={formData.code}
        onChange={handleChange}
        onSubmit={handleSubmit}
        isCheckValid={isCheckValid}
        time={time}
        isPhoneAuthSent={isPhoneAuthSent}
        isCheckEmailDuplication={isCheckEmailDuplication}
      />
      <SingleConfirmModal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        children={modalContent}
        cancelText="확인"
      />
      {isLoading === true && <OverlayLoader />}
    </div>
  );
};

export default FindPassword;
