import React, { useState, useEffect, useRef } from "react";
import {
  List,
  CellMeasurer,
  CellMeasurerCache,
  WindowScroller,
  AutoSizer,
} from "react-virtualized";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import style from "@components/DetailRegisteredPlace/DetailRegisteredPlaceStyle.module.scss";
import KeywordManager from "@components/DetailRegisteredPlace/KeywordManager";
import RankingTable from "@components/DetailRegisteredPlace/RankingTable";
import Switch from "@components/Common/Switch/Switch";
import OverlayLoader from "@components/Common/Loader/OverlayLoader";
import { usePlaceHandlers } from "@hooks/place/usePlaceHandlers";
import { KeywordDetailDTO } from "@dto/place/KeywordDetialDTO";

const RegisterFormContainer = ({ registrationPlaceId }) => {
  const { placeId } = useParams();
  const [isRankingTableVisible, setIsRankingTableVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [rankingTableData, setRankingTableData] = useState(
    new KeywordDetailDTO({
      placeInfo: {},
      placeRankList: [],
      keywordRankList: [],
    })
  );
  const membershipData = useSelector((state) => state.membership);

  const { handleGetRegisteredPlaceTableInfo } = usePlaceHandlers();
  const keywordRefs = useRef({}); // 키워드 참조를 저장할 객체

  const handleKeywordClick = (keywordName) => {
    if (keywordRefs.current[keywordName]) {
      keywordRefs.current[keywordName].scrollIntoView({ behavior: "smooth" });
      keywordRefs.current[keywordName].focus();
    }
  };

  useEffect(() => {
    getAndSetRankingTableData();
  }, [placeId]);

  const getAndSetRankingTableData = async () => {
    setIsLoading(true);

    try {
      const data = await handleGetRegisteredPlaceTableInfo({
        placeId,
        registrationPlaceId,
      });
      setRankingTableData(
        new KeywordDetailDTO({
          placeInfo: data.placeInfo,
          placeRankList: data.placeRankList,
          keywordRankList: data.keywordRankList,
        })
      );
    } catch (error) {
      if (error && error.status === 404) {
        setRankingTableData(new KeywordDetailDTO({}));
      } else {
        console.log("🚀 ~ getAndSetRankingTableData ~ error:", error);
      }
    } finally {
      setTimeout(() => {
        setIsLoading(false);
      }, 200);
    }
  };

  const setKeywordRankList = () => {
    if (
      1 === rankingTableData.getKeywordRankList().length &&
      0 === rankingTableData.getKeywordRankList()[0].getDateRankList().length
    ) {
      return [];
    }
    return rankingTableData.getKeywordRankList().map((data) => {
      if (
        !data.getKeywordName() ||
        "" === data.getKeywordName() ||
        null === data.getKeywordName()
      ) {
        return;
      } else {
        return {
          keywordName: data.getKeywordName(),
        };
      }
    });
  };

  const cache = new CellMeasurerCache({
    fixedWidth: true,
    defaultHeight: 200, // 각 테이블의 기본 높이
  });

  const rowRenderer = ({ index, key, parent, style }) => {
    const data = rankingTableData.getKeywordRankList()[index];
    return (
      <CellMeasurer
        key={key}
        cache={cache}
        parent={parent}
        columnIndex={0}
        rowIndex={index}
      >
        {({ measure }) => (
          <div
            style={{
              ...style,
              paddingBottom: "32px",
              boxSizing: "border-box",
            }}
            onLoad={measure}
          >
            <RankingTable
              key={data.getKeywordName() || ""}
              isVisibleMore={isRankingTableVisible}
              toggleAlarm={() => toggleAlarm(data.getKeywordName() || "")}
              keywordRankList={data}
              registrationPlaceId={registrationPlaceId}
              placeInfo={rankingTableData.getPlaceInfo() || {}}
              placeRankList={rankingTableData.getPlaceRankList() || []}
            />
          </div>
        )}
      </CellMeasurer>
    );
  };

  const toggleAlarm = (keywordName) => {
    const keywordRankList = rankingTableData.getKeywordRankList();
    const keywordIndex = keywordRankList.findIndex(
      (data) => data.getKeywordName() === keywordName
    );

    if (keywordIndex !== -1) {
      // 알림 상태를 반전시킵니다.
      const updatedKeyword = {
        ...keywordRankList[keywordIndex],
        notification: !keywordRankList[keywordIndex].getNotification(),
      };

      const updatedKeywordRankList = [
        ...keywordRankList.slice(0, keywordIndex),
        updatedKeyword,
        ...keywordRankList.slice(keywordIndex + 1),
      ];

      setRankingTableData(
        new KeywordDetailDTO({
          placeInfo: rankingTableData.getPlaceInfo() || {},
          placeRankList: rankingTableData.getPlaceRankList() || [],
          keywordRankList: updatedKeywordRankList,
        })
      );
    }
  };

  const registerKeyword = (newKeywordData) => {
    const keywordRankList = rankingTableData.getKeywordRankList() || [];
    const newKeywordDTO = new KeywordDetailDTO(newKeywordData);
    const newKeywordRankList = newKeywordDTO.getKeywordRankList() || [];
    const updatedKeywordRankList = [newKeywordRankList[0], ...keywordRankList];

    // KeywordDetailDTO 객체를 유지하면서 keywordRankList만 업데이트합니다.
    setRankingTableData(
      new KeywordDetailDTO({
        ...rankingTableData,
        keywordRankList: updatedKeywordRankList,
      })
    );
    console.log("🚀 ~ registerKeyword ~ rankingTableData:", rankingTableData);
  };

  const removeKeyword = (keywordName) => {
    const keywordRankList = rankingTableData.getKeywordRankList() || [];

    const keywordIndex = keywordRankList.findIndex(
      (data) => data.getKeywordName() === keywordName
    );

    if (keywordIndex !== -1) {
      // 업데이트된 키워드 리스트를 생성합니다.
      const updatedKeywordRankList = [
        ...keywordRankList.slice(0, keywordIndex),
        ...keywordRankList.slice(keywordIndex + 1),
      ];

      // KeywordDetailDTO 객체를 유지하면서 keywordRankList만 업데이트합니다.
      setRankingTableData(
        new KeywordDetailDTO({
          ...rankingTableData,
          keywordRankList: updatedKeywordRankList,
        })
      );
    }
  };

  const handleSwitch = () => {
    // 랭킹 테이블의 가시성을 토글합니다.
    setIsRankingTableVisible(!isRankingTableVisible);
  };

  if (isLoading) {
    return <OverlayLoader />;
  }

  return (
    <div className={style.registerFormContainer}>
      <KeywordManager
        onRegisterKeyword={registerKeyword}
        keywordRankList={setKeywordRankList()}
        onRemoveKeyword={removeKeyword}
        placeInfo={rankingTableData.getPlaceInfo() || {}}
        onKeywordClick={handleKeywordClick}
      />

      {membershipData.membershipType !== "basic" ? (
        <div className={style.switchBox}>
          <div
            className={
              isRankingTableVisible
                ? style.tagInfoBox
                : style.tagInfoBoxInvisible
            }
          >
            <div className={` ${style.rank}`}>순위</div>
            <div className={` ${style.save}`}>저장</div>
            <div className={` ${style.review}`}>리뷰</div>
            <div className={` ${style.blog}`}>블로그</div>
          </div>
          <Switch isOn={isRankingTableVisible} setIsOn={handleSwitch} />
        </div>
      ) : null}

      <div className={style.rankingTableContainer} style={{ width: "100%" }}>
        <WindowScroller>
          {({ height, isScrolling, scrollTop, onChildScroll }) => (
            <AutoSizer disableHeight>
              {({ width }) => (
                <List
                  autoHeight
                  width={width} // AutoSizer에서 제공하는 너비 사용
                  height={height} // WindowScroller에서 제공하는 높이 사용
                  rowCount={rankingTableData.getKeywordRankList().length}
                  rowHeight={cache.rowHeight} // 동적으로 계산된 높이 사용
                  deferredMeasurementCache={cache} // 캐시 사용
                  rowRenderer={rowRenderer}
                  scrollTop={scrollTop} // WindowScroller에서 제공하는 스크롤 위치 사용
                  onScroll={onChildScroll} // 스크롤 이벤트 핸들러
                  isScrolling={isScrolling} // 스크롤 상태
                  overscanRowCount={5}
                />
              )}
            </AutoSizer>
          )}
        </WindowScroller>
      </div>
    </div>
  );
};

export default RegisterFormContainer;
