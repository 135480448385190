import { useNavigate } from "react-router-dom";
import URL_PATH from "@utils/constants/paths/routes";
import RANKING_SEARCH_TYPE from "@utils/constants/place/rangkintSearch";

export function useAppNavigate() {
  const navigate = useNavigate();

  const goToHome = () => {
    console.info("goToHome");
    navigate(URL_PATH.home, { replace: true });
  };

  const goToLogin = (isReplace = false) =>
    navigate(URL_PATH.login, { replace: isReplace });

  const goToEmailLogin = (isReplace = false) =>
    navigate(URL_PATH.emailLogin, { replace: isReplace });

  const goToSignup = (isReplace = false) =>
    navigate(URL_PATH.signup, { replace: isReplace });

  const goToAddPhoneNumber = (isReplace = false) =>
    navigate(URL_PATH.addPhoneNumber, { replace: isReplace });

  const goToSignupComplete = (isReplace = true) =>
    navigate(URL_PATH.signupComplete, { replace: isReplace });

  const goToFindEmailPassword = (isReplace = false) =>
    navigate(URL_PATH.findEmailPassword, { replace: isReplace });

  const goToFindEmailComplete = (isReplace = true) =>
    navigate(URL_PATH.findEmailComplete, { replace: isReplace });

  const goToFindPasswordComplete = (isReplace = true) =>
    navigate(URL_PATH.findPasswordComplete, { replace: isReplace });

  const goToRankingSearch = ({
    rankingSearchType = RANKING_SEARCH_TYPE.DEFAULT,
    keyword = "",
    isReplace = true,
  }) => {
    // 키워드가 누락된 경우 url만 검색 못함 그러므로 다시 기본페이지(키워드+ 이름 검색창)로 이동
    if (!keyword) {
      rankingSearchType = RANKING_SEARCH_TYPE.DEFAULT;
    }
    navigate(
      URL_PATH.rankingSearch
        .replace(":rankingSearchType", rankingSearchType)
        .replace(":keyword", keyword),
      { replace: isReplace }
    );
  };

  const goToRankingSearchResult = ({
    isReplace = false,
    keyword,
    placeNameOrUrl,
    limit,
    page,
  }) => {
    // URL이 이미 인코딩된 상태인지 확인
    const isEncoded = (str) => {
      try {
        return str !== decodeURIComponent(str);
      } catch (e) {
        return false;
      }
    };

    const encodedPlaceNameOrUrl = isEncoded(placeNameOrUrl)
      ? placeNameOrUrl
      : encodeURIComponent(placeNameOrUrl);

    const url = URL_PATH.rankingSearchResult
      .replace(":placeNameOrUrl", encodedPlaceNameOrUrl)
      .replace(":limit", limit)
      .replace(":page", page)
      .replace(":keyword", keyword || "");

    console.log("Navigating to:", url); // 디버깅을 위한 콘솔 출력

    navigate(url, { replace: isReplace });
  };

  const goToRegisteredPlace = (isReplace = true) =>
    navigate(URL_PATH.registeredPlace, { replace: isReplace });

  const goToRegisteredPlaceDetail = ({
    placeId,
    registrationPlaceId,
    isReplace = false,
  }) =>
    navigate(
      URL_PATH.registeredPlaceDetail
        .replace(":placeId", placeId)
        .replace(":registrationPlaceId", registrationPlaceId),
      {
        replace: isReplace,
      }
    );

  const goToMembership = (isReplace = true) =>
    navigate(URL_PATH.membership, { replace: isReplace });

  const goToMyMembership = (isReplace = true) =>
    navigate(URL_PATH.myMembership, { replace: isReplace });

  const goToModifyMemberInfo = (isReplace = true) =>
    navigate(URL_PATH.modifyMemberInfo, { replace: isReplace });

  const goToCompleteDeleteAccount = (isReplace = true) =>
    navigate(URL_PATH.completeDeleteAccount, { replace: isReplace });
  const goToExitSurvey = (isReplace = true) =>
    navigate(URL_PATH.exitSurvey, { replace: isReplace });

  const goToFirstLoginBenefits = (isReplace = true) =>
    navigate(URL_PATH.firstLoginBenefits, { replace: isReplace });

  const goToServerError = (isReplace = true) =>
    navigate(URL_PATH.serverError, { replace: isReplace });

  const goToNotFound = (isReplace = true) =>
    navigate(URL_PATH.notFound, { replace: isReplace });

  const goToPrivacyPolicy = () => window.open(URL_PATH.privacyPolicy, "_blank");

  const goToMarketingConsent = () =>
    window.open(URL_PATH.marketingConsent, "_blank");

  const goToTermsOfService = () =>
    window.open(URL_PATH.termsOfService, "_blank");

  const goToPrivacyHandlePolicy = () =>
    window.open(URL_PATH.privacyHandlePolicy, "_blank");

  const goToCompleteDeleteMembership = (isReplace = true) =>
    navigate(URL_PATH.completeDeleteMembership, { replace: isReplace });

  return {
    goToHome,
    goToLogin,
    goToEmailLogin,
    goToSignup,
    goToSignupComplete,
    goToFindEmailPassword,
    goToFindEmailComplete,
    goToFindPasswordComplete,
    goToRankingSearch,
    goToRankingSearchResult,
    goToRegisteredPlace,
    goToRegisteredPlaceDetail,
    goToMembership,
    goToMyMembership,
    goToModifyMemberInfo,
    goToCompleteDeleteAccount,
    goToExitSurvey,
    goToFirstLoginBenefits,
    goToServerError,
    goToPrivacyHandlePolicy,
    goToTermsOfService,
    goToPrivacyPolicy,
    goToMarketingConsent,
    goToCompleteDeleteMembership,
  };
}
