import React, { useState, useContext } from "react";
import loginStyle from "@components/Login/LoginStyle.module.scss";
import SingleConfirmModal from "@components/Common/Modal/Dialogs/SingleConfirmModal";
import { emailLogin } from "@utils/api/login/login";
import { VALIDATIONS } from "@utils/constants/validation/fieldValidations";
import { useDispatch } from "react-redux";
import { setMembershipData } from "@utils/functions/membership/membership";
import AuthContext from "@utils/contexts/AuthContext";
import { useAppNavigate } from "@hooks/navigate/navigate";
import { API_ERROR_CODE } from "@utils/constants/api/errorCode";
const LoginForm = () => {
  const dispatch = useDispatch();
  const { goToRankingSearch, goToFirstLoginBenefits } = useAppNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loginAttempts, setLoginAttempts] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { isLoggedIn, login, logout } = useContext(AuthContext);

  function getModalMessage(loginAttempts) {
    if (!loginAttempts || loginAttempts === 0) {
      return "아이디 및 비밀번호가 일치하지 않습니다.";
    }
    if (loginAttempts >= 5) {
      return (
        <>
          로그인 횟수가 만료되었습니다.(5/5)
          <br />
          아이디 및 비밀번호 찾기를 통해 재설정 해주세요.
        </>
      );
    }
    return `아이디 및 비밀번호가 일치하지 않습니다.(${loginAttempts}/5)`;
  }

  const handleLogin = async (e) => {
    e.preventDefault();

    const form = e.target.form;
    // form 내의 모든 입력값이 유효한지 검사
    if (!form.checkValidity()) {
      form.reportValidity(); // 유효하지 않은 입력에 대해 사용자에게 알림
      return;
    }

    try {
      const userData = await emailLogin({ email, password });
      console.log("Login successful", userData);
      await setMembershipData(userData, dispatch);
      login();
      navigateAfterLogin(userData.isFirstLogin);
    } catch (error) {
      console.error("Login failed", error);
      await handleError(error.response.data);
    }
  };

  const navigateAfterLogin = (isFirstLogin) => {
    if (isFirstLogin) {
      goToFirstLoginBenefits(true);
    } else {
      goToRankingSearch({ isReplace: true });
    }
  };

  const errorHandlers = {
    401: (errorData) => handle401Error(errorData),
    400: (errorData) => handle400Error(errorData),
    429: (errorData) => handle429Error(errorData),
    default: () => {
      console.log("로그인에 실패했습니다 다시 시도해주세요.");
    },
  };

  const handleError = async (error) => {
    console.log("🚀 ~ handleError ~ error:", error);
    if (error && error.status) {
      const handler = errorHandlers[error.status];
      if (handler) {
        await handler(error); // error.data를 인자로 전달하여 처리 함수 호출
      }
    } else if (error && error.customMessage) {
      alert(error.customMessage);
    } else {
      console.log("알 수 없는 오류가 발생했습니다.");
    }
  };

  const handle401Error = async (error) => {
    console.log("🚀 ~ handle401Error ~ error:", error);
    const loginAttemptCount = error?.count || 0; // errorData가 undefined일 경우 0으로 설정
    console.log("🚀 ~ handle401Error ~ loginAttemptCount:", loginAttemptCount);
    setLoginAttempts(loginAttemptCount);
    setIsModalOpen(true);
  };

  const handle400Error = (errorData) => {
    console.log("🚀 ~ handle400Error ~ errorData:", errorData);
    if (API_ERROR_CODE.INVALID_EMAIL_FORMAT === errorData.errors[0].errorCode) {
      console.log(
        "🚀 ~ handle400Error ~ errorData.errors[0].code:",
        errorData.errors[0].code
      );
      alert("이메일 형식이 올바르지 않습니다.");
    } else {
      alert("필수 항목을 모두 입력해 주세요.");
    }
    console.error("Login failed", errorData);
  };

  const handle429Error = () => {
    setLoginAttempts(5);
    setIsModalOpen(true);
  };

  return (
    <form className={loginStyle.loginCommonContainer}>
      <input
        type="email"
        value={email}
        className={loginStyle.loginFormInput}
        placeholder="이메일"
        onChange={(e) => setEmail(e.target.value)}
        required
      />
      <input
        type="password"
        value={password}
        className={loginStyle.loginFormInput}
        placeholder="비밀번호"
        onChange={(e) => setPassword(e.target.value)}
        required
        pattern={VALIDATIONS.password.pattern}
      />
      <button
        type="submit"
        className={`${loginStyle.emailLoginButton} ${loginStyle.commonLoginButtons}`}
        onClick={handleLogin}
      >
        로그인
      </button>
      <SingleConfirmModal
        open={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
        }}
        title={getModalMessage(loginAttempts)}
        cancelText="확인"
      ></SingleConfirmModal>
    </form>
  );
};

export default LoginForm;
