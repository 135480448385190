import { defaultAxiosInstance } from "@utils/api/axios/defaultInstance";
import { loginAxiosInstance } from "@utils/api/axios/loginInstance";
import API_PATH from "@utils/constants/api/apiPath";

export const refreshAccessToken = async () => {
  console.info("refreshAccessToken");
  try {
    console.log("🚀 ~ refreshAccessToken ~ refreshAccessToken:");
    const response = await defaultAxiosInstance.get(
      API_PATH.refreshAccessToken
    );
    console.log("🚀 ~ refreshAccessToken ~ response:", response);
    return response.data;
  } catch (error) {
    console.error("🚀 ~ refreshAccessToken ~ error:", error);
  }
};

export const checkEmailDuplication = async (email) => {
  try {
    console.log("🚀 ~ checkEmailDuplication ~ email:", email);
    const response = await loginAxiosInstance.post(
      API_PATH.checkEmailDuplication,
      { email: email }
    );
    return response.data;
  } catch (error) {
    console.error("🚀 ~ checkEmailDuplication ~ error:", error);
    throw error;
  }
};

export const signupByEmail = async ({
  email,
  password,
  name,
  birthdate,
  phoneNumber,
}) => {
  try {
    console.log(
      "🚀 ~ signupByEmail ~ signupByEmail:",
      email,
      password,
      name,
      birthdate,
      phoneNumber
    );
    const response = await loginAxiosInstance.post(API_PATH.signupByEmail, {
      email: email,
      password: password,
      name: name,
      birthdate: birthdate,
      phoneNumber: phoneNumber,
    });
    return response.data;
  } catch (error) {
    console.error("🚀 ~ signupByEmail ~ error:", error);
    throw error;
  }
};

export const sendAuthCode = async ({
  name = null,
  email = null,
  phoneNumber,
  verificationType,
}) => {
  try {
    const response = await loginAxiosInstance.post(
      API_PATH.sendVerificationCode,
      {
        name,
        email,
        phoneNumber,
        verificationType,
      }
    );
    return response.data;
  } catch (error) {
    console.error("🚀 ~ sendAuthCode ~ error:", error);
    throw error;
  }
};

export const checkAuthCode = async ({
  phoneNumber,
  verificationType,
  verificationCode,
  name = null,
  email = null,
}) => {
  try {
    const response = await loginAxiosInstance.post(API_PATH.verifyCode, {
      phoneNumber,
      verificationType,
      verificationCode,
      name,
      email,
    });
    return response.data;
  } catch (error) {
    console.error("🚀 ~ sendAuthCode ~ error:", error);
    throw error;
  }
};

export const sendAuthCodeForPhoneChange = async ({
  phoneNumber,
  newPhoneNumber,
  verificationType,
}) => {
  try {
    const response = await loginAxiosInstance.post(
      API_PATH.sendAuthCodeForPhoneChange,
      {
        phoneNumber,
        newPhoneNumber,
        verificationType,
      }
    );
    return response.data;
  } catch (error) {
    console.error("🚀 ~ sendAuthCode ~ error:", error);
    throw error;
  }
};

export const checkAuthCodeForPhoneChange = async ({
  phoneNumber,
  newPhoneNumber,
  verificationType,
  verificationCode,
}) => {
  console.log("🚀 ~ verificationType:", verificationType);
  console.log("🚀 ~ phoneNumber:", phoneNumber);
  console.log("🚀 ~ newPhoneNumber:", newPhoneNumber);
  console.log("🚀 ~ verificationCode:", verificationCode);
  try {
    const response = await loginAxiosInstance.post(
      API_PATH.checkAuthCodeForPhoneChange,
      {
        phoneNumber,
        newPhoneNumber,
        verificationType,
        verificationCode,
      }
    );
    console.log("🚀 ~ response:", response);
    return response.data;
  } catch (error) {
    console.error("🚀 ~ sendAuthCode ~ error:", error);
    throw error;
  }
};

export const changeUserInfo = async ({
  currentPassword = null,
  newPassword = null,
  phoneNumber = null,
}) => {
  try {
    const response = await loginAxiosInstance.put(API_PATH.changeUserInfo, {
      currentPassword,
      newPassword,
      phoneNumber,
    });
    return response.data;
  } catch (error) {
    console.error("🚀 ~ changeUserInfo ~ error:", error);
    throw error;
  }
};

export const exitUserReason = async ({ index, reason }) => {
  try {
    const response = await loginAxiosInstance.post(API_PATH.exitUserReason, {
      index,
      reason,
    });
    return response.data;
  } catch (error) {
    console.error("🚀 ~ exitUserReason ~ error:", error);
    throw error;
  }
};
