import { useState } from "react";
import InputField from "@components/SignUp/InputField";
import { VALIDATIONS } from "@utils/constants/validation/fieldValidations";
import SingleConfirmModal from "@components/Common/Modal/Dialogs/SingleConfirmModal";
import { VERIFICATION_TYPE } from "@utils/constants/api/verificationType";
import { useAuthCode } from "@hooks/user/useAuthCode";
import findEmailPasswordStyles from "@components/FindEmailPassword/FindEmailPasswordStyle.module.scss";
import AuthCodeInput from "@components/Common/User/AuthCodeInput";
import PhoneNumberInput from "@components/Common/User/PhoneNumberInput";

const FindEmailForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    phoneNumber: "",
    code: "",
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState("");

  const {
    resendCount,
    isPhoneAuthSent,
    time,
    isCheckValid,
    sendAuthCode,
    checkAuthCode,
  } = useAuthCode(VERIFICATION_TYPE.EMAIL_SEARCH);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = () => {
    checkAuthCode({
      name: formData.name,
      phoneNumber: formData.phoneNumber,
      code: formData.code,
    });
  };

  // 인증번호 전송 버튼 클릭 이벤트
  const handlePhoneAuthSend = () => {
    sendAuthCode({
      name: formData.name,
      phoneNumber: formData.phoneNumber,
    });
    setModalContent(
      <div className={findEmailPasswordStyles.modalContent}>
        <p>인증번호를 발송했습니다.</p>
        <p>인증번호가 오지 않으면 입력하신 정보가</p>
        <p>회원정보와 일치하는지 확인해 주세요.</p>
      </div>
    );
    setIsModalOpen(true);
  };

  return (
    <div className={findEmailPasswordStyles.findEmailFormContainer}>
      <InputField
        name="name"
        value={formData.name}
        onChange={handleChange}
        placeholder="이름"
        pattern={VALIDATIONS.name.pattern}
      />
      <PhoneNumberInput
        phoneNumber={formData.phoneNumber}
        onChange={handleChange}
        onSendAuthCode={handlePhoneAuthSend}
        resendCount={resendCount}
      />

      <AuthCodeInput
        code={formData.code}
        onChange={handleChange}
        onSubmit={handleSubmit}
        isCheckValid={isCheckValid}
        time={time}
        isPhoneAuthSent={isPhoneAuthSent}
      />
      <SingleConfirmModal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        children={modalContent}
        cancelText="확인"
      />
    </div>
  );
};

export default FindEmailForm;
