import findEmailPasswordStyles from "@components/FindEmailPassword/FindEmailPasswordStyle.module.scss";

const SwitchButtons = ({
  activeButtonName = "email",
  onTabChange,
  isFindEmailComplete = false,
}) => {
  return (
    <div className={findEmailPasswordStyles.switchContainer}>
      <button
        className={`${findEmailPasswordStyles.switchButton} ${
          activeButtonName === "email" ? findEmailPasswordStyles.active : ""
        }`}
        onClick={() => onTabChange("email")}
      >
        이메일 찾기
      </button>
      {!isFindEmailComplete && (
        <button
          className={`${findEmailPasswordStyles.switchButton} ${
            activeButtonName === "password"
              ? findEmailPasswordStyles.active
              : ""
          }`}
          onClick={() => onTabChange("password")}
        >
          비밀번호 찾기
        </button>
      )}
    </div>
  );
};

export default SwitchButtons;
