import style from "@components/DetailRegisteredPlace/DetailRegisteredPlaceStyle.module.scss";
import { useState, useEffect } from "react";
import { usePlaceHandlers } from "@hooks/place/usePlaceHandlers";
import { PlaceInfoDTO } from "@dto/place/DefaultPlaceInfoDTO";
import { useParams } from "react-router-dom";
import PropTypes from "prop-types";
import noImage from "@assets/images/no_image.png";
import textAnimation from "@assets/styles/textAnimation.module.scss";

const PlaceInfoFetcher = ({ setRegisterPlaceId }) => {
  const { registrationPlaceId } = useParams();
  const animationStyle = {
    "--animation-duration": "0.8s",
  };
  console.log(
    "🚀 ~ PlaceInfoFetcher ~ registrationPlaceId:",
    registrationPlaceId
  );
  const { handleGetRegisteredPlaceDetail } = usePlaceHandlers();
  const [placeDetail, setPlaceDetail] = useState(new PlaceInfoDTO({}));

  useEffect(() => {
    getAndSetPlaceDetail();
  }, [registrationPlaceId]);

  const getAndSetPlaceDetail = async () => {
    try {
      const data = await handleGetRegisteredPlaceDetail({
        registrationPlaceId,
      });
      console.log("🚀 ~ getAndSetPlaceDetail ~ data:", data);
      setRegisterPlaceId(data.registrationPlaceId); // get 메서드로 데이터 가져오지 않는 이유는  placeDetail이 아직 업데이트 되지 않았을 수 있기 때문
      setPlaceDetail(new PlaceInfoDTO(data));
    } catch (error) {
      if (error.response.status === 404) {
        setPlaceDetail(new PlaceInfoDTO({}));
      } else {
        console.log("🚀 ~ getAndSetPlaceDetail ~ error:", error);
      }
    }
  };

  return (
    <div
      className={`${textAnimation.fadeIn} ${style.placeInfoFetcherBox}`}
      style={animationStyle}
    >
      {placeDetail.getImageUrl() ? (
        <img
          src={placeDetail.getImageUrl() || noImage}
          alt={placeDetail.getName() || "noImage"}
          className={style.registerStoreCardImage}
        />
      ) : (
        <img
          src={noImage}
          alt="noImage"
          className={style.registerStoreCardImage}
        />
      )}

      <div className={style.placeInfoFetcherContent}>
        <div className={style.placeInfoFetcherContentHeader}>
          <span className={style.name}>
            {placeDetail.getName() || "이름 없음"}
          </span>
          <span className={style.category}>
            {placeDetail.getCategory() || "카테고리 없음"}
          </span>
        </div>

        <div className={style.placeInfoFetcherContentMiddle}>
          <div className={style.placeInfoFetcherContentMiddleItem}>
            방문자 리뷰
            <span>{placeDetail.getVisitorReviewCount() || 0}</span>
          </div>

          <div className={style.placeInfoFetcherContentMiddleItem}>
            블로그 리뷰
            <span>{placeDetail.getBlogReviewCount() || 0}</span>
          </div>

          <div className={style.placeInfoFetcherContentMiddleItem}>
            저장수
            <span>{placeDetail.getSaveCount() || 0}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

PlaceInfoFetcher.propTypes = {
  setRegisterPlaceId: PropTypes.func.isRequired,
};

export default PlaceInfoFetcher;
