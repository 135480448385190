export class BasePlaceInfo {
  constructor({
    placeId = "",
    name = "이름 없음",
    category = "카테고리 없음",
    url = "",
    imageUrl = null,
    blogReviewCount = 0,
    visitorReviewCount = 0,
    saveCount = "0",
    registrationDate = "날짜 없음",
  }) {
    this.placeId = placeId;
    this.name = name;
    this.category = category;
    this.url = url;
    this.imageUrl = imageUrl;
    this.blogReviewCount = parseInt(blogReviewCount) || 0;
    this.visitorReviewCount = parseInt(visitorReviewCount) || 0;
    this.saveCount = saveCount;
    this.registrationDate = registrationDate;
  }

  // GET methods
  getPlaceId() {
    return this.placeId;
  }

  getName() {
    return this.name;
  }

  getCategory() {
    return this.category;
  }

  getUrl() {
    return this.url;
  }

  getImageUrl() {
    return this.imageUrl;
  }

  getBlogReviewCount() {
    return this.blogReviewCount;
  }

  getVisitorReviewCount() {
    return this.visitorReviewCount;
  }

  getSaveCount() {
    return this.saveCount;
  }

  getRegistrationDate() {
    return this.registrationDate;
  }

  // SET methods
  setPlaceId(value) {
    this.placeId = value;
  }

  setName(value) {
    this.name = value;
  }

  setCategory(value) {
    this.category = value;
  }

  setUrl(value) {
    this.url = value;
  }

  setImageUrl(value) {
    this.imageUrl = value;
  }

  setBlogReviewCount(value) {
    this.blogReviewCount = value;
  }

  setVisitorReviewCount(value) {
    this.visitorReviewCount = value;
  }

  setSaveCount(value) {
    this.saveCount = value;
  }

  setRegistrationDate(value) {
    this.registrationDate = value;
  }
}
