import style from "@components/RankingSearch/RankingSearchStyle.module.scss";
import StoreInfoCard from "@components/RankingSearch/StoreInfoCard";
import { MonthlyCountDTO } from "@dto/place/MonthlyCountDTO";
import { SearchResultPlaceDTO } from "@dto/place/SearchPlaceListDTO";
import PropTypes from "prop-types";
import React from "react";

const StoreInfoCardList = ({
  placeList,
  monthlyCount,
  functionType,
  isLoading,
}) => {
  // TODO 데이터 받아오기
  // 페이징 처리후 ui 재포딩 조건 추가
  // 더미 데이터 정의

  if (!isLoading && (!placeList || placeList.length === 0)) {
    return (
      <div className={style.storeInfoCardBox}>
        <div className={style.noDataInfoCard}>
          해당 플레이스 정보가 존재하지 않습니다.
        </div>
      </div>
    );
  }
  return (
    <div className={style.storeInfoCardBox}>
      <div className={style.storeInfoCardList}>
        {placeList.map((place) => (
          <StoreInfoCard
            key={place.id}
            placeInfo={place}
            monthlyCount={monthlyCount}
            functionName={functionType}
          />
        ))}
      </div>
    </div>
  );
};

StoreInfoCardList.propTypes = {
  placeList: PropTypes.arrayOf(PropTypes.instanceOf(SearchResultPlaceDTO)),
  monthlyCount: PropTypes.instanceOf(MonthlyCountDTO),
  functionType: PropTypes.string,
};

export default React.memo(StoreInfoCardList);
