import MypageStyle from "@components/Mypage/MyMembership/MymbershipStyle.module.scss";
import UsingMembershipCard from "@components/Mypage/MyMembership/UsingMembershipCard";
import UpgradeMembershipCard from "@components/Mypage/MyMembership/UpgradeMembershipCard";
import { MEMBERSHIP_DATA } from "@utils/constants/membership/membership.js";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import Loader from "@components/Common/Loader/Loader";
import { setMembershipDataKeyForUpgrade } from "@utils/functions/membership/membership.js";

const MyMembershipCardList = () => {
  console.log("🚀 ~ MembershipCardList ~ MembershipCardList:");
  console.log("🚀 ~ MEMBERSHIP_DATA:", MEMBERSHIP_DATA);

  const membershipData = useSelector((state) => state.membership);
  const membershipDataKey = setMembershipDataKeyForUpgrade(
    membershipData.subscriptionType
  );
  console.log("🚀 ~ MyMembershipCardList ~ membershipData:", membershipData);

  useEffect(() => {}, []);

  if (!membershipData.userId) {
    console.log(
      "🚀 ~ MembershipCardList ~ membershipData.userId:",
      membershipData.userId
    );

    return <Loader />;
  }

  const shouldShowUpgradeCard = (membershipData) => {
    // 무료 체험 기간이거나, 구독 타입이 'premium'인 경우 업그레이드 카드 숨김
    return (
      "premium" !== membershipData.subscriptionType &&
      false === membershipData.isFreeTrial
    );
  };

  const setPayCostForAnnouncement = () => {
    if (true === membershipData.isFreeTrial) {
      // 무료 체험인 경우 현재 멤버십 가격 표시
      return MEMBERSHIP_DATA[membershipData.subscriptionType].membershipCost;
    } else if (
      membershipData.subscriptionType !== "premium" &&
      membershipData.subscriptionType !== "owner"
    ) {
      // 현재 구독 중인 멤버십이 있는 경우 한 단계 업그레이드된 멤버십 가격 표시
      return MEMBERSHIP_DATA[membershipDataKey].membershipCost;
    }
    // 'premium'인 경우 금액 표시 안함
    return null;
  };

  return (
    <div className={MypageStyle.membershipCardList}>
      <div className={MypageStyle.membershipCardListBox}>
        <div className={MypageStyle.usingMembershipBox}>
          {
            // 무료 이용기간이 아니고 구독정보가 있어야만 '이용 중인 멤버십' 표시
            false === membershipData.isFreeTrial &&
            membershipData.subscriptionType ? (
              <span className={MypageStyle.membershipText}>
                이용 중인 멤버십
              </span>
            ) : null
          }

          {/* 구독정보가 없을 때는 현재 이용중인 멤버십이 없습니다 문구 반환 */}
          <UsingMembershipCard
            isSubscribe={!!membershipData.subscriptionType}
            // free 회원은 구독중 아님
            isFreeTrial={membershipData.isFreeTrial || false}
            subscriptionType={membershipData.subscriptionType}
            startDate={membershipData.startDate || ""}
            endDate={membershipData.endDate || ""}
          />
        </div>

        {shouldShowUpgradeCard(membershipData) ? (
          <div className={MypageStyle.changeMembershipBox}>
            <span className={MypageStyle.membershipText}>추천 멤버십</span>
            <UpgradeMembershipCard
              subscriptionType={membershipData.subscriptionType}
            />
          </div>
        ) : null}
      </div>

      {
        // 무료 체험이거나 'free'인 경우 혜택 안내 문구 표시
        membershipData.isFreeTrial === true ||
        !membershipData.subscriptionType ? (
          <span className={MypageStyle.freeTrialText}>
            25,900원으로 구독하여 혜택을 받아보세요!
          </span>
        ) : null
      }

      {setPayCostForAnnouncement() ? (
        <div className={MypageStyle.textBox}>
          <span className={MypageStyle.membershipCostText}>
            {"최종 월별 결제금액 "}
            <span className={MypageStyle.membershipCost}>
              {setPayCostForAnnouncement()}원
            </span>
          </span>
          <span>
            *맴버십을 변경할 경우 변경 및 결제가 즉시 적용됩니다. <br />
          </span>
          <span>
            *이전 멤버십은 이용 기간 시작 달 기준으로 일자를 측정하여 <br />
            사용하지 않은 일자를 계산해 카드사로 2-5일 이내로 환불됩니다.
            <br />
          </span>
          <span>
            *모든 멤버십은 월 구독 상품으로 중도해지가 되지 않을 시 매달
            정기결제가 이루어집니다.
          </span>
        </div>
      ) : null}
    </div>
  );
};

export default MyMembershipCardList;
