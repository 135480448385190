import { MEMBERSHIP_DATA } from "@utils/constants/membership/membership";

/**
 * 구독 중인 멤버십과 현재 등록된 플레이스 수를 기반으로
 * 남은 등록 가능한 플레이스 수를 계산합니다.
 *
 * @param {string} subscribedMembership - 구독 중인 멤버십 유형
 * @param {number} currentPlaceCount - 현재 등록된 플레이스 수
 * @returns {number} - 남은 등록 가능한 플레이스 수
 */
export const getAvailablePlaceCount = (
  subscribedMembership,
  currentPlaceCount
) => {
  console.log("🚀 ~ currentPlaceCount:", currentPlaceCount);
  console.log("🚀 ~ subscribedMembership:", subscribedMembership);

  // 구독 중인 멤버십이 없으면 0을 반환합니다.
  if (!subscribedMembership) {
    return 0;
  }

  // 'free' 멤버십은 'standard' 멤버십으로 처리합니다.
  if ("free" === subscribedMembership) {
    console.log("🚀 ~ subscribedMembership:", subscribedMembership);
    subscribedMembership = "standard";
  }

  // 구독 중인 멤버십의 최대 등록 가능한 플레이스 수를 가져옵니다.
  const maxPlaceCount = MEMBERSHIP_DATA[subscribedMembership].placeCount;

  // 남은 등록 가능한 플레이스 수를 계산하여 반환합니다.
  return Math.max(0, maxPlaceCount - currentPlaceCount);
};

/**
 * 구독 중인 멤버십과 저장소 데이터를 기반으로
 * 키워드 사용 정보를 계산합니다.
 *
 * @param {string} subscribedMembership - 구독 중인 멤버십 유형
 * @param {Array} storeData - 저장소 데이터 배열
 * @returns {Object} - 키워드 사용 정보 (총 사용된 키워드 수, 총 등록 가능한 키워드 수, 남은 키워드 수)
 */
export const getKeywordUsage = (subscribedMembership, storeData) => {
  // 구독 중인 멤버십이 없으면 기본값을 반환합니다.
  if (!subscribedMembership) {
    return {
      totalKeywordsUsed: 0,
      totalKeywordsAvailable: 0,
      remainingKeywords: 0,
    };
  }

  // 저장소 데이터에서 사용된 키워드의 총 개수를 계산합니다.
  const totalKeywordsUsed = storeData.reduce(
    (total, store) => total + store.keywordRankArray.length,
    0
  );

  // 구독 중인 멤버십의 총 등록 가능한 키워드 수를 가져옵니다.
  const totalKeywordsAvailable =
    MEMBERSHIP_DATA[subscribedMembership].keywordCount *
    MEMBERSHIP_DATA[subscribedMembership].placeCount;

  // 남은 등록 가능한 키워드 수를 계산합니다.
  const remainingKeywords = Math.max(
    0,
    totalKeywordsAvailable - totalKeywordsUsed
  );

  // 키워드 사용 정보를 객체 형태로 반환합니다.
  return {
    totalKeywordsUsed,
    totalKeywordsAvailable,
    remainingKeywords,
  };
};
