import ErrorStyle from "@pages/PageError/error.module.scss";
const NotFound = () => {
  return (
    <div className={ErrorStyle.notFound}>
      <h1 className={ErrorStyle.notFoundTitle}>500</h1>
      <h2 className={ErrorStyle.subtitle}> 잠시 후 다시 확인해주세요!</h2>
      <span className={ErrorStyle.notFoundSubTitle}>
        잠깐 문제가 발생했어요!
        <br />
        새로고침하거나, 나중에 다시 시도해 주세요.
      </span>
    </div>
  );
};

export default NotFound;
