import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  subscriptionType: null, // 사용자 데이터 초기값
  loading: true, // 로딩 상태 초기값
  error: null, // 에러 상태 초기값
  isFreeTrial: false,
  startDate: null,
  endDate: null,
  userId: null,
  name: null,
  email: null,
  phoneNumber: null,
};

const membershipSlice = createSlice({
  name: "membership", // 슬라이스의 이름
  initialState, // 이 슬라이스의 초기 상태
  reducers: {
    fetchMembershipStart: (state) => {
      console.log("fetchMembershipStart");
      state.loading = true;
      state.error = null;
    },
    fetchMembershipSuccess: (state, action) => {
      console.log("fetchMembershipSuccess");
      state.loading = false;
      // resetSubscriptionType이 true면 subscriptionType을 null로 설정
      // 아니면 action.payload.subscriptionType을 subscriptionType으로 설정
      state.subscriptionType = action.payload.resetSubscriptionType
        ? null
        : action.payload.subscriptionType ?? state.subscriptionType;
      state.isFreeTrial = action.payload.isFreeTrial ?? state.isFreeTrial;
      state.startDate = action.payload.resetSubscriptionType
        ? ""
        : action.payload.startDate ?? state.startDate;
      state.endDate = action.payload.resetSubscriptionType
        ? ""
        : action.payload.endDate ?? state.endDate;
      state.userId = action.payload.userId ?? state.userId;
      state.name = action.payload.name ?? state.name;
      state.email = action.payload.email ?? state.email;
      state.phoneNumber = action.payload.phoneNumber ?? state.phoneNumber;
    },
    fetchMembershipFailure: (state, action) => {
      console.log("fetchMembershipFailure");
      state.loading = false;
      state.error = action.payload;
    },
    fetchMembershipInitial: (state) => {
      console.log("fetchMembershipInitial");
      state.subscriptionType = null;
      state.loading = false;
      state.error = null;
      state.isFreeTrial = false;
      state.startDate = null;
      state.endDate = null;
      state.userId = null;
      state.name = null;
      state.email = null;
      state.phoneNumber = null;
    },
  },
});

export const {
  fetchMembershipStart,
  fetchMembershipSuccess,
  fetchMembershipFailure,
  fetchMembershipInitial,
} = membershipSlice.actions;

export default membershipSlice.reducer;
