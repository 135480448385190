import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styles from "@pages/Home/Home.module.scss";
import FirstSlideItem from "@components/Home/FirstSlideItem";
import SecondSlideItem from "@components/Home/SecondSlideItem";
import ThirdSlideItem from "@components/Home/ThirdSlideItem";
import FourthSlideItem from "@components/Home/FourthSlideItem";
import FifthSlideItem from "@pages/Login/Login";
import setBodyColor from "@utils/functions/css/customCss";
import { textAnimation } from "@assets/styles/textAnimation.module.scss";
import { useEffect, useState, useRef } from "react";

export default function Home() {
  const [currentSlide, setCurrentSlide] = useState(0);
  const sliderRef = useRef();

  const goToSlide = (index) => {
    sliderRef.current.slickGoTo(index);
  };

  const goToPrevSlide = () => {
    sliderRef.current.slickPrev();
  };

  const goToNextSlide = () => {
    sliderRef.current.slickNext();
  };

  useEffect(() => {
    if (currentSlide === 0) {
      setBodyColor("#EEF5FF"); // 홈 화면의 배경색 변경
    } else if (currentSlide === 1 || currentSlide === 2) {
      setBodyColor("linear-gradient(180deg, #fcfdff 24%, #f5f5f5 98%)"); // 다른 페이지로 이동할 때 원래 색상으로 복원
    } else {
      setBodyColor("#fcfdff"); // 다른 페이지로 이동할 때 원래 색상으로 복원
    }
    return () => {
      setBodyColor("#fcfdff"); // 다른 페이지로 이동할 때 원래 색상으로 복원
    };
  }, [currentSlide]);

  var settings = {
    dots: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    centerPadding: "100px", // 중앙 모드에서 양 옆의 슬라이드 보이지 않게 설정
    adaptiveHeight: true,
    arrows: false,
    useCss: true,

    beforeChange: (oldIndex, newIndex) => {
      setCurrentSlide(newIndex);
      document.querySelectorAll(".slick-slide").forEach((slide) => {});
    },
    appendDots: (dots) => (
      <div
        style={{
          //  건너뛰기 버튼 클릭 영역을 가리지 않게 하기 위해  width  값 조절
          // width 값 조절하면서 가운데 정렬도 재조정
          left: "50%",
          width: "fit-content",
          transform: "translate(-50%, -50%)",
          position: "fixed",
          bottom: "80px",
        }}
      >
        <ul style={{ margin: "0px", padding: "0px" }}>
          {dots.map((dot, index) => (
            <li
              key={index}
              style={{
                marginRight: "16px",
                width: "12px",
                height: "12px",
              }}
            >
              {dot}
            </li>
          ))}
        </ul>
      </div>
    ),
    customPaging: (i) => (
      <div
        style={
          i === currentSlide
            ? {
                backgroundColor: "#9E9E9E",
                width: "12px",
                height: "12px",
                color: "#9E9E9E",
                borderRadius: "50%",
              }
            : {
                backgroundColor: "#E6E6E6",
                width: "12px",
                height: "12px",
                color: "#E6E6E6",
                borderRadius: "50%",
              }
        }
      ></div>
    ),
  };
  return (
    <div className={styles.sliderContainer}>
      <button className={styles.prevArrow} onClick={goToPrevSlide}></button>
      <Slider ref={sliderRef} {...settings}>
        <div className={styles.slideItem}>
          <FirstSlideItem />
          <button className={styles.skipButton} onClick={() => goToSlide(4)}>
            건너뛰기
          </button>
        </div>
        <div className={styles.slideItem}>
          <SecondSlideItem />
          <button className={styles.skipButton} onClick={() => goToSlide(4)}>
            건너뛰기
          </button>
        </div>
        <div className={styles.slideItem}>
          <ThirdSlideItem />
          <button className={styles.skipButton} onClick={() => goToSlide(4)}>
            건너뛰기
          </button>
        </div>
        <div className={styles.slideItem}>
          <FourthSlideItem />
          <button className={styles.skipButton} onClick={() => goToSlide(4)}>
            건너뛰기
          </button>
        </div>
        <div className={styles.slideItem}>
          <FifthSlideItem isHome={true} />
        </div>
      </Slider>
      <button className={styles.nextArrow} onClick={goToNextSlide}></button>
    </div>
  );
}
