import MypageStyle from "@components/Mypage/MyMembership/MymbershipStyle.module.scss";
import { useState, useEffect } from "react";
import PayButtons from "@components/Mypage/MyMembership/PayButtons";
import { forwardRef } from "react";
import { PaymentMethodDTO } from "@dto/membership/PaymentMethodDTO";
import { MembershipInfoDTO } from "@dto/membership/MembershipInfoDTO";
import { useMembershipHandlers } from "@hooks/membership/useMembershipHandlers";
import { setMembershipDataKeyForUpgrade } from "@utils/functions/membership/membership";
import { useSelector } from "react-redux";

const PaymentFormBox = forwardRef(({ selectMebershipTypeForPay }, ref) => {
  const [paymentInfo, setPaymentInfo] = useState(new PaymentMethodDTO({}));
  const [membershipDataKeyForPay, setMembershipDataKeyForPay] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const membershipData = useSelector((state) => state.membership);

  useEffect(() => {
    if (selectMebershipTypeForPay) {
      setMembershipDataKeyForPay(selectMebershipTypeForPay);
      return;
    }

    if (false === membershipData.isFreeTrial) {
      setMembershipDataKeyForPay(
        setMembershipDataKeyForUpgrade(membershipData.subscriptionType)
      );
    } else {
      setMembershipDataKeyForPay(membershipData.subscriptionType);
    }
  }, [selectMebershipTypeForPay]);

  const { handleGetMembershipInfo } = useMembershipHandlers();

  useEffect(() => {
    getAndSetMembershipInfo();
  }, []);

  const getAndSetMembershipInfo = async () => {
    try {
      const data = await handleGetMembershipInfo();
      const membershipInfo = new MembershipInfoDTO(data);

      const paymentMethod = membershipInfo.getPaymentMethod();
      if (paymentMethod) {
        setPaymentInfo(new PaymentMethodDTO(paymentMethod));
      } else {
        setPaymentInfo(null);
      }
    } catch (error) {
      if (error && error.status === 404) {
        setPaymentInfo(null);
      } else {
      }
    }
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className={MypageStyle.paymentFormBox} ref={ref}>
      {!paymentInfo ? (
        <div className={MypageStyle.noRegisterCardBox}>
          <div className={MypageStyle.textBox}>
            <span className={MypageStyle.title}>결제수단</span>
          </div>
          {membershipDataKeyForPay ? (
            <PayButtons membershipDataKeyForPay={membershipDataKeyForPay} />
          ) : null}
        </div>
      ) : (
        <div className={MypageStyle.registeredCardBox}>
          <div className={MypageStyle.registeredCardBoxHeader}>
            <span className={MypageStyle.title}>등록된 카드</span>
            <div className={MypageStyle.registerCardInfoBox}>
              {paymentInfo.getCardCompanyName() ||
                "카드명 없음" + " " + paymentInfo.getCardNumber() ||
                "카드번호 없음"}

              {/* 멤버십 안내 페이지에서는 변경 버튼 보이지 않음  ->
              멤버십 안내에서는 지불 카드 변경 불가능
              마이페이지에서만  카드 변경 가능*/}
              {/* 결제 수단 변경은 다음 배포 기간에 추가하기   */}
              {/* {isShowUpgradeMembershipInfo ? (
                  <button onClick={() => setShowChangePayButtons(true)}>
                    변경
                  </button>
                ) : null} */}
            </div>
          </div>

          {/* {showChangePayButtons ? (
              <div className={MypageStyle.changePayInfoBox}>
                <div className={MypageStyle.textBox}>
                  <span className={MypageStyle.title}>결제 수단 변경</span>
                  <span className={MypageStyle.subtitle}>
                    결제수단을 선택해주세요.
                  </span>
                </div>
                <PayButtons isChangePayInfo={true} />
                <span className={MypageStyle.changePayInfoText}>
                  *결제수단 변경 시, 다음 결제일 부터 변경한 결제수단으로 결제됩니다.
                </span>
              </div>
            ) : null} */}
        </div>
      )}
    </div>
  );
});

export default PaymentFormBox;
