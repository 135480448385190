import styles from "@pages/Agreements/Agreement.module.scss";

const MarketingConsent = () => {
  return (
    <div className={styles.privacyPolicy}>
      <div className={styles.container}>
        <div className={styles.logo}>
          <div className={styles.logoText} />
        </div>
        <div className={styles.policyCard}>
          <div className={styles.policyHeader}>
            <h1 className={styles.policyTitle}>개인정보처리방침</h1>
          </div>
          <div className={styles.policyContent}>
            <p className={styles.contentDescription}>
              주식회사 캐치랭크(이하 "회사")는 회사가 캐치랭크 홈페이지를 통하여
              제공하는 서비스를 이용하는 회원의 개인정보를 보호하고 이와 관련한
              고충을 신속하고 원활하게 처리할 수 있도록 하기 위하여 다음과 같이
              개인정보처리지침을 수립·공개합니다.
            </p>
            <h2 className={styles.sectionTitle}>
              제 1 조 (개인정보 수집 목적)
            </h2>
            <ul className={styles.list}>
              <li>회원관리 및 중복가입 방지</li>
              <li>
                회원식별 및 가입의사 확인, 본인·연령확인, 부정이용 방지,
                문의사항 또는 불만·분쟁처리, 개별 및 중요한 공지사항 안내
              </li>
              <li>서비스 이행 및 요금 결제</li>
              <li>상품 구매, 유료 서비스 이용 시 요금 결제, 청구, 정산</li>
              <li>서비스 반영</li>
              <li>서비스 검토, 개선, 새로운 서비스 개발</li>
              <li>마케팅 및 광고에 활용</li>
              <li>
                신규 서비스(제품) 개발 및 특화, 이벤트 등 광고성 정보 전달, 인구
                통계학적 특성에 따른 서비스 제공 및 광고 게재, 접속 빈도 파악
                또는 회원의 서비스 이용에 대한 통계
              </li>
              <li>가명정보의 활용</li>
              <li>
                통계작성, 과학적 연구, 공익적 기록보존 등을 위한 가명처리 및
                가명정보의 활용
              </li>
            </ul>

            <h2 className={styles.sectionTitle}>
              제 2 조 (수집하는 개인정보 항목, 수집방법, 이용기간)
            </h2>
            <p>
              ① 회사는 회원으로부터 다음과 같이 개인정보를 수집하여 이용 및
              보유합니다.
            </p>
            <table className={styles.table}>
              <thead>
                <tr>
                  <th>필수/선택</th>
                  <th>항목</th>
                  <th>수집방법</th>
                  <th>보유 기간</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>필수</td>
                  <td>
                    [회원 가입] 사용자 이름, 사용자 기업명 혹은 소속기관, 사용자
                    이메일 주소, 비밀번호, 휴대폰 번호
                  </td>
                  <td>회원 가입시 회원이 기입</td>
                  <td>회원 탈퇴 후 90일이내</td>
                </tr>
                <tr>
                  <td>필수</td>
                  <td>
                    카드사명, 카드 종류, 암호화된 카드 식별정보(Key), 카드번호,
                    카드 유효기간, 은행계좌정보 등 결제와 관련된 정보
                  </td>
                  <td>결제시 회원이 기입</td>
                  <td>서비스 구매 계약 종료 후 5영업일 이내</td>
                </tr>
                <tr>
                  <td>필수</td>
                  <td>
                    IP주소, 쿠키, MAC주소, 서비스 이용기록, 방문 기록, 불량 이용
                    기록
                  </td>
                  <td>서비스 이용과정에서 자동 수집</td>
                  <td>회원 탈퇴 후 12개월 이내</td>
                </tr>
              </tbody>
            </table>

            <p>
              ② 회사는 제1항 기재 이용 및 보유 기간에도 불구하고 다음의 사유에
              해당하는 경우에는 해당 사유 종료 시까지 이용 및 보유할 수
              있습니다.
            </p>
            <table className={styles.table}>
              <thead>
                <tr>
                  <th>근거 및 해당 사항</th>
                  <th>기간</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>관계 법령 위반에 따른 수사∙조사 등이 진행중인 경우</td>
                  <td>해당 수사·조사 종료 시까지</td>
                </tr>
                <tr>
                  <td>서비스 이용에 따른 채권·채무 관계가 잔존하는 경우</td>
                  <td>해당 수사·조사 종료 시까지</td>
                </tr>
                <tr>
                  <td>통신비밀보호법상 홈페이지 방문 기록</td>
                  <td>3개월</td>
                </tr>
                <tr>
                  <td>
                    전자상거래 등에서의 소비자 보호에 관한 법률 - 표시∙광고에
                    관한 기록
                  </td>
                  <td>6개월</td>
                </tr>
                <tr>
                  <td>
                    전자상거래 등에서의 소비자 보호에 관한 법률 - 계약 또는
                    청약철회에 관한 기록
                  </td>
                  <td>5년</td>
                </tr>
                <tr>
                  <td>
                    전자상거래 등에서의 소비자 보호에 관한 법률 - 대금결제 및
                    재화 등의 공급에 관한 기록
                  </td>
                  <td>5년</td>
                </tr>
                <tr>
                  <td>
                    전자상거래 등에서의 소비자 보호에 관한 법률 - 소비자의 불만
                    또는 분쟁처리에 관한 기록
                  </td>
                  <td>3년</td>
                </tr>
              </tbody>
            </table>

            <h2 className={styles.sectionTitle}>제 3 조 (개인정보의 제공)</h2>
            <p>
              회사는 회원들의 개인정보를 이용목적의 범위 내에서 이용하며, 다음
              각 호의 경우에 해당하는 경우에만 개인정보를 제3자에게 제공합니다.
            </p>
            <ol className={styles.orderedList}>
              <li>회원이 사전에 동의한 경우</li>
              <li>서비스의 제공에 따른 요금 정산을 위하여 필요한 경우</li>
              <li>
                법령의 규정에 의거하거나, 수사기관이 수사 목적으로 법령에 정해진
                절차와 방법에 따라 요구하는 경우
              </li>
              <li>기타 법률에 따라 요구되는 경우</li>
            </ol>

            <h2 className={styles.sectionTitle}>
              제 4 조 (개인정보처리의 위탁)
            </h2>
            <p>
              ① 회사는 원활한 개인정보 업무처리를 위하여 다음과 같이 개인정보
              처리 업무를 위탁하고 있습니다.
            </p>
            <table className={styles.table}>
              <thead>
                <tr>
                  <th>위탁 받는 자(수탁 업체)</th>
                  <th>업무 내용</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>(주)카카오</td>
                  <td>SMS 및 메시지 발송</td>
                </tr>
                <tr>
                  <td>인포뱅크(주)</td>
                  <td>SMS 및 메시지 발송</td>
                </tr>
                <tr>
                  <td>AWS</td>
                  <td>데이터 보관 및 인프라 관리</td>
                </tr>
              </tbody>
            </table>
            <p>
              ② 회사는 위탁계약 체결 시 관련 법령에 따라 위탁업무 수행 목적 외
              개인정보 처리금지, 기술적·관리적 보호조치, 재 위탁 제한, 수탁자에
              대한 관리·감독, 손해배상 등 책임에 관한 사항을 계약서 등 문서에
              명시하고, 수탁자가 개인정보를 안전하게 처리하는지를 감독하고
              있습니다.
            </p>
            <p>
              ③ 위탁업무의 내용이나 수탁자가 변경될 경우에는 지체없이 본
              개인정보 처리방침을 통하여 공개하도록 하겠습니다.
            </p>

            <h2 className={styles.sectionTitle}>
              제 5 조 (수집목적과 합리적으로 관련된 범위 내의 개인정보 이용 및
              제공)
            </h2>
            <p>
              ① 회사는 당초 수집 목적과 합리적인 범위 내에서 아래 각 기준을
              고려하여, 이용자의 동의 없이 개인정보를 이용 또는 제3자에게 제공할
              수 있습니다.
            </p>
            <ol className={styles.orderedList}>
              <li>
                당초 수집 목적과 관련성이 있는지 여부: 당초 수집 목적과 추가적
                이용·제공 목적이 성질이나 경향에 있어 연관이 있는지 등을
                고려하여 따라 판단
              </li>
              <li>
                개인정보를 수집한 정황 또는 처리 관행에 비추어 볼 때 개인정보의
                추가적인 이용 또는 제공에 대한 예측 가능성이 있는지 여부:
                개인정보처리자와 이용자 간의 관계, 기술 수준 및 발전 속도,
                상당한 기간동안 정립된 일반적인 사정(관행) 등을 고려하여 판단
              </li>
              <li>
                이용자의 이익을 부당하게 침해하는지 여부: 추가적인 이용 목적과의
                관계에서 이용자의 이익이 실질적으로 침해되는지 및 해당 이익
                침해가 부당한지 등을 고려하여 판단
              </li>
              <li>
                가명처리 또는 암호화 등 안전성 확보에 필요한 조치를 하였는지
                여부: 침해 가능성을 고려한 안전 조치가 취해지는지 등을 고려하여
                판단
              </li>
            </ol>

            <h2 className={styles.sectionTitle}>
              제 6 조 (회원의 권리 의무 및 행사방법)
            </h2>
            <p>
              ① 회원은 회사에 대해 언제든지 다음 각 호의 개인정보 보호 관련
              권리를 행사할 수 있습니다.
            </p>
            <ol className={styles.orderedList}>
              <li>개인정보 열람 요구</li>
              <li>오류 등이 있을 경우 정정 요구</li>
              <li>삭제 요구</li>
              <li>처리 정지 요구</li>
            </ol>
            <p>
              ② 회원은 홈페이지의 "회원정보 변경" 메뉴를 통하여 권리 행사를 하실
              수 있으며 회사는 이에 대해 지체 없이 조치하며, 회사의
              개인정보보호책임자 및 담당자에게 서면 또는 전자우편으로 연락하여
              권리 행사하는 것 또한 가능합니다.
            </p>
            <p>
              ③ 회원이 개인정보의 오류 등에 대한 정정 또는 삭제를 요구한
              경우에는 회사는 정정 또는 삭제를 완료할 때까지 당해 개인정보를
              이용하거나 제공하지 않습니다.
            </p>
            <p>
              ④ 제1항에 따른 권리 행사는 회원의 법정대리인이나 위임을 받은 자 등
              대리인을 통하여 하실 수 있습니다. 단, 위임을 받은 자는 회사에
              위임장을 제출하여야 합니다.
            </p>
            <p>
              ⑤ 회원은 관계법령을 위반하여 회사가 처리하고 있는 회원 본인이나
              타인의 개인정보 및 사생활을 침해하여서는 안 됩니다. 만약 이 같은
              책임을 다하지 못하고 타인의 정보 및 존엄성을 훼손할
              시에는『정보통신망 이용촉진 및 정보 보호 등에 관한 법률』등에 의해
              처벌받을 수 있습니다.
            </p>
            <p>
              ⑥ 회원의 개인정보를 최신의 상태로 정확하게 입력하여 불의의 사고를
              예방해 주시기 바랍니다. 회원이 입력한 부정확한 정보로 인해
              발생하는 사고의 책임은 회원 자신에게 있으며 타인 정보의 도용 등
              허위정보를 입력할 경우 회원자격이 상실될 수 있습니다.
            </p>

            <h2 className={styles.sectionTitle}>제 7 조 (개인정보의 파기)</h2>
            <p>
              ① 회사는 개인정보 보유기간의 경과, 처리목적 달성 등 개인정보가
              불필요하게 되었을 때에는 지체없이 해당 개인정보를 파기합니다.
            </p>
            <p>
              ② 회원으로부터 동의 받은 개인정보 보유기간이 경과하거나 처리목적이
              달성되었음에도 불구하고 다른 법령에 따라 개인정보를 계속
              보존하여야 하는 경우에는, 해당 개인정보를 별도의
              데이터베이스(DB)로 옮기거나 보관장소를 달리하여 보존합니다.
            </p>
            <p>③ 개인정보 파기의 절차 및 방법은 다음과 같습니다.</p>
            <ol className={styles.orderedList}>
              <li>
                파기절차: 회사는 파기 사유가 발생한 개인정보를 선정하고, 회사의
                개인정보 보호책임자의 승인을 받아 개인정보를 파기합니다.
              </li>
              <li>
                파기방법: 회사는 전자적 파일 형태로 기록·저장된 개인정보는
                기록을 재생할 수 없는 방법을 이용하여 파기하며, 종이 문서에
                기록·저장된 개인정보는 분쇄기로 분쇄하거나 소각하여 파기합니다.
              </li>
            </ol>

            <h2 className={styles.sectionTitle}>
              제 8 조 (개인정보의 안전성 확보 조치)
            </h2>
            <p>
              회사는 개인정보의 안전성 확보를 위해 다음과 같은 조치를 취하고
              있습니다.
            </p>
            <ol className={styles.orderedList}>
              <li>관리적 조치: 내부관리계획 수립·시행, 정기적 직원 교육 등</li>
              <li>
                기술적 조치: 개인정보처리시스템 등의 접근권한 관리,
                접근통제시스템 설치, 고유식별정보 등의 암호화
              </li>
            </ol>

            <h2 className={styles.sectionTitle}>
              제 9 조 (개인정보 자동 수집 장치의 설치·운영 및 거부에 관한 사항)
            </h2>
            <p>
              ① 회사는 회원에게 개별적인 맞춤서비스를 제공하기 위해 이용 정보를
              저장하고 수시로 불러오는 쿠키(cookie)를 사용합니다.
            </p>
            <p>
              ② 쿠키는 웹사이트를 운영하는데 이용되는 서버(http)가 회원의 컴퓨터
              브라우저에게 보내는 소량의 정보이며 회원들의 PC 컴퓨터 내의
              하드디스크에 저장되기도 합니다.
            </p>
            <ol className={styles.orderedList}>
              <li>
                쿠키의 사용목적: 회원이 방문한 각 서비스와 웹 사이트들에 대한
                방문 및 이용형태 인기 검색어, 보안접속 여부 등을 파악하여
                회원에게 최적화된 정보 제공을 위해 사용됩니다.
              </li>
              <li>
                쿠키의 설치∙운영 및 거부(Internet Explorer의 경우): 웹 브라우저
                상단의 도구>인터넷 옵션 >개인정보 메뉴의 옵션 설정을 통해 쿠키
                저장을 거부할 수 있습니다.
              </li>
              <li>
                쿠키 저장을 거부할 경우 맞춤형 서비스 이용에 어려움이 발생할 수
                있습니다.
              </li>
            </ol>

            <h2 className={styles.sectionTitle}>제 10 조 광고성 정보 전송</h2>
            <p>
              회사는 회원의 명시적인 수신거부의사에 반하여 영리 목적의 광고성
              정보를 전송하지 않습니다. 회사는 안내 등 온라인 마케팅을 위해
              광고성 정보를 전자우편 등으로 전송하는 경우 정보통신망법의 규정에
              따라 제목란 및 본문 란에 다음 사항과 같이 회원이 쉽게 알아볼 수
              있도록 조치합니다.
            </p>
            <ol className={styles.orderedList}>
              <li>
                제목란: (광고)라는 문구를 제목란에 표시하지 않을 수 있으며
                전자우편 본문 란의 주요 내용을 표시합니다.
              </li>
              <li>
                본문 란: 회원이 수신 거부의 의사표시를 할 수 있는 전송자의 명칭,
                전자우편 주소, 전화번호 및 주소 등 회원이 수신 거부의 의사를
                쉽게 표시할 수 있는 방법을 명시합니다.
              </li>
              <li>
                팩스, 휴대폰 문자전송 등 전자우편 이외의 문자 전송을 통해 영리
                목적의 광고성 정보를 전송하는 경우에는 전송내용 처음에
                "(광고)"라는 문구를 표기하고 전송내용 중에 전송자의 연락처를
                명시하도록 조치합니다.
              </li>
            </ol>

            <h2 className={styles.sectionTitle}>
              제 11 조 (개인정보 보호책임자)
            </h2>
            <p>
              회사는 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보
              처리와 관련한 회원의 불만처리 및 피해구제 등을 위하여 아래와 같이
              개인정보 보호책임자를 지정하고 있습니다.
            </p>
            <table className={styles.table}>
              <tbody>
                <tr>
                  <td>성명</td>
                  <td>전유상</td>
                </tr>
                <tr>
                  <td>직책</td>
                  <td>대표</td>
                </tr>
                <tr>
                  <td>이메일</td>
                  <td>amplify15813@gmail.com</td>
                </tr>
                <tr>
                  <td>전화번호</td>
                  <td>02-515-1867</td>
                </tr>
              </tbody>
            </table>
            <p>
              ① 회원은 회사의 서비스(또는 사업)를 이용하시면서 발생한 모든
              개인정보 보호 관련 문의, 불만처리, 피해구제 등에 관한 사항을
              개인정보 보호책임자 및 담당부서로 문의하실 수 있습니다.
            </p>

            <h2 className={styles.sectionTitle}>
              제 12 조 (개인정보 처리방침 변경)
            </h2>
            <p>
              본 개인정보 처리방침의 내용 추가, 삭제 및 변경이 있을 경우 개정
              최소 7일 전에 '공지 사항'을 통해 사전 고지할 것입니다. 다만,
              수집하는 개인정보의 항목, 제3자 제공 등 중대한 변경이 발생할
              때에는 최소 30일 전에 고지하고, 필요시 회원 동의를 다시 받도록
              하겠습니다.
            </p>

            <h2 className={styles.sectionTitle}>부 칙</h2>
            <p>본 개인정보 처리방침은 2024년 10월 27일부터 적용됩니다.</p>
            <p>이전 개인정보처리방침은 아래와 같습니다.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MarketingConsent;
