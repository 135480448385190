import styles from "@components/Common/Loader/LoaderStyle.module.scss";

const OverlayLoader = () => {
  return (
    <div className={styles.loaderBox}>
      <span className={styles.loader}></span>
    </div>
  );
};

export default OverlayLoader;
