import style from "@components/RegisteredPlace/RegisteredPlaceStyle.module.scss";
import PropTypes from "prop-types";
import { useAppNavigate } from "@hooks/navigate/navigate";
import { PlaceInfoDTO, KeywordRankDTO } from "@dto/place/RegisterPlaceCardDTO";
import textAnimation from "@assets/styles/textAnimation.module.scss";
import noImage from "@assets/images/no_image.png";
import React from "react";

const RegisterStoreCard = React.memo(
  ({
    placeInfo,
    keywordRankArray,
    toggleSelectId,
    isSelected,
    showCheckBox,
  }) => {
    const placeInfoDTO = new PlaceInfoDTO(placeInfo);
    const keywordRankDTOArray = keywordRankArray.map(
      (rank) => new KeywordRankDTO(rank)
    );
    const { goToRegisteredPlaceDetail } = useAppNavigate();

    return (
      <div
        className={`${style.registerStoreCard} ${
          showCheckBox === true ? style.showCheckBox : ""
        }`}
      >
        {showCheckBox && (
          <div
            className={`${style.checkBox} ${isSelected ? style.checked : ""}`}
            onClick={() =>
              toggleSelectId(placeInfoDTO.getRegistrationPlaceId())
            }
          ></div>
        )}
        <div
          className={style.registerStoreInfoBox}
          onClick={() => {
            goToRegisteredPlaceDetail({
              placeId: placeInfoDTO.getPlaceId(),
              registrationPlaceId: placeInfoDTO.getRegistrationPlaceId(),
            });
          }}
        >
          <div className={style.registerStoreCardContentHeader}>
            <p>{placeInfoDTO.getName() || "이름 없음"}</p>
            <p className={style.category}>
              {placeInfoDTO.getCategory() || "분류 정보 없음"}
            </p>
          </div>

          <div className={style.registerStoreCardContentMiddle}>
            <div className={style.registerStoreCardContentMiddleItem}>
              방문자 리뷰
              <p className={style.count}>
                {placeInfoDTO.getVisitorReviewCount() || 0}
              </p>
            </div>
            <div className={style.registerStoreCardContentMiddleItem}>
              블로그 리뷰
              <p className={style.count}>
                {placeInfoDTO.getBlogReviewCount() || 0}
              </p>
            </div>
            <div className={style.registerStoreCardContentMiddleItem}>
              저장수
              <p className={style.count}>
                {placeInfoDTO.getSaveCount() || "0"}
              </p>
            </div>
          </div>

          <div
            className={`${style.registerStoreCardContentFooter} ${textAnimation.scaleInCenter}`}
          >
            {keywordRankDTOArray &&
              keywordRankDTOArray.slice(0, 5).map(
                (rank, index) =>
                  rank.keyword && (
                    <p key={index}>
                      {rank.getRanking() === 0 ? (
                        <div className={style.noRank}></div>
                      ) : (
                        rank.getRanking() || "-" + "위"
                      )}
                      <span className={style.keyword}>
                        {rank.getKeyword() || "키워드 없음"}
                      </span>
                    </p>
                  )
              )}
            {keywordRankDTOArray && keywordRankDTOArray.length >= 5 && (
              <span className={style.ect}></span>
            )}
          </div>
        </div>

        {placeInfoDTO.imageUrl ? (
          <img
            src={placeInfoDTO.imageUrl}
            alt={placeInfoDTO.name}
            className={style.registerStoreCardImage}
          />
        ) : (
          <img
            src={noImage}
            alt="noImage"
            className={style.registerStoreCardImage}
          />
        )}
      </div>
    );
  }
);

RegisterStoreCard.propTypes = {
  placeInfo: PropTypes.object.isRequired,
  keywordRankArray: PropTypes.array.isRequired,
  toggleSelectId: PropTypes.func.isRequired,
  isSelected: PropTypes.bool.isRequired,
  showCheckBox: PropTypes.bool.isRequired,
};

export default RegisterStoreCard;
