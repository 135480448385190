import signupStyle from "@components/SignUp/SignUpStyle.module.scss";
import { useAppNavigate } from "@hooks/navigate/navigate";
function SignUpCompleteButtons() {
  const { goToLogin } = useAppNavigate();
  return (
    <div className={signupStyle.signupCompleteContainer}>
      <button className={signupStyle.completeButton} onClick={goToLogin}>
        로그인 하러가기
      </button>
    </div>
  );
}

export default SignUpCompleteButtons;
