// src/dto/place/defaultPlaceInfoDTO.js
import { BasePlaceInfo } from "@dto/place/BasePlaceInfo";

export class PlaceInfoDTO extends BasePlaceInfo {
  constructor({
    registrationPlaceId = "",
    rank = 0,
    pcRank = "순위 없음",
    mobileRank = "순위 없음",
    keyword = "",
    isRegistered = false,
    ...baseProps
  }) {
    super(baseProps);
    this.registrationPlaceId = registrationPlaceId;
    this.rank = rank;
    this.pcRank = pcRank;
    this.mobileRank = mobileRank;
    this.keyword = keyword;
    this.isRegistered = isRegistered;
  }

  // GET methods
  getRegistrationPlaceId() {
    return this.registrationPlaceId;
  }

  getRank() {
    return this.rank;
  }

  getPcRank() {
    return this.pcRank;
  }

  getMobileRank() {
    return this.mobileRank;
  }

  getKeyword() {
    return this.keyword;
  }

  getIsRegistered() {
    return this.isRegistered;
  }

  // SET methods
  setRegistrationPlaceId(value) {
    this.registrationPlaceId = value;
  }

  setRank(value) {
    this.rank = value;
  }

  setPcRank(value) {
    this.pcRank = value;
  }

  setMobileRank(value) {
    this.mobileRank = value;
  }

  setKeyword(value) {
    this.keyword = value;
  }

  setIsRegistered(value) {
    this.isRegistered = value;
  }
}
