import style from "@pages/RegisteredPlace/RegisteredPlace.module.scss";
import SearchableCardList from "@components/RegisteredPlace/SearchableCardList";
const RegisteredPlace = () => {
  return (
    <div className={style.registeredPlaceContainer}>
      <div className={style.registeredPlaceTitleContainer}>
        <h1>등록한 플레이스</h1>
        <h3>등록한 플레이스를 한눈에 보세요! </h3>
      </div>
      <div className={style.registeredCardListContainer}>
        <SearchableCardList />
      </div>
    </div>
  );
};

export default RegisteredPlace;
