import { useState, useEffect } from "react";
import findEmailPasswordStyles from "./FindEmailPassword.module.scss"; // 스타일 시트 임포트
import GuideTextContainer from "@components/FindEmailPassword/GuideTextContainer";
import SwitchButtons from "@components/FindEmailPassword/SwitchButtons";
import FindEmailForm from "@components/FindEmailPassword/FindEmailForm";
import FindPassword from "@components/FindEmailPassword/FindPassword";
import { useParams, useNavigate } from "react-router-dom";

const FindEmailPassword = () => {
  const { isEmail } = useParams(); // URL 파라미터에서 isEmail 가져오기
  const [selectedTab, setSelectedTab] = useState("email"); // 현재 선택된 탭 상태 관리
  const navigate = useNavigate(); // useNavigate 훅 사용
  // 탭 변경 핸들러
  const handleTabChange = (tab) => {
    setSelectedTab(tab);
    navigate(`/findEmailPassword/${tab === "email" ? "true" : "false"}`, {
      replace: true,
    }); // URL 파라미터 업데이트
  };

  useEffect(() => {
    setSelectedTab(isEmail === "true" ? "email" : "password");
  }, [isEmail]); // isEmail 변경 시 selectedTab 업데이트
  return (
    <div className={findEmailPasswordStyles.findEmailPasswordContainer}>
      <GuideTextContainer
        title="이메일 / 비밀번호 찾기"
        subtitle={
          selectedTab === "email"
            ? "가입 시 입력한 이름과 휴대전화 번호를 입력해주세요."
            : "가입 시 입력한 이메일과 휴대전화 번호를 입력해주세요."
        }
      />
      <div className={findEmailPasswordStyles.inputContainer}>
        <SwitchButtons
          activeButtonName={selectedTab}
          onTabChange={handleTabChange}
        />
        {selectedTab === "email" ? <FindEmailForm /> : <FindPassword />}
      </div>
    </div>
  );
};

export default FindEmailPassword;
