import { useState } from "react";
import PhoneNumberInput from "@components/Common/User/PhoneNumberInput";
import GuideTextContainer from "@components/FindEmailPassword/GuideTextContainer";
import styles from "@pages/SignUp/SignUp.module.scss";
import AuthCodeInput from "@components/Common/User/AuthCodeInput";
import SingleConfirmModal from "@components/Common/Modal/Dialogs/SingleConfirmModal";
import { useAuthCode } from "@hooks/user/useAuthCode";
import { VERIFICATION_TYPE } from "@utils/constants/api/verificationType";
import { API_ERROR_TYPE } from "@utils/constants/api/errorType";

const AddPhoneNumber = () => {
  const [formData, setFormData] = useState({
    email: "example@gmail.com",
    name: "example",
    phoneNumber: "",
    code: "",
    birthDate: "",
    socialProvider: "",
    socialId: "",
    refreshToken: "",
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState("");

  const {
    resendCount,
    isPhoneAuthSent,
    time,
    isCheckValid,
    checkAuthCode,
    sendAuthCode,
    sendAuthCodeForAddPhoneNumber,
  } = useAuthCode(VERIFICATION_TYPE.REGISTER_KAKAO);

  const getQueryParams = (url) => {
    const params = new URLSearchParams(url.split("?")[1]);
    const data = params.get("data");

    if (data) {
      try {
        const parsedData = JSON.parse(decodeURIComponent(data));
        return {
          nickname: parsedData.profile.nickname,
          isDefaultNickname: parsedData.profile.is_default_nickname,
        };
      } catch (error) {
        console.error("🚀 ~ getQueryParams ~ JSON 파싱 에러:", error);
      }
    }

    return {
      nickname: null,
      isDefaultNickname: false,
    };
  };

  const queryParams = getQueryParams(window.location.search);
  console.log("🚀 ~ AddPhoneNumber ~ queryParams:", queryParams);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async () => {
    try {
      await checkAuthCode({
        phoneNumber: formData.phoneNumber,
        code: formData.code,
      });
      await sendAuthCodeForAddPhoneNumber({
        email: formData.email,
        name: formData.name,
        phoneNumber: formData.phoneNumber,
        socialProvider: queryParams.socialProvider,
        socialId: queryParams.socialId,
        refreshToken: queryParams.refreshToken,
        birthDate: formData.birthDate,
      });
    } catch (error) {
      console.error("🚀 ~ handleSubmit ~ error:", error);
      throw error;
    }
  };

  const handlePhoneAuthSend = async () => {
    try {
      await sendAuthCode({
        phoneNumber: formData.phoneNumber,
      });
      setModalContent(
        <div className={styles.modalContent}>
          <p>
            인증번호를 발송했습니다.
            <br />
            인증번호가 오지 않으면 입력하신 정보가 <br />
            회원정보와 일치하는지 확인해 주세요.
          </p>
        </div>
      );
      setIsModalOpen(true);
    } catch (error) {
      console.log("🚀 ~ handlePhoneAuthSend ~ error:", error);
      if (error && error.status === 409) {
        const type = error.response?.data?.type ?? ""; // 안전하게 type을 가져옴
        console.log("🚀 ~ handlePhoneAuthSend ~ type:", type);
        if (API_ERROR_TYPE.CONFLICT_KAKAO_USER === type) {
          setModalContent("이미 카카오에 가입되어 있는 계정입니다.");
          setIsModalOpen(true);
        } else if (API_ERROR_TYPE.CONFLICT_NAVER_USER === type) {
          setModalContent("이미 네이버에 가입되어 있는 계정입니다.");
          setIsModalOpen(true);
        }
      }
    }
  };

  return (
    <div className={styles.addPhoneNumberBox}>
      <GuideTextContainer
        title="회원가입 정보 추가"
        subtitle="등록하실 휴대폰 번호를 입력해주세요."
      />
      <div className={styles.line} />

      <div className={styles.inputBox}>
        <PhoneNumberInput
          name="phoneNumber"
          phoneNumber={formData.phoneNumber}
          onChange={handleChange}
          onSendAuthCode={handlePhoneAuthSend}
          resendCount={resendCount}
        />

        <AuthCodeInput
          code={formData.code}
          onChange={handleChange}
          onSubmit={handleSubmit}
          isCheckValid={isCheckValid}
          time={time}
          isPhoneAuthSent={isPhoneAuthSent}
        />
        <SingleConfirmModal
          open={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          children={modalContent}
          cancelText="확인"
        />
      </div>
    </div>
  );
};

export default AddPhoneNumber;
