import MembershipStyle from "@components/Membership/MyMembershipStyle.module.scss";
import MembershipCard from "@components/Membership/MembershipCard";
import { map } from "lodash";
import { MEMBERSHIP_DATA } from "@utils/constants/membership/membership";
import { useSelector } from "react-redux";

function MembershipCardList({ onEventTrigger }) {
  const subscriptionType = useSelector(
    (state) => state.membership.subscriptionType
  );
  console.log("🚀 ~ MembershipCardList ~ subscriptionType:", subscriptionType);

  const membershipData = map(MEMBERSHIP_DATA, (membership, key) => ({
    ...membership,
    isSubscribed: key === subscriptionType,
  }));

  return (
    <div className={MembershipStyle.membershipCardListBox}>
      {map(
        // 오너 멤버십은 표시하지 않습니다. (판매하는 멤버십 중 오너 멤버십은 없음.)
        membershipData.filter((membership) => !membership.isOwner),
        (membership) => (
          <MembershipCard
            key={membership.membershipName}
            membershipName={membership.membershipName}
            isSubscribed={membership.isSubscribed}
            membershipCost={membership.membershipCost}
            placeCount={membership.placeCount}
            keywordCount={membership.keywordCount}
            alarmCount={membership.alarmCount}
            onEventTrigger={onEventTrigger}
          />
        )
      )}
    </div>
  );
}

export default MembershipCardList;
