const API_PATH = {
  autoLogin: "/login/auto",
  refreshAccessToken: "/user/token",
  emailLogin: "/login/email",
  kakaoLogin: "/login/kakao",
  naverLogin: "/login/naver",
  logout: "/login/logout",
  signupByEmail: "/user",
  checkEmailDuplication: "/user/check-email",
  subscribeMembershipWithNicePay: "/membership/subscribe/nicepay",
  sendVerificationCode: "/auth/verify/send-verification-code",
  verifyCode: "/auth/verify/verify-code",
  searchPlace: "/place/places",
  registerPlace: "/place",
  getRegisteredPlace: "/place",
  deleteRegisteredPlace: "/place",
  deleteRegisteredPlaces: "/place/places",
  getRegisteredPlaceDetail: "/place/detail/placeInfo",
  getRegisteredPlaceTableInfo: "/place/detail/rankLog",
  toggleNotification: "/place/notification/toggle",
  deleteKeyword: "/place/keyword",
  registerKeyword: "/place/keyword",
  getMembershipInfo: "/membership",
  sendAuthCodeForPhoneChange: "/auth/verify/send-verification-code/authorized",
  checkAuthCodeForPhoneChange: "/auth/verify/verify-code/authorized",
  changeUserInfo: "/user",
  cancelMembership: "/membership",
  exitUser: "/exit/user",
  exitUserReason: "/exit/reason",
  addPhoneNumber: "/login/phoneNum/notExist",
  kakaoLoginCallback: "/login/callback/kakao",
  naverLoginCallback: "/login/callback/naver",
};

export default API_PATH;
