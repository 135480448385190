import { placeAxiosInstance } from "@utils/api/axios/placeInstance";
import API_PATH from "@utils/constants/api/apiPath";

export const searchPlace = async ({ keyword, placeNameOrUrl, limit, page }) => {
  try {
    const response = await placeAxiosInstance.get(
      `${API_PATH.searchPlace}?keyword=${encodeURIComponent(
        keyword
      )}&placeNameOrUrl=${encodeURIComponent(
        placeNameOrUrl
      )}&limit=${limit}&page=${page}`
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const registerPlace = async (placeDataObject) => {
  try {
    const response = await placeAxiosInstance.post(
      API_PATH.registerPlace,
      placeDataObject
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getRegisteredPlace = async () => {
  try {
    const response = await placeAxiosInstance.get(API_PATH.getRegisteredPlace);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getRegisteredPlaceDetail = async ({ registrationPlaceId }) => {
  console.log(
    "🚀 ~ getRegisteredPlaceDetail ~ registrationPlaceId:",
    registrationPlaceId
  );
  try {
    const response = await placeAxiosInstance.get(
      `${
        API_PATH.getRegisteredPlaceDetail
      }?registrationPlaceId=${encodeURIComponent(registrationPlaceId)}`
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getRegisteredPlaceTableInfo = async ({
  placeId,
  registrationPlaceId,
}) => {
  try {
    const response = await placeAxiosInstance.get(
      `${API_PATH.getRegisteredPlaceTableInfo}?placeId=${encodeURIComponent(
        placeId
      )}&registrationPlaceId=${encodeURIComponent(registrationPlaceId)}`
    );
    console.log("🚀 ~ getRegisteredPlaceTableInfo ~ response:", response);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const toggleNotification = async (
  toggle,
  keywordName,
  registrationPlaceId
) => {
  try {
    const response = await placeAxiosInstance.post(
      API_PATH.toggleNotification,
      {
        toggle,
        keywordName,
        registrationPlaceId,
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deleteKeyword = async ({ registrationPlaceId, keyword }) => {
  console.log("🚀 ~ deleteKeyword ~ keyword:", keyword);
  console.log("🚀 ~ deleteKeyword ~ registrationPlaceId:", registrationPlaceId);
  try {
    const response = await placeAxiosInstance.delete(
      `${API_PATH.deleteKeyword}?registrationPlaceId=${encodeURIComponent(
        registrationPlaceId
      )}&keyword=${encodeURIComponent(keyword)}`
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const registerKeyword = async ({ keywordName, placeId, placeName }) => {
  try {
    const response = await placeAxiosInstance.post(API_PATH.registerKeyword, {
      keywordName,
      placeId,
      placeName,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deleteRegisteredPlaces = async (registrationPlaceIds) => {
  try {
    let queryString;
    let apiPath;

    if (registrationPlaceIds.length === 1) {
      // 배열에 값이 1개인 경우
      queryString = `registrationPlaceId=${encodeURIComponent(
        registrationPlaceIds[0]
      )}`;
      apiPath = API_PATH.deleteRegisteredPlace; // 단일 삭제 경로
    } else {
      // 배열에 값이 여러 개인 경우
      queryString = `registrationPlaceIds=${encodeURIComponent(
        JSON.stringify(registrationPlaceIds)
      )}`;
      apiPath = API_PATH.deleteRegisteredPlaces; // 다중 삭제 경로
    }

    console.log("🚀 ~ deleteRegisteredPlaces ~ queryString:", queryString);

    const response = await placeAxiosInstance.delete(
      `${apiPath}?${queryString}`
    );
    return response;
  } catch (error) {
    throw error;
  }
};
