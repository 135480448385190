import MembershipInfoStyle from "@pages/Mypage/AccountSettings/Account.module.scss";
import ChangeUserInfoForm from "@components/Mypage/AccountSettings/ChangeUserInfoForm";
import MembershipCancelBox from "@components/Mypage/AccountSettings/MembershipCancelBox";
import { useAppNavigate } from "@hooks/navigate/navigate";
import textAnimation from "@assets/styles/textAnimation.module.scss";
const MembershipInfo = () => {
  const { goToExitSurvey } = useAppNavigate();

  const handleDelete = () => {
    goToExitSurvey();
  };

  return (
    <div className={MembershipInfoStyle.membershipInfoBox}>
      <div
        className={`${MembershipInfoStyle.modifyInfoBox} ${textAnimation.fadeIn}`}
      >
        <h1 className={MembershipInfoStyle.title}>회원정보</h1>
        <ChangeUserInfoForm />
      </div>

      <div
        className={`${MembershipInfoStyle.membershipCancelBox} ${textAnimation.fadeIn}`}
      >
        <MembershipCancelBox />
      </div>

      <button
        className={`${MembershipInfoStyle.deleteAccount} `}
        onClick={handleDelete}
      >
        회원탈퇴
      </button>
    </div>
  );
};

export default MembershipInfo;
