const URL_PATH = {
  home: "/",
  login: "/login",
  emailLogin: "/login/emailLogin",
  signup: "/signup",
  signupComplete: "/signup/complete",
  addPhoneNumber: "/signup/addPhoneNumber",
  findEmailPassword: "/findEmailPassword/:isEmail",
  findEmailComplete: "/findEmailPassword/findEmailComplete", // 추가된 경로
  findPasswordComplete: "/findEmailPassword/findPasswordComplete", // 추가된 경로
  rankingSearch: "/rankingSearch/:rankingSearchType/:keyword?",
  rankingSearchResult:
    "/rankingSearch/result/:placeNameOrUrl/:page/:limit/:keyword",
  registeredPlace: "/registeredPlace",
  registeredPlaceDetail: "/registeredPlace/:placeId/:registrationPlaceId",
  membership: "/mypage/membership",
  myMembership: "/mypage/myMembership",
  modifyMemberInfo: "/mypage/modifyMemberInfo",
  completeDeleteAccount: "/mypage/completeDeleteAccount",
  exitSurvey: "/mypage/exitSurvey",
  firstLoginBenefits: "/login/firstLoginBenefits",
  serverError: "/serverError",
  notFound: "/notFound",
  privacyPolicy: "/agreements/privacyPolicy",
  marketingConsent: "/agreements/marketingConsent",
  termsOfService: "/agreements/termsOfService",
  privacyHandlePolicy: "/agreements/privacyHandlePolicy",
  completeDeleteMembership: "/mypage/completeDeleteMembership",
};

export default URL_PATH;
