import styles from "@pages/Agreements/Agreement.module.scss";

const PrivacyHandlePolicy = () => {
  return (
    <div className={styles.privacyPolicy}>
      <div className={styles.container}>
        <div className={styles.logo}>
          <img
            src="/placeholder.svg?height=40&width=40"
            alt="Catchrank logo"
            className={styles.logoIcon}
          />
          <img
            src="/placeholder.svg?height=32&width=120"
            alt="Catchrank"
            className={styles.logoText}
          />
        </div>
        <div className={styles.policyCard}>
          <div className={styles.policyHeader}>
            <h1 className={styles.policyTitle}>개인정보 처리방침</h1>
          </div>
          <div className={styles.policyContent}>
            <p className={styles.contentDescription}>
              주식회사 엠플리파이(이하 "회사"라 함)는 개인정보보호법에 따라
              회사의 서비스(캐치랭크) 이용자의 개인정보 보호 및 권익을 보호하고
              개인정보와 관련한 이용자의 고충을 원활하게 처리할 수 있도록 다음과
              같은 처리방침을 두고 있습니다.
            </p>
            <p className={styles.effectiveDate}>시행일자 : 2024.10.27</p>

            <h2 className={styles.sectionTitle}>1. 개인정보의 수집 목적</h2>
            <ol className={styles.orderedList}>
              <li>이용자의 원활한 회사 서비스 이용</li>
              <li>본인 식별, 민원사항 처리, 불량회원의 부정한 이용방지</li>
              <li>상품 구매, 유료 서비스 이용 시 요금 결제, 청구, 정산</li>
              <li>서비스 검토, 개선, 새로운 서비스 개발</li>
              <li>개별 및 중요한 공지사항 안내, 이용자의 분쟁 처리</li>
              <li>각종 이벤트, 광고성 정보의 제공</li>
            </ol>

            <h2 className={styles.sectionTitle}>2. 개인정보의 수집 항목</h2>
            <ol className={styles.orderedList}>
              <li>
                필수항목(공통) : 이용목적, 사용자 이메일 주소, 비밀번호, 휴대폰
                번호,생년월일
              </li>
              <li>
                유료이용시 선택항목 : 카드사명, 카드번호, 카드 유효기간 등
                결제와 관련된 정보
              </li>
            </ol>

            <h2 className={styles.sectionTitle}>3. 개인정보의 수집 방법</h2>
            <ol className={styles.orderedList}>
              <li>
                서비스의 가입 및 이용과정 중 이용자의 자발적 제공을 통한 수집
              </li>
              <li>
                서비스 이용과정 및 사업처리 과정 중 서비스 프로그램을 통한 자동
                수집
              </li>
            </ol>

            <h2 className={styles.sectionTitle}>4. 개인정보의 공유 및 제공</h2>
            <p className={styles.paragraph}>
              회사는 이용자들의 개인정보를 이용자의 동의, 법령에 규정된 경우,
              수사 목적으로 법령에 정해진 절차와 방법에 따라 수사기관의 요구가
              있는 경우를 제외하고, 목적 범위를 초과하여 이용하거나 제3자에게
              공유 및 제공하지 않습니다.
            </p>

            <h2 className={styles.sectionTitle}>
              5. 이용자 및 법정대리인의 권리와 그 행사방법
            </h2>
            <ol className={styles.orderedList}>
              <li>
                이용자(만 14세 미만인 경우 법정 대리인)는 언제든지 이용자의
                개인정보 조회나 수정을 할 수 있으며, 가입해지(동의 철회)를
                요청할 수 있습니다.
              </li>
              <li>
                개인정보 조회 및 수정은 '내 프로필'에서, 유료 서비스 가입 및
                해지(동의 철회)는 '등급관리'를 통해 가능합니다.
              </li>
              <li>
                이용자가 개인정보의 오류에 대한 정정을 요청하신 경우에는 정정을
                완료하기 전까지 당해 개인정보를 이용 또는 제공하지 않습니다.
                또한, 잘못된 개인정보를 제3자에게 이미 제공한 경우에는 정정
                처리결과를 제3자에게 지체 없이 통지하여 정정이 이루어지도록
                하겠습니다.
              </li>
              <li>
                회사는 이용자의 요청으로 해지 또는 삭제된 개인정보는 "6.
                개인정보의 보유 및 이용기간"에 명시된 바에 따라 처리하고 그 외의
                용도로 열람 또는 이용할 수 없도록 처리하고 있습니다.
              </li>
            </ol>

            <h2 className={styles.sectionTitle}>
              6. 개인정보의 보유 및 이용기간
            </h2>
            <p className={styles.paragraph}>
              회사는 원칙적으로 개인정보의 수집 및 이용목적이 달성되면 이용자의
              개인정보를 파기합니다. 단, 회사 내부방침이나 관계법령의 규정에
              의하여 보존할 필요가 있는 경우에는 일정 기간 개인정보를 보관할 수
              있습니다.
            </p>

            <h2 className={styles.sectionTitle}>
              7. 개인정보 파기절차 및 방법
            </h2>
            <h3 className={styles.subSectionTitle}>(1) 파기절차</h3>
            <ol className={styles.orderedList}>
              <li>
                이용자가 회원가입 등을 위해 입력한 정보는 목적이 달성된 후
                별도의 DB로 옮겨져(종이의 경우 별도의 서류함) 내부 방침 및 기타
                관련 법령에 의한 정보보호 사유에 따라(6. 개인정보의 보유 및
                이용기간 참조) 일정 기간 저장된 후 파기됩니다.
              </li>
              <li>
                동 개인정보는 법률에 의한 경우가 아니고서는 보유되는 이외의 다른
                목적으로 이용되지 않습니다.
              </li>
            </ol>
            <h3 className={styles.subSectionTitle}>(2) 파기방법</h3>
            <ol className={styles.orderedList}>
              <li>
                종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여
                파기합니다.
              </li>
              <li>
                전자적 파일 형태로 저장된 개인정보는 기록을 재생할 수 없는
                기술적 방법을 사용하여 삭제합니다.
              </li>
            </ol>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivacyHandlePolicy;
