import styles from "@layouts/layout.module.scss";
import { Outlet } from "react-router-dom";
import Footer from "@components/Footer/Footer";
import FloatingButtons from "@components/Common/Button/FloatingButtons";
function FooterLayout() {
  return (
    <div className={styles.containerWithFooter}>
      <div className={styles.mainBox}>
        <main>
          <Outlet />
          <FloatingButtons />
        </main>
      </div>
      <Footer />
    </div>
  );
}

export default FooterLayout;
