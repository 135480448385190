import ErrorStyle from "@pages/PageError/error.module.scss";
const NotFound = () => {
  return (
    <div className={ErrorStyle.notFound}>
      <h1 className={ErrorStyle.notFoundTitle}>404</h1>
      <h2 className={ErrorStyle.subtitle}>다시 한번 확인해주세요!</h2>
      <span className={ErrorStyle.notFoundSubTitle}>
        찾을 수 없는 페이지 입니다. <br />
        요청하신 페이지가 사라졌거나, 다른페이지로 변경되었습니다. 주소를 다시
        확인해주세요.
      </span>

      <span className={ErrorStyle.copyright}>@amplify AII Right Reserved</span>
    </div>
  );
};

export default NotFound;
