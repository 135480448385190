import styles from "@pages/Agreements/Agreement.module.scss";

const PrivacyPolicy = () => {
  return (
    <div className={styles.privacyPolicy}>
      <div className={styles.container}>
        <div className={styles.logo}>
          <div alt="Catchrank" className={styles.logoText} />
        </div>
        <div className={styles.policyCard}>
          <div className={styles.policyHeader}>
            <h1 className={styles.policyTitle}>개인정보 처리 방침</h1>
          </div>
          <div className={styles.policyContent}>
            <h2 className={styles.contentTitle}>개인정보 수집·이용 동의서</h2>
            <p className={styles.contentDescription}>
              주식회사 엠플리파이(이하 "회사")는 회사가 캐치랭크 홈페이지를
              통하여 제공하는 서비스 (이하 "서비스")의 원활한 제공을 위하여
              아래와 같이 개인정보를 수집·이용하고자 합니다. 내용을 자세히
              읽으신 후 동의 여부를 결정하여 주십시오.
            </p>
            <div className={styles.policyTableContainer}>
              <table className={styles.policyTable}>
                <thead>
                  <tr>
                    <th className={styles.tableHeader}>항목</th>
                    <th className={styles.tableHeader}>수집목적</th>
                    <th className={styles.tableHeader}>보유 기간</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className={styles.tableCell}>
                      [회원 가입 시]
                      <br />
                      사용자 이름, 사용자 기업명 혹은 소속기관, 사용자 이메일
                      주소, 비밀번호, 휴대폰 번호
                    </td>
                    <td className={styles.tableCell}>
                      회원가입의사 확인, 본인 식별인증, 연령인증, 회원자격 유지
                      및 관리, 서비스 부정이용 방지, 각종 고지·통지, 계약의 이행
                      및 서비스 제공, 상담∙불만∙민원처리, 고지∙안내사항 전달,
                      불법∙부정이용방지
                    </td>
                    <td className={styles.tableCell}>
                      회원 탈퇴 후 3개월 이내
                      <br />
                      단, 회원 탈퇴일 이후에도 관련 법령 위반에 따른 수사, 조사
                      등이 진행중인 경우에는 해당 수사·조사 종료 시까지, 서비스
                      이용에 따른 채권·채무 관계(이용약관 또는 정책에 따른
                      회원의 의무 이행을 포함함)가 잔존하는 경우에는 채권·채무
                      관계 정산/종료 시까지,금융사고 조사, 분쟁 해결, 민원처리,
                      법률상 의무이행 등을 위한 경우에는 관계법령에서 정한
                      일정한 기간 동안 보유·이용.
                    </td>
                  </tr>
                  <tr>
                    <td className={styles.tableCell}>
                      [회원이 직접 등록]
                      <br />
                      카드사명, 카드 종류, 암호화된 카드 식별정보(Key),
                      카드번호, 카드 유효기간, 은행계좌정보 등 결제와 관련된
                      정보
                    </td>
                    <td className={styles.tableCell}>
                      멤버십 정기 결제 서비스 제공
                    </td>
                    <td className={styles.tableCell}>
                      서비스 구매 계약 종료 후 5영업일 이내
                    </td>
                  </tr>
                  <tr>
                    <td className={styles.tableCell}>
                      IP주소, 쿠키, MAC주소, 서비스 이용기록, 방문 기록, 불량
                      이용 기록
                    </td>
                    <td className={styles.tableCell}>
                      서비스의 안정성 확보, 법률, 이용약관 등 위반 행위 제한
                    </td>
                    <td className={styles.tableCell}>
                      회원 탈퇴 후 12개월 이내
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <p className={styles.policyNote}>
              ※ 위와 같이 개인정보를 처리하는데 동의를 거부할 권리가 있습니다.
              <br />
              그러나 동의를 거부할 경우, 회원가입 및 회사가 제공하는 서비스를
              이용할 수 없습니다.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
