import Input from "@components/Mypage/AccountSettings/Input";
import style from "@components/Common/User/CommonUserStyle.module.scss";
import { VALIDATIONS } from "@utils/constants/validation/fieldValidations";
const ChangePhoneNumberInput = ({
  changePhoneNumber,
  handleInputChange,
  handleSendAuthNumber,
  resendCount,
}) => {
  return (
    <div className={style.phoneNumberBox}>
      <Input
        label={VALIDATIONS.changePhoneNumber.label}
        type={VALIDATIONS.changePhoneNumber.type}
        placeholder={VALIDATIONS.changePhoneNumber.placeholder}
        name="changePhoneNumber"
        value={changePhoneNumber}
        onChange={handleInputChange}
        pattern={VALIDATIONS.changePhoneNumber.pattern}
        required={true}
      />
      <button
        className={style.checkAuthNumberButton}
        onClick={handleSendAuthNumber}
        disabled={
          (changePhoneNumber.length !== 10 &&
            changePhoneNumber.length !== 11) ||
          resendCount >= 4
        }
      >
        {resendCount > 0 ? "재발송" : "발송"}
      </button>
    </div>
  );
};

export default ChangePhoneNumberInput;
