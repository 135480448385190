import { useEffect, useState } from "react";
import style from "@components/Common/Button/ButtonStyle.module.scss";
import { COMPANY_INFO } from "@utils/constants/company/companyInfo";
const FloatingButtons = () => {
  const [bottomOffset, setBottomOffset] = useState(0);
  const [isAskActive, setIsAskActive] = useState(false);
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  useEffect(() => {
    const handleScroll = () => {
      const footer = document.querySelector("#footer");
      if (footer) {
        const footerRect = footer.getBoundingClientRect();
        const windowHeight = window.innerHeight;
        // footer가 화면에 보일 때의 오프셋 계산
        const offset = Math.max(0, windowHeight - footerRect.top);
        setBottomOffset(offset);
      }
    };

    window.addEventListener("scroll", handleScroll);
    handleScroll(); // 초기 위치 설정

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const toggleAskButton = () => {
    setIsAskActive((prev) => !prev); // ASK 버튼 상태 토글
  };

  return (
    <div
      className={style.floatingButtonsContainer}
      style={{ marginBottom: `${bottomOffset}px` }}
    >
      <div className={style.askButtonBox}>
        {isAskActive && (
          <div className={style.inquiryBox}>
            <div className={style.inquiryBoxHeader}>
              <div className={style.catchRankIcon}></div>
              <div className={style.inquiryBoxHeaderText}>
                <p className={style.inquiryBoxHeaderTextTitle} title="캐치랭크">
                  캐치랭크
                </p>
                <p
                  className={style.inquiryBoxHeaderTextSubTitle}
                  title="운영시간 평일 10시~18시"
                >
                  운영시간 평일 10시~18시
                </p>
              </div>
            </div>

            <div className={style.mediumBox}>
              <p>
                안녕하세요🙂
                <br />
                궁금하신 점 최대한 빨리 <br />
                답변드리겠습니다.
              </p>
            </div>
            <button className={style.inquiryButton}>
              {COMPANY_INFO.phoneNumber}
              <span className={style.inquiryButtonIcon}></span>
            </button>
          </div>
        )}

        <button
          className={`${style.askButton} ${isAskActive ? style.active : ""}`}
          onClick={toggleAskButton}
        ></button>
      </div>
      <button className={style.topButton} onClick={scrollToTop}>
        <span>TOP</span>
      </button>
    </div>
  );
};

export default FloatingButtons;
