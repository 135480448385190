import { useState } from "react";
import MembershipStyle from "@components/Mypage/AccountSettings/AccountStyle.module.scss";
import Input from "@components/Mypage/AccountSettings/Input";
import { VALIDATIONS } from "@utils/constants/validation/fieldValidations";
import { useSelector } from "react-redux";
import { useAuthCode } from "@hooks/user/useAuthCode";
import { VERIFICATION_TYPE } from "@utils/constants/api/verificationType";
import ChangePhoneNumberInput from "@components/Common/User/PhoneNumberMypageInput";
import AuthCodeInputMypageInput from "@components/Common/User/AuthCodeInputMypageInput";
import { useUserHandler } from "@hooks/user/useUserHandler";

const ChangeUserInfoForm = () => {
  const { handleChangeUserInfo } = useUserHandler();

  const [isVisibleChangePhoneBox, setIsVisibleChangePhoneBox] = useState(false);
  const membershipData = useSelector((state) => state.membership);
  const [isVisibleChangePasswordBox, setIsVisibleChangePasswordBox] =
    useState(false);
  const [isVisibleAuthNumberBox, setIsVisibleAuthNumberBox] = useState(false);

  // TODO :  유저 정보 받아오기 (이메일,휴대폰 번호, 이름)
  const [userInfo, setUserInfo] = useState({
    changePhoneNumber: "",
    authNumber: "",
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  });

  const [checkValid, setCheckValid] = useState({
    checkCurrentPassword: null,
    checkConfirmNewPassword: null,
  });

  const {
    resendCount,
    isPhoneAuthSent,
    time,
    isCheckValid,
    sendAuthCode,
    checkAuthCode,
  } = useAuthCode(VERIFICATION_TYPE.PHONE_CHANGE);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserInfo((prev) => ({ ...prev, [name]: value }));
    // 여기에 유효성 검사 로직 추가
  };

  const isSaveButtonDisabled = () => {
    // 휴대폰 번호 변경 창이 보이는 경우
    if (
      isVisibleChangePhoneBox &&
      (!userInfo.changePhoneNumber || !isCheckValid)
    ) {
      return true; // 휴대폰 번호가 입력되지 않았거나 인증이 완료되지 않은 경우 비활성화
    }
    // 비밀번호 변경 창이 보이는 경우
    if (
      isVisibleChangePasswordBox &&
      (!userInfo.currentPassword ||
        !userInfo.newPassword ||
        !userInfo.confirmPassword ||
        !checkValid.checkConfirmNewPassword)
    ) {
      return true; // 비밀번호 필드가 모두 입력되지 않았거나 새 비밀번호가 확인되지 않은 경우 비활성화
    }
    return false; // 모든 조건이 충족된 경우 활성화
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("submit");
    const form = e.target.form; // form 요소에 접근
    // 휴대폰 번호 변경 창이 보이는 경우 인증이 완료되었는지 확인
    if (isVisibleChangePhoneBox && !isCheckValid) {
      alert("휴대폰 인증이 완료되지 않았습니다.");
      return;
    }
    // 비밀번호 변경 창이 보이는 경우 새로운 비밀번호가 일치하는지 확인
    if (isVisibleChangePasswordBox && !checkValid.checkConfirmNewPassword) {
      alert(
        "새로운 비밀번호를 입력하시거나 비밀번호가 일치하는지 확인해주세요."
      );
      return;
    }

    if (form.checkValidity()) {
      try {
        handleChangeUserInfo({
          currentPassword: userInfo.currentPassword,
          newPassword: userInfo.newPassword,
          phoneNumber: userInfo.changePhoneNumber,
        });

        alert("회원정보가 변경되었습니다.");
        window.location.reload();
      } catch (error) {
        if (error && error.status === 404) {
          alert("현재 비밀번호가 일치하지 않습니다.");
          setCheckValid((prev) => ({
            ...prev,
            checkCurrentPassword: false,
          }));
        }
      }
    } else {
      form.reportValidity(); // 유효하지 않은 입력에 대해 사용자에게 알림
    }
  };

  const resetForm = () => {
    window.location.reload();
  };

  const showChangePhoneNumberBox = (e) => {
    e.preventDefault();
    setIsVisibleChangePhoneBox(true);
  };

  const handleSendAuthNumber = async (e) => {
    e.preventDefault();
    try {
      await sendAuthCode({
        name: membershipData.name,
        phoneNumber: membershipData.phoneNumber,
        newPhoneNumber: userInfo.changePhoneNumber,
      });
      setIsVisibleAuthNumberBox(true);
    } catch (error) {
      console.error("Error sending auth code:", error);
    }
  };

  const showChangePasswordBox = (e) => {
    e.preventDefault();
    setIsVisibleChangePasswordBox(!isVisibleChangePasswordBox);
  };

  const handleCheckAuthNumber = async (e) => {
    e.preventDefault();

    try {
      await checkAuthCode({
        phoneNumber: membershipData.phoneNumber,
        code: userInfo.authNumber,
        newPhoneNumber: userInfo.changePhoneNumber,
      });
    } catch (error) {}
  };

  const handleCheckPassword = (e) => {
    e.preventDefault();

    if (userInfo.newPassword === userInfo.confirmPassword) {
      setCheckValid((prev) => ({
        ...prev,
        checkConfirmNewPassword: true,
      }));
    } else {
      setCheckValid((prev) => ({
        ...prev,
        checkConfirmNewPassword: false,
      }));
    }
  };
  return (
    <div className={MembershipStyle.changeUserInfoForm}>
      <form
        className={MembershipStyle.changeUserInfoFormBox}
        onSubmit={handleSubmit}
        autoComplete="off"
      >
        <Input
          label={VALIDATIONS.email.label}
          type={VALIDATIONS.email.type}
          name="email"
          value={membershipData.email} // 이메일 수정 불가
          disabled={true}
        />
        <div className={MembershipStyle.phoneNumberBox}>
          <Input
            label={VALIDATIONS.phoneNumber.label}
            type={VALIDATIONS.phoneNumber.type}
            name="phoneNumber"
            value={membershipData.phoneNumber}
            onChange={handleInputChange}
            disabled={true}
          />

          <button
            className={MembershipStyle.changePhoneNumberButton}
            onClick={showChangePhoneNumberBox}
          >
            변경
          </button>
        </div>

        {isVisibleChangePhoneBox ? (
          <ChangePhoneNumberInput
            changePhoneNumber={userInfo.changePhoneNumber}
            handleInputChange={handleInputChange}
            handleSendAuthNumber={handleSendAuthNumber}
            resendCount={resendCount}
          />
        ) : null}

        {isVisibleAuthNumberBox ? (
          <AuthCodeInputMypageInput
            authNumber={userInfo.authNumber}
            handleInputChange={handleInputChange}
            handleCheckAuthNumber={handleCheckAuthNumber}
            isCheckValid={isCheckValid}
            time={time}
            isPhoneAuthSent={isPhoneAuthSent}
          />
        ) : null}

        <Input
          label={VALIDATIONS.name.label}
          type={VALIDATIONS.name.type}
          name="name"
          value={membershipData.name}
          disabled={true}
        />
        <div className={MembershipStyle.visibleChangePasswordInputs}>
          <span>비밀번호</span>
          <button
            className={MembershipStyle.changePasswordButton}
            onClick={showChangePasswordBox}
          >
            변경
          </button>
        </div>

        {isVisibleChangePasswordBox ? (
          <>
            <Input
              label="현재 비밀번호"
              type={VALIDATIONS.password.type}
              name="currentPassword"
              value={userInfo.currentPassword}
              onChange={handleInputChange}
              placeholder="현재 비밀번호"
              pattern={VALIDATIONS.password.pattern}
              required={true}
              error={
                checkValid.checkCurrentPassword === false
                  ? "입력하신 비밀번호가 일치하지 않습니다."
                  : ""
              }
              maxLength={VALIDATIONS.password.maxLength}
            />

            <Input
              label="새 비밀번호"
              type={VALIDATIONS.password.type}
              name="newPassword"
              value={userInfo.newPassword}
              onChange={handleInputChange}
              placeholder="새 비밀번호 (영문,소문자와 숫자포함 8~16자)"
              pattern={VALIDATIONS.password.pattern}
              required={true}
              maxLength={VALIDATIONS.password.maxLength}
              autoComplete="new-password"
            />

            <Input
              label="새 비밀번호 확인"
              type={VALIDATIONS.password.type}
              name="confirmPassword"
              value={userInfo.confirmPassword}
              onChange={handleInputChange}
              placeholder="비밀번호를 다시 한번 입력해주세요."
              pattern={VALIDATIONS.password.pattern}
              required={true}
              maxLength={VALIDATIONS.password.maxLength}
              error={
                checkValid.checkConfirmNewPassword === false
                  ? "입력하신 비밀번호가 일치하지 않습니다."
                  : ""
              }
              check={
                checkValid.checkConfirmNewPassword === true
                  ? "비밀번호가 일치합니다."
                  : ""
              }
              onBlur={handleCheckPassword}
            />
          </>
        ) : null}

        {/* 변경 항목이 있는 경우 저장 버튼 visible */}
        {isVisibleChangePhoneBox || isVisibleChangePasswordBox ? (
          <div className={MembershipStyle.buttonBox}>
            <button
              type="submit"
              onClick={handleSubmit}
              disabled={isSaveButtonDisabled()}
            >
              저장
            </button>
            <button type="reset" onClick={resetForm}>
              취소
            </button>
          </div>
        ) : null}
      </form>
    </div>
  );
};

export default ChangeUserInfoForm;
