import loginStyle from "@components/Login/LoginStyle.module.scss";
import { useAppNavigate } from "@hooks/navigate/navigate";
import { handleKakaoLogin } from "@utils/api/login/login";

function LoginButtons() {
  const { goToEmailLogin } = useAppNavigate();

  return (
    <div className={loginStyle.loginCommonContainer}>
      <button
        onClick={handleKakaoLogin}
        className={`${loginStyle.commonLoginButtons} ${loginStyle.kakaoLoginButton}`}
      >
        Kakao 로그인 하기
      </button>

      <button
        className={`${loginStyle.commonLoginButtons} ${loginStyle.emailLoginButton}`}
        onClick={() => goToEmailLogin(true)}
      >
        이메일 로그인 하기
      </button>
    </div>
  );
}

export default LoginButtons;
