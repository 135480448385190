import styles from "@components/RankingSearch/RankingSearchStyle.module.scss";
import PropTypes from "prop-types";

const RankingSearchInput = ({
  label,
  type = "text",
  name,
  value,
  onChange,
  placeholder,
  pattern,
  required,
  autoComplete,
}) => {
  return (
    <div className={styles.rankingSearchInputContainer}>
      {label && (
        <label htmlFor={name} className={styles.rankingSearchInputLabel}>
          {label}
        </label>
      )}
      <input
        className={styles.rankingSearchInput}
        type={type}
        name={name}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        pattern={pattern}
        required={required}
        autoComplete={autoComplete}
      />
    </div>
  );
};

RankingSearchInput.propTypes = {
  label: PropTypes.string,
  type: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  pattern: PropTypes.string,
};

export default RankingSearchInput;
