import { useContext, useEffect } from "react";
import AuthenticatedApp from "@routes/AuthenticatedApp";
import UnauthenticatedApp from "@routes/UnAuthenticatedApp";
import AuthContext from "@utils/contexts/AuthContext";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchMembershipStart,
  fetchMembershipInitial,
} from "@features/membership/membershipSlice";
import { autoLogin } from "@utils/api/login/login";
import { setMembershipData } from "@utils/functions/membership/membership";
import Loader from "@components/Common/Loader/Loader";
import AuthHeader from "@components/Header/AuthHeader";
import { API_URL } from "@utils/constants/api/api";

function App() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false); // 로딩 상태 추가
  const { isLoggedIn, login, logout } = useContext(AuthContext);
  console.log(isLoggedIn);
  console.log("Current API URL:", API_URL);

  const membershipData = useSelector((state) => state.membership);
  console.log(membershipData);

  useEffect(() => {
    const handleAutoLogin = async () => {
      console.info("handleAutoLogin 호출됨");
      try {
        console.info("try 블록 진입");
        setLoading(true);
        console.info("setLoading(true) 호출");
        dispatch(fetchMembershipStart());
        console.info("dispatch(fetchMembershipStart()) 호출");
        console.info("autoLogin 호출 전");
        const data = await autoLogin();
        console.log("🚀 ~ handleAutoLogin ~ data:", data);
        console.info("autoLogin 호출 후");
        await setMembershipData(data, dispatch);
        console.info("setMembershipData 호출 후");
        login();
      } catch (error) {
        console.log("🚀 ~ handleAutoLogin ~ error:", error);
        dispatch(fetchMembershipInitial());
        logout();
      } finally {
        setLoading(false); // 로딩 완료
      }
    };

    handleAutoLogin();
  }, [dispatch]);

  if (loading || membershipData.loading) {
    return <Loader />;
  }

  return (
    // url 주소를 바라보고 있음 변경 되면 어떤 것을 보여줄지 결정해줌
    <>
      {console.log("로그인 상태", isLoggedIn)}
      {isLoggedIn ? <AuthHeader /> : null}
      {isLoggedIn ? <AuthenticatedApp /> : <UnauthenticatedApp />}
    </>
  );
}

export default App;
