import style from "./NicePay.module.scss";
import { getFormattedDate } from "@utils/functions/format/date";
import { useSelector } from "react-redux";
import { useState } from "react";
import OverlayLoader from "@components/Common/Loader/OverlayLoader";
import { useMembershipHandlers } from "@hooks/membership/useMembershipHandlers";
const PaymentModal = ({ amt, subscriptionType, closeModal }) => {
  const membershipData = useSelector((state) => state.membership);
  const { handleSubscribeMembership } = useMembershipHandlers();
  const [isLoading, setIsLoading] = useState(false);
  const ediDate = getFormattedDate();
  const moid = `${membershipData.userId}_${ediDate}`;

  const [paymentData, setPaymentData] = useState({
    cardNumber1: "",
    cardNumber2: "",
    cardNumber3: "",
    cardNumber4: "",
    validityPeriodMonth: "",
    validityPeriodYear: "",
    cardPassword: "",
    idType: "birthday",
    identificationNumber: "",
  });

  const transformPaymentData = (
    paymentData,
    subscriptionType,
    amt,
    moid,
    ediDate
  ) => {
    return {
      subscriptionInfo: {
        subscriptionType: subscriptionType,
        price: 100,
        cardNo: `${paymentData.cardNumber1}${paymentData.cardNumber2}${paymentData.cardNumber3}${paymentData.cardNumber4}`,
        expYear: paymentData.validityPeriodYear,
        expMonth: paymentData.validityPeriodMonth,
        idNo: paymentData.identificationNumber,
        cardPw: paymentData.cardPassword,
        mOid: moid,
        ediDate: ediDate,
      },
    };
  };

  const handlePayment = async (e) => {
    e.preventDefault();

    const form = e.target.form;
    console.log("🚀 ~ handlePayment ~ form:", form);
    if (!form.checkValidity()) {
      form.reportValidity();
      return;
    }

    const paymentPayload = transformPaymentData(
      paymentData,
      subscriptionType,
      amt,
      moid,
      ediDate
    );
    console.log("Payment Payload:", paymentPayload);
    try {
      await handleSubscribeMembership(paymentPayload);
      closeModal();
    } catch (error) {
      console.error("🚀 ~ handlePayment ~ error:", error);
    }
  };

  const handlePaymentDataChange = (e) => {
    setPaymentData({ ...paymentData, [e.target.name]: e.target.value });
  };

  return (
    <div className={style.paymentModalBox}>
      {isLoading && <OverlayLoader />}
      <div className={style.paymentModalContent}>
        <div className={style.closeButton} onClick={closeModal}></div>
        <div className={style.textBox}>
          <h2 className={style.modalTitle}>정기결제 카드정보</h2>
          <div className={style.productInfo}>
            <span>구독권 : {subscriptionType}</span>
            <span>{amt}원</span>
          </div>
        </div>
        <form className={style.paymentForm}>
          <div className={style.inputGroup}>
            <label>카드 번호</label>
            <div className={style.cardNumberInput}>
              <input
                type="password"
                maxLength="4"
                placeholder="****"
                pattern="\d{4}"
                name="cardNumber1"
                value={paymentData.cardNumber1}
                onChange={handlePaymentDataChange}
                required
              />
              <input
                type="text"
                maxLength="4"
                pattern="\d{4}"
                name="cardNumber2"
                value={paymentData.cardNumber2}
                onChange={handlePaymentDataChange}
                required
              />
              <input
                type="text"
                maxLength="4"
                pattern="\d{4}"
                name="cardNumber3"
                value={paymentData.cardNumber3}
                onChange={handlePaymentDataChange}
                required
              />
              <input
                type="password"
                maxLength="4"
                placeholder="****"
                pattern="\d{4}"
                name="cardNumber4"
                value={paymentData.cardNumber4}
                onChange={handlePaymentDataChange}
                required
              />
            </div>
          </div>
          <div className={style.cardInfoInput}>
            <div className={style.validityPeriodInput}>
              <label>유효기간</label>
              <div>
                <input
                  type="text"
                  maxLength="2"
                  placeholder="MM"
                  pattern="\d{2}"
                  name="validityPeriodMonth"
                  value={paymentData.validityPeriodMonth}
                  onChange={handlePaymentDataChange}
                  required
                />
                <input
                  type="text"
                  maxLength="2"
                  placeholder="YY"
                  pattern="\d{2}"
                  name="validityPeriodYear"
                  value={paymentData.validityPeriodYear}
                  onChange={handlePaymentDataChange}
                  required
                />
              </div>
            </div>

            <div className={style.cardPasswordInput}>
              <label>카드 비밀번호</label>
              <input
                type="password"
                maxLength="2"
                placeholder="앞 두자리"
                pattern="\d{2}"
                name="cardPassword"
                value={paymentData.cardPassword}
                onChange={handlePaymentDataChange}
                required
              />
            </div>
          </div>
          <div className={style.inputGroup}>
            <label>생년월일 또는 사업자번호</label>
            <select
              name="idType"
              defaultValue={paymentData.idType || "birthday"}
              onChange={handlePaymentDataChange}
              className={style.idTypeSelect}
            >
              <option value="birthday">생년월일</option>
              <option value="businessId">사업자번호</option>
            </select>
            <input
              type="text"
              maxLength={paymentData.idType === "businessId" ? "10" : "6"}
              placeholder={
                paymentData.idType === "businessId"
                  ? "사업자번호 10자리"
                  : "YYMMDD"
              }
              pattern={
                paymentData.idType === "businessId" ? "\\d{10}" : "\\d{6}"
              }
              name="identificationNumber"
              value={paymentData.identificationNumber}
              onChange={handlePaymentDataChange}
              required
            />
          </div>
          <div className={style.paymentButtonBox}>
            <button className={style.paymentButton} onClick={handlePayment}>
              결제하기
            </button>
          </div>

          {/* 상품명, 가격 파싱된 데이터 표시 */}
        </form>
      </div>
    </div>
  );
};

export default PaymentModal;
