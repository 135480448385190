import { handle401Error } from "@utils/api/axios/handle401Errors";
import { API_URL } from "@utils/constants/api/api";
import axios from "axios";
export const membershipAxiosInstance = axios.create({
  headers: { "content-type": "application/json" },
  baseURL: API_URL,
  withCredentials: true, // 쿠키를 포함하여 요청
});

// 요청 인터셉터 추가하기
membershipAxiosInstance.interceptors.request.use(
  function (config) {
    // 요청이 전달되기 전에 작업 수행
    console.log("🚀 ~ 요청 인터셉터 ~ config:", config);
    return config;
  },
  function (error) {
    // 요청 오류가 있는 작업 수행
    return Promise.reject(error);
  }
);

// 응답 인터셉터 추가하기
membershipAxiosInstance.interceptors.response.use(
  function (response) {
    // 2xx 범위에 있는 상태 코드는 이 함수를 트리거 합니다.
    console.log("🚀 ~ 응답 인터셉터 ~ response:", response);
    return response;
  },
  async function (error) {
    if (error.response) {
      switch (error.response.status) {
        case 401:
          try {
            const response = await handle401Error(
              error,
              membershipAxiosInstance
            );
            if (response) {
              return response;
            }
          } catch (handleError) {
            console.error("🚀 ~ handle401Error 실패:", handleError);
            return Promise.reject(handleError);
          }
          break;
        default:
          return Promise.reject(error); // 에러를 호출한 쪽으로 전파
      }
    } else {
      return Promise.reject(error); // 에러를 호출한 쪽으로 전파
    }
  }
);
