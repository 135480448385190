export const createSessionStorage = (key, value) => {
  if (!value) {
    return;
  }
  sessionStorage.setItem(key, value);
};

export const readSessionStorage = (key) => {
  if (!key) {
    return;
  }
  return sessionStorage.getItem(key);
};

export const removeSessionStorage = (key) => {
  if (!key) {
    return;
  }
  sessionStorage.removeItem(key);
};

export const clearSessionStorage = () => {
  sessionStorage.clear();
};
