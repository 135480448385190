// 현재 날짜를 yyyyMMddhhmmss 형식으로 변환
export const getFormattedDate = () => {
  const date = new Date();
  return date
    .toISOString()
    .replace(/[-:T.Z]/g, "")
    .slice(0, 14);
};

export const formatDateToYMD = (date) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  return `${year}.${month}.${day}`;
};
