import findEmailPasswordStyles from "@components/FindEmailPassword/FindEmailPasswordStyle.module.scss";
import { useAppNavigate } from "@hooks/navigate/navigate";

const FindEmailCompleteButtons = ({
  isFindPasswordComplete = false,
  email,
}) => {
  const { goToLogin, goToFindEmailPassword, goToEmailLogin } = useAppNavigate();

  return (
    <div className={findEmailPasswordStyles.findEmailCompleteButtonsContainer}>
      {email ? (
        <button
          className={findEmailPasswordStyles.findEmailCompleteButton}
          onClick={goToLogin}
        >
          로그인 하러가기
        </button>
      ) : (
        <button
          className={findEmailPasswordStyles.findEmailCompleteButton}
          onClick={goToEmailLogin}
        >
          로그인 하러가기
        </button>
      )}

      {!isFindPasswordComplete && email && (
        <button
          className={findEmailPasswordStyles.findEmailCompleteButton}
          onClick={goToFindEmailPassword}
        >
          비밀번호 찾기
        </button>
      )}
    </div>
  );
};

export default FindEmailCompleteButtons;
