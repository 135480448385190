import styles from "@components/Membership/MyMembershipStyle.module.scss";
import { MEMBERSHIP_DATA } from "@utils/constants/membership/membership";
export default function PayGuideBox({ subscriptionType }) {
  return (
    <div className={styles.payGuideBox}>
      <span className={styles.membershipCostText}>
        {"최종 월별 결제금액 "}
        <span className={styles.membershipCost}>
          {MEMBERSHIP_DATA[subscriptionType].membershipCost}원
        </span>
      </span>
      <span>
        *멤버십을 변경할 경우 변경 및 결제가 즉시 적용됩니다. <br />
      </span>
      <span className={styles.changeLineHeight}>
        *이전 멤버십은 이용 기간 시작 달 기준으로 일자를 측정하여 <br />
        사용하지 않은 일자를 계산해 카드사로 2-5일 이내로 환불됩니다.
        <br />
      </span>
      <span>
        *모든 멤버십은 월 구독 상품으로 중도해지가 되지 않을 시 매달 정기결제가
        이루어집니다.
      </span>
    </div>
  );
}
