import styles from "@pages/Mypage/MyMembership/MyMembership.module.scss";
import { useAppNavigate } from "@hooks/navigate/navigate";
const CompleteDeleteMembership = () => {
  const { goToHome } = useAppNavigate();
  return (
    <div className={styles.completeDeleteAccountBox}>
      <h1 className={styles.title}>멤버십 해지가 완료되었습니다.</h1>
      <button className={styles.button} onClick={goToHome}>
        홈으로 가기
      </button>
    </div>
  );
};
export default CompleteDeleteMembership;
