import { Routes, Route } from "react-router-dom";
import FooterLayout from "@layouts/MainLayoutWithFooter";
import FirstLoginBenefits from "@pages/Login/FirstLoginBenefits";
import RankingSearch from "@pages/RankingSearch/RankingSearch";
import RankingSearchResult from "@pages/RankingSearch/RankingSearchResult";
import RegisteredPlace from "@pages/RegisteredPlace/PlaceSearchListView";
import DetailView from "@pages/DetailRegisteredPlace/DetailView";
import MyMembershipFromMypage from "@pages/Mypage/MyMembership/MyMembership";
import MembershipInfo from "@pages/Mypage/AccountSettings/MembershipInfo";
import NotFound from "@pages/PageError/NotFound";
import ServerError from "@pages/PageError/ServerError";
import MyMembership from "@pages/MyMembership/MyMembership";
import PrivacyHandlePolicy from "@pages/Agreements/PrivacyHandlePolicy";
import TermsOfService from "@pages/Agreements/TermsOfService";
import ExitSurvey from "@pages/Mypage/MyMembership/ExitSurvey";

import CompleteDeleteMembership from "@pages/Mypage/MyMembership/CompleteDeleteMembership";
function AuthenticatedApp() {
  return (
    <>
      <Routes>
        <Route path="/" element={<FooterLayout />}>
          <Route index element={<RankingSearch />} />
          <Route path="/login">
            <Route path="firstLoginBenefits" element={<FirstLoginBenefits />} />
          </Route>

          <Route path="/rankingSearch">
            <Route
              path=":rankingSearchType/:keyword?"
              element={<RankingSearch />}
            />
            <Route
              path="result/:placeNameOrUrl/:page/:limit/:keyword"
              element={<RankingSearchResult />}
            />

            <Route path="result/*" element={<RankingSearchResult />} />
          </Route>
          <Route path="/registeredPlace">
            <Route index element={<RegisteredPlace />} />
            <Route
              path=":placeId/:registrationPlaceId"
              element={<DetailView />}
            />
          </Route>

          <Route path="/mypage">
            <Route path="membership" element={<MyMembership />} />
            <Route path="myMembership" element={<MyMembershipFromMypage />} />
            <Route path="modifyMemberInfo" element={<MembershipInfo />} />
            <Route
              path="completeDeleteMembership"
              element={<CompleteDeleteMembership />}
            />
            <Route path="exitSurvey" element={<ExitSurvey />} />
          </Route>
        </Route>
        <Route path="/serverError" element={<ServerError />} />
        <Route path="/notFound" element={<ServerError />} />
        <Route path="*" element={<NotFound />} /> // 마지막에 추가
        <Route path="/agreements">
          <Route path="termsOfService" element={<TermsOfService />} />
          <Route path="privacyHandlePolicy" element={<PrivacyHandlePolicy />} />
        </Route>
      </Routes>
    </>
  );
}

export default AuthenticatedApp;
