import { useState, useEffect, useRef, useCallback } from "react";
import { useParams, useNavigate } from "react-router-dom";
import KeywordCountBox from "@components/RankingSearch/KeywordCountBox";
import StoreInfoCardList from "@components/RankingSearch/StoreInfoCardList";
import style from "@pages/RankingSearch/RankingSearch.module.scss";
import { useAppNavigate } from "@hooks/navigate/navigate";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { usePlaceHandlers } from "@hooks/place/usePlaceHandlers";
import { SearchPlaceListDTO } from "@dto/place/SearchPlaceListDTO";
import { MonthlyCountDTO } from "@dto/place/MonthlyCountDTO";
import OverlayLoader from "@components/Common/Loader/OverlayLoader";
import RANKING_SEARCH_TYPE from "@utils/constants/place/rangkintSearch";
import URL_PATH from "@utils/constants/paths/routes";

const RankingSearchResult = () => {
  const { goToRankingSearch } = useAppNavigate();
  const scrollAnchorRef = useRef(null); // ref 생성
  const { keyword, placeNameOrUrl, page, limit } = useParams();

  const navigate = useNavigate(); // useNavigate 훅을 사용하여 URL을 업데이트
  const [currentPage, setCurrentPage] = useState(parseInt(page, 10) || 1);
  const [isLoading, setIsLoading] = useState(true);
  const [isNoData, setIsNoData] = useState(false);
  const [isLastPage, setIsLastPage] = useState(true);
  const [placeList, setPlaceList] = useState([]);
  const [monthlyCount, setMonthlyCount] = useState(new MonthlyCountDTO({}));
  const [functionType, setFunctionType] = useState();
  const { handleSearchPlace } = usePlaceHandlers(null);

  useEffect(() => {
    setIsLoading(true);
    getAndSetPlaceSearchResults();
  }, [page]); // page가 변경될 때마다 데이터 로드

  useEffect(() => {
    if (placeList.length > 0) {
      setIsLoading(false);
    }
  }, [placeList]);

  useEffect(() => {
    if (currentPage !== 1) {
      navigate(
        URL_PATH.rankingSearchResult
          .replace(":placeNameOrUrl", placeNameOrUrl)
          .replace(":page", currentPage)
          .replace(":limit", limit)
          .replace(":keyword", keyword),
        { replace: true }
      );
    }
  }, [currentPage]);

  const handleIntersection = useCallback(
    (entries) => {
      if (entries[0].isIntersecting && !isLoading && !isLastPage) {
        setIsLoading(true);
        setTimeout(() => {
          setCurrentPage((prevPage) => prevPage + 1);
        }, 500); // 500ms 대기 후 페이지 증가
      }
    },
    [isLoading, isLastPage]
  );

  useEffect(() => {
    const observer = new IntersectionObserver(handleIntersection, {
      threshold: 0.8,
    });

    if (scrollAnchorRef.current) {
      observer.observe(scrollAnchorRef.current);
    }

    return () => {
      if (scrollAnchorRef.current) {
        observer.unobserve(scrollAnchorRef.current);
      }
      observer.disconnect();
    };
  }, [handleIntersection]);

  const isUrl = (string) => {
    try {
      new URL(string);
      return true;
    } catch (_) {
      return false;
    }
  };

  const getAndSetPlaceSearchResults = async () => {
    try {
      const dataObject = await handleSearchPlace({
        keyword,
        placeNameOrUrl,
        page,
        limit,
      });
      const searchPlaceListDTO = new SearchPlaceListDTO(dataObject);
      const placeList = searchPlaceListDTO.getPlaceList();
      console.log(
        "🚀 ~ getAndSetPlaceSearchResults ~ placeList.length:",
        placeList.length
      );
      console.log("🚀 ~ getAndSetPlaceSearchResults ~ page:", page);

      if (placeList.length === 0 && currentPage === 1) {
        setIsNoData(true);
        return;
      }
      if (placeList.length < limit) {
        setIsLastPage(true);
      } else {
        setIsLastPage(false);
      }

      setPlaceList((prevList) => [...prevList, ...placeList]);
      setMonthlyCount(searchPlaceListDTO.getMonthlyCount());
      setFunctionType(searchPlaceListDTO.getFunctionType());
      setIsNoData(false);
      setIsLoading(false);
    } catch (error) {
      console.log("🚀 ~ getAndSetPlaceSearchResults ~ error:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className={style.rankingSearchResultContainer}>
      {isLoading && <OverlayLoader />}
      <div className={style.rankingSearchResultTopContainer}>
        {isNoData && (
          <button
            className={style.rankingSearchResultBackButton}
            onClick={() => {
              goToRankingSearch();
            }}
          >
            다시 검색하러 가기
          </button>
        )}

        <h1 className={style.rankingSearchResultTitle}>
          검색한 키워드 <span>{keyword}</span>
        </h1>

        <KeywordCountBox isNoData={isNoData} monthlyCount={monthlyCount} />
      </div>
      <div className={style.storeInfoCardListContainer}>
        <div className={style.storeInfoCardListTitleBox}>
          <span className={style.storeInfoCardListTitle}>실시간 검색 결과</span>

          <i
            className={style.rankingSearchResultQuestionMark}
            data-tooltip-id="alarmInfo"
          ></i>

          <ReactTooltip
            place="right"
            type="dark"
            effect="solid"
            id="alarmInfo"
            multiline={true}
            className={style.customTooltip}
          >
            <span>
              키워드 순위 알람을 받고 싶다면
              <br />
              업체 등록 후 키워드를 추가해 주세요!
            </span>
          </ReactTooltip>
        </div>

        {
          <StoreInfoCardList
            placeList={placeList}
            monthlyCount={monthlyCount}
            functionType={functionType}
            isLoading={isLoading}
          />
        }
      </div>

      {!isUrl(placeNameOrUrl) && (
        <div className={style.rankingSearchResultBottomContainer}>
          <div className={style.rankingSearchResultDescription}>
            {/* TODO : 이름으로 검색한 경우에만 visible 처리
          default : invisible   */}
            <p className={style.rankingSearchResultDescriptionText}>
              업장 이름이 없으신가요?
              <br />
              <span className={style.blueText}>
                그렇다면 정확한 URL을 입력해 주 세요.
              </span>
            </p>
            <span
              className={style.rankingSearchResultDescriptionLink}
              onClick={() => {
                goToRankingSearch({
                  rankingSearchType: RANKING_SEARCH_TYPE.URL_SEARCH,
                  isReplace: true,
                  keyword: keyword,
                });
              }}
            >
              URL 검색 가기<i></i>
            </span>
          </div>
        </div>
      )}

      {!isLastPage && !isLoading && (
        <div
          ref={scrollAnchorRef}
          id="scroll-anchor"
          style={{ height: "30px" }}
        ></div>
      )}
    </div>
  );
};

export default RankingSearchResult;
