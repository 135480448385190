import signupStyle from "@pages/SignUp/SignUp.module.scss";
import smileIcon from "@assets/icons/smile_icon.svg";
import SignUpCompleteButtons from "@components/SignUp/SignUpCompleteButtons";
function SignupPage() {
  return (
    <div className={signupStyle.signupCompleteContainer}>
      <img className={signupStyle.signupCompleteIcon} src={smileIcon}></img>
      <div className={signupStyle.signupCompleteTitle}>
        캐치랭크의 회원이 되신 걸 환영합니다.
      </div>
      <div className={signupStyle.signupCompleteSubtitle}>
        한눈에 쏙! 편하고 쉽게 이용해 보세요!
      </div>

      <div className={signupStyle.completeButtonsContainer}>
        <SignUpCompleteButtons />
      </div>
    </div>
  );
}

export default SignupPage;
