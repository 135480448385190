import styles from "@pages/Agreements/Agreement.module.scss";

const policyOfService = () => {
  return (
    <div className={styles.privacyPolicy}>
      <div className={styles.container}>
        <div className={styles.logo}>
          <div className={styles.logoText} />
        </div>
        <div className={styles.policyCard}>
          <div className={styles.policyHeader}>
            <h1 className={styles.policyTitle}>서비스 이용약관</h1>
          </div>
          <div className={styles.policyContent}>
            <div className={styles.container}>
              <h2>제 1 조 (목적)</h2>

              <p className={styles.contentDescription}>
                이약관은주식회사엠플리파이(이하“회사”)가제공하는캐치랭크및캐치랭크관련제반서비스(이하
                “서비스”)에가입한회원이서비스를이용함에있어회원및회사간의제반권리의무와관련절차등을
                규정하는데그목적이있습니다
              </p>

              <h2>제 2 조 (용어의 정의) </h2>
              <p>
                이메일”이란 회원이 서비스에 가입할 때 회사가 회원을 식별하기
                위해 승인하는 문자와 숫자의 조합을 말합니다. 아이디는 이메일
                주소 형식으로 되어 있습니다. “회원”이란 서비스를 이용하기 위해
                이 약관에 따라 회사와 이용계약을 체결하고 회사가 제공하는
                서비스를 이용 하는 고객을 말합니다. “계정”이란 회원이 서비스를
                이용하기 위해 회원가입을 하면 생성되는 고유한 식별 표지를
                말합니다. “실시간 순위 분석” 이란 입력한 키워드를 분석하여 시장
                규모 예측을 위한 데이터, 해당 키워드에 해당하는 순위에 따른 종합
                지표 등 키워드와 관련된 지표를 확인할 수 있는 기능을 말합니다.
                “순위”란 네이버 지도에 등록된 플레이스 업장이 네이버 검색
                기준으로 PC 와 모바일에서 몇 페이지의 몇 번째에 노출되고 있는지
                검색할 수 있는 기능을 말합니다. “월간 검색수”란 네이버에서 해당
                키워드의 월간 검색량을 말합니다. “월간 블로그 발행량”은
                네이버블로그에서 해당 키워드로 최근 30일 동안 발행된 블로그의
                수를 말합니다. “방문자 리뷰”는 해당 플레이스에 방문한 고객들의
                영수증 리뷰 작성한 누적 수를 의미합니다. “블로그 리뷰” 는 해당
                플레이스가 언급된 블로그 컨텐츠의 누적 수를 의미합니다.
                “저장수”는 해당 플레이스가 네이버 지도에서 네이버 유저 에게
                저장된 갯수를 의미합니다. “등록한 플레이스”란 검색 결과내 업장
                등록을 클릭한 플레이스만 노출되며 등록한 키워드 플친 알람, SMS
                외 히스토리 로그를 제공하고 있습니다. “등록가능한 플레이스 수”란
                구독권에 따른 등록 가능한 잔여 플레이스 수를 나타냅니다. “순위
                알림”이란 하루에 한번씩 키워드에 따른 플레이스 순위를 카카오
                플친 메세지 또는 문자메세지로 알려주는 기능을 말합니다.
              </p>

              <h2>제 3 조 (회사정보 등의 제공)</h2>
              <p>
                회사는 다음 각 호의 사항을 회원이 알아보기 쉽도록 서비스 등 내에
                표시합니다. 다만, 개인정보처리방침과 약관은 회원이 연결화면을
                통하여 볼 수 있도록 할 수 있습니다. 상호 및 대표자의 성명 영업소
                소재지 주소(회원의 불만을 처리할 수 있는 곳의 주소를 포함한다)
                전화번호, 전자우편주소 사업자 등록번호 통신판매업 신고번호
                개인정보처리방침 이용약관
              </p>

              <h2>제 4 조 (약관의 명시, 효력 및 개정)</h2>

              <p>
                회사는 다음 각 호의 사항을 회원이 알아보기 쉽도록 서비스 등 내에
                표시합니다. 다만, 개인정보처리방침과 약관은 회원이 연결화면을
                통하여 볼 수 있도록 할 수 있습니다. 이 약관은 서비스에 게시하여,
                회원에게 공지함으로써 효력이 발생합니다. 회사는 「전자상거래
                등에서의 소비자보호에 관한 법률」, 「약관의 규제에 관한 법률」,
                「정보통신망이용촉진 및 정보보호 등에 관한 법률」,
                「콘텐츠산업진흥법」 등 관련 법령에 위배하지 않는 범위에서 이
                약관을 개정할 수 있습니다. 회사가 이 약관을 개정할 경우에는
                적용일자 및 개정사유를 명시하여 현행약관과 함께 서비스 내에 그
                적용일자 7일전부터 적용일자 전일까지 공지 또는 통지합니다. 다만,
                회원에게 불리하게 약관 내용을 변경하는 경우에는 최소한 30일
                이상의 사전 유예기간을 두고 공지 또는 통지합니다. 회사는 개정
                약관의 안내를 서비스 내 공지, 이메일 발송, SMS 발송 중 1가지
                이상의 방법으로 명확하게 공지합니다. 회사가 제3항에 따라
                개정약관을 공지 또는 통지하면서, 약관 변경 적용일까지 거부의사를
                표시하지 아니할 경우 약관의 변경에 동의한 것으로 간주한다는
                내용을 회원에게 명확하게 공지 또는 통지하였음에도 회원이
                명시적으로 거부의 의사표시를 하지 않은 경우 회원이 개정약관에
                동의한 것으로 간주합니다. 회원이 개정약관의 적용에 동의하지 않는
                경우 회사는 개정약관의 내용을 적용할 수 없으며, 이 경우 회원은
                이용계약을 해지할 수 있습니다. 다만, 회사가 개정약관에 부동의한
                회원에게 기존 약관을 적용할 수 없는 경우, 회사는 해당 회원과의
                이용계약을 해지할 수 있습니다. 회원은 항상 이 약관의 내용에
                변경이 있는지 여부를 확인하여야 합니다. 회사는 필요한 경우 특정
                서비스에 관하여 적용될 사항을 위해 개별약관이나 이용정책을
                정하여 운영할 수 있으며, 해당 내용은 모바일 기기를 통해 공지할
                수 있습니다. 이 약관에 규정되지 않은 사항에 대해서는 관련법령
                또는 개별약관, 이용정책에서 정한 바에 따릅니다. 이 약관에서
                정하지 아니한 사항과 이 약관의 해석에 관하여는 「전자상거래
                등에서의 소비자보호에 관한 법률」, 「약관의 규제에 관한 법률」,
                「정보통신망이용촉진 및 정보보호 등에 관한 법률」,
                「콘텐츠산업진흥법」 등 관련 법령 또는 상관례에 따릅니다.
              </p>

              <h2>제 5 조 (약관의 해석)</h2>
              <p>
                회사는 다음 각 호의 사항을 회원이 알아보기 쉽도록 서비스 등 내에
                표시합니다. 다만, 개인정보처리방침과 약관은 회원이 연결화면을
                통하여 볼 수 있도록 할 수 있습니다. 회사는 플레이스 업장을 가진
                키워드 분석, 순위 효율 분석 등을 제공합니다. 회사는 서비스를
                유료로 제공할 수 있습니다. 다만, 유료로 제공하는 경우에는 그
                내용을 서비스 내 공지, 이메일 발송, SMS 발송, 계약서 내 기재 중
                1가지 이상의 방법으로 명확하게 공지합니다. 회사가 서비스를
                유료(이하 “유료 서비스”)로 제공하는 경우, 회사는 유료 서비스의
                이용료를 신용카드 등 기타 회사가 정하는 결제 수단을 통해 납부
                받습니다. 납부방법은 건마다 자동으로 결제되는 정기 결제가
                있습니다. 회사는 서비스를 일정 범위로 나누어 범위별로 이용
                가능하고 시간을 별도로 지정할 수 있습니다. 다만, 이러한 경우에는
                그 내용을 사전에 공지합니다. 회사는 원활한 서비스를 위하여
                회원에게 본인인증과 실명확인을 요구할 수 있습니다. 회사는 컴퓨터
                등 정보통신설비의 보수점검, 교체 및 고장, 통신두절 또는 운영상
                타당한 이유가 있는 경우 서비스의 제공을 일시적으로 중단할 수
                있습니다. 이 경우 회사는 회원에게 통지해야 하지만 회사가 사전에
                통지할 수 없는 부득이한 사유가 있는 경우 사후에 통지할 수
                있습니다. 회사는 서비스의 제공에 필요한 경우 정기점검을 할 수
                있으며, 정기점검 시간은 서비스 제공화면에 공지한 바에 따릅니다.
                회사는 본 조 제7항 및 제8항의 사유로 서비스의 제공이 일시적으로
                중단되는 경우 이용자 또는 제3자가 입은 손해에 대하여 배상합니다.
                단, 회사의 고의 또는 과실에 의하지 않은 경우에는 그러하지
                않습니다. 약관을 적용하기 위하여 필요한 사항과 약관에서 구체적
                범위를 정하여 위임한 사항을 서비스 운영정책(이하 “운영정책”이라
                합니다)으로 정할 수 있습니다. 회사는 운영정책의 내용을 회원이 알
                수 있도록 서비스 내 또는 그 연결화면에 게시합니다. 운영정책을
                개정하는 경우에는 제4조 제3항의 절차에 따릅니다. 다만, 운영정책
                개정 내용이 다음 각 호의 어느 하나에 해당하는 경우에는 본 조
                제11항의 방법으로 사전에 공지합니다. ① 이 약관에서 구체적으로
                범위를 정하여 위임한 사항을 개정하는 경우 ② 회원의 권리·의무와
                관련 없는 사항을 개정하는 경우 ③ 운영정책의 내용이 약관에서 정한
                내용과 근본적으로 다르지 않고 회원이 예측할 수 있는 범위 내에서
                운영정책을 개정하는 경우
              </p>

              <h2>제 6 조 (이용계약의 체결 및 적용)</h2>
              <p>
                이용계약은 회원이 되고자 하는 자(이하 “가입신청자”라 합니다)가
                이 약관의 내용에 대하여 동의를 한 다음 서비스 이용 신청을 하고,
                회사가 그 신청에 대해서 승낙함으로써 체결됩니다. 이용자가 만
                14세미만인 경우에는 법정대리인의 동의를 받아 이용 신청을 할 수
                있습니다. 전항의 경우는 동의서를 첨부하여 이용 신청을 하여야
                합니다. 회사는 가입신청자의 신청에 대하여 승낙함을 원칙으로
                합니다. 다만, 회사는 다음 각 호의 어느 하나에 해당하는 이용
                신청에 대해서는 승낙을 거절할 수 있습니다. ① 이용신청서 내용을
                허위로 기재하거나 필수 제공사항인 정보를 누락하거나 오기하여
                신청하는 경우 또는 이용신청 요건을 충족하지 못한 경우 ② 회사가
                서비스를 제공하지 않은 국가에서 비정상적이거나 우회적인 방법을
                통해 서비스를 이용하는 경우 ③ 「콘텐츠산업진흥법」등 관련
                법령에서 금지하는 행위를 할 목적으로 신청하는 경우 ④ 사회의
                안녕과 질서 또는 미풍양속을 저해할 목적으로 신청한 경우 ⑥ 부정한
                용도로 서비스를 이용하고자 하는 경우 ⑦ 영리를 추구할 목적으로
                서비스를 이용하고자 하는 경우 ⑧ 가입신청자가 회원탈퇴 또는
                신청자의 귀책사유로 인한 회사의 계약 해지로 회원 자격을 상실한
                사실이 있는 경우 ⑨ 회사로부터 서비스 이용 정지 조치 등을 받은
                자가 그 조치기간 중에 이용계약을 임의 해지하고 재이용신청을 하는
                경우 ⑩ 그 밖에 각 호에 준하는 사유로서 승낙이 부적절하다고
                판단되는 경우 회사는 다음 각 호의 어느 하나에 해당하는 경우 그
                사유가 해소될 때까지 승낙을 유보할 수 있습니다. ① 회사의 설비에
                여유가 없거나, 특정 모바일 기기의 지원이 어렵거나, 기술적 장애가
                있는 경우 ② 서비스 상의 장애 또는 서비스 이용요금, 결제수단의
                장애가 발생한 경우 ③ 그 밖의 각 호에 준하는 사유로서 이용신청의
                승낙이 어렵다고 판단되는 경우 회원은 서비스 내에서 언제든지
                본인의 개인정보를 열람하고 수정할 수 있습니다. 다만, 서비스
                관리를 위해 필요한 계정(이메일) 등은 수정할 수 없습니다. 회원은
                회원가입 시 기재한 사항이 변경되었을 경우 온라인, 이메일, FAX
                등으로 그 변경사항을 회사에 알려야 합니다. 변경사항을 회사에
                알리지 않아 발생한 불이익에 대하여 회사는 책임지지 않습니다.
              </p>

              <h2>제 7 조 (회원의 아이디 관리에 대한 의무)</h2>
              <p>
                회원의 아이디에 관한 관리책임은 회원에게 있으며, 이를 제3자가
                이용하도록 하여서는 안 됩니다. 회사는 회원의 아이디가 개인정보
                유출 우려가 있거나, 반사회적 또는 미풍양속에 어긋나거나 회사 및
                회사의 운영자로 오인할 우려가 있는 경우, 해당 아이디의 이용을
                제한할 수 있습니다. 회원은 아이디가 도용되거나 제3자가 사용하고
                있음을 인지한 경우에는 이를 즉시 회사에 통지하고 회사의 안내에
                따라야 합니다. 만약 회사에 그 사실을 통지하지 않거나, 통지한
                경우에도 회사의 안내에 따르지 않아 발생한 불이익에 대하여 회사는
                책임지지 않습니다.
              </p>

              <h2>제 8 조 (회사의 의무)</h2>
              <p>
                회사는 관련법과 이 약관이 금지하거나 미풍양속에 반하는 행위를
                하지 않으며, 계속적이고 안정적으로 서비스를 제공하기 위하여
                최선을 다하여 노력합니다. 회사는 회원이 안전하게 서비스를 이용할
                수 있도록 개인정보(신용정보 포함)보호를 위해 보안시스템을
                갖추어야 하며 개인정보 처리방침을 공시하고 준수합니다. 다만,
                회사가 제공하는 서비스 이외의 링크된 서비스에서는 회사의
                개인정보처리방침이 적용되지 않습니다. 회사는 서비스 이용과
                관련하여 회원으로부터 제기된 의견이나 불만이 정당하다고 인정할
                경우에는 이를 처리하여야 합니다. 회원이 제기한 의견이나 불만
                사항에 대해서는 게시판을 활용하거나 이메일 등을 통하여 회원에게
                처리 과정 및 결과를 전달합니다.
              </p>

              <h2>제 9 조 (회원의 의무)</h2>
              <p>
                회원은 아래 각 호의 행위를 하여서는 안 됩니다. 회사는 회원이
                아래 각 호에 해당되는 행위를 할 경우에는 이 약관 및 각
                서비스별로 공지하는 운영정책에 따라 회원의 서비스 이용을
                제한하거나 계정의 삭제, 수사기관의 고발 조치 등 적법한 조치를
                포함한 제재를 가할 수 있습니다. ① 신청 또는 변경 시 허위내용의
                등록 ② 타인의 정보도용 또는 타인의 결제 수단은 도용, 부정하게
                사용하는 행위 ③ 회사가 게시한 정보의 변경 ④ 다른 회원의 개인정보
                및 계정정보를 수집하는 행위 또는 해킹을 통해서 다른 사용자의
                정보를 취득하는 행위 ⑤ 회사의 사전 동의 없이 영리 목적의 광고성
                정보를 전송하기 위하여 이용하는 행위 ⑥ 리버스 엔지니어링,
                디컴파일, 디스어셈블 및 기타 일체의 가공행위를 통하여 서비스를
                복제, 분해 또는 모방 기타 변형하는 행위 ⑦ 자동 접속 프로그램
                등을 사용하는 등 정상적인 용법과 다른 방법으로 서비스를 이용하여
                회사의 서버에 부하를 일으켜 회사의 정상적인 서비스를 방해하는
                행위 ⑧ 본인 아닌 제3자에게 접속 권한을 부여하는 행위 ⑨ 회사와
                기타 제3자의 저작권 등 지식재산권에 대한 침해 ⑩ 회사 및 기타
                제3자의 명예를 손상하거나 업무를 방해하는 행위 ⑪ 외설 또는
                폭력적인 메시지, 화상, 음성, 기타 공서양속에 반하는 정보를
                서비스에 공개 또는 게시하는 행위 ⑫ 회사의 동의 없이 영리를
                목적으로 서비스를 사용하는 행위 ⑬ 회원 탈퇴, 재가입 등을
                반복적으로 행하거나, 회사가 정하지 않은 비정상적인 방식으로
                쿠폰, 이벤트 혜택 등의 경제상의 이익을 수취하는 행위 ⑭ 회사의
                운영자, 임직원, 회사를 사칭하는 경우 ⑮ 기타 불법적이거나 부당한
                행위 회원은 관계법, 이 약관의 규정, 이용안내 및 서비스와
                관련하여 공지한 주의사항, 회사가 통지하는 사항 등을 준수하여야
                하며, 기타 회사의 업무에 방해되는 행위를 하여서는 안 됩니다.
                회원은 부정한 결제가 이루어지지 아니하도록 결제 비밀번호 기능을
                설정하고 관리하여야 합니다. 회원의 부주의로 인하여 발생하는
                손해에 대해 회사는 책임지지 않습니다. 회사는 회원이 이 약관을
                준수하지 않아 발생하는 손해에 대하여 책임을 부담하지 않습니다.
              </p>

              <h2>제 10 조 (서비스의 변경)</h2>
              <p>
                회사는 이용 감소로 인한 원활한 서비스 제공의 곤란 및 수익성
                악화, 기술 진보에 따른 차세대 서비스로의 전환 필요성, 서비스
                제공과 관련한 회사 정책의 변경 등 회사의 제반 사정 또는 법률상의
                장애, 기타 타당한 이유가 있는 경우에 운영상, 기술상의 필요에
                따라 제공하고 있는 전부 또는 일부 서비스를 변경 또는 중단,
                종료할 수 있습니다. 회사는 무료로 제공되는 서비스의 일부 또는
                전부를 회사의 정책 및 운영의 필요로 유료화, 수정, 중단, 변경할
                수 있으며, 이에 대하여 관련법에 특별한 규정이 없는 한 회원에게
                별도의 보상을 하지 않습니다. 서비스의 내용, 이용방법, 이용시간에
                대하여 변경 또는 서비스 중단이 있는 경우에는 변경 또는 중단될
                서비스의 내용 및 사유와 일자 등은 그 변경 또는 중단 전에 회사
                웹사이트 또는 서비스 내 공지사항 화면, 서비스 메시지 등 회원이
                충분히 인지할 방법으로 30일의 기간을 두고 사전에 공지합니다.
                다만, 회사가 통제할 수 없는 사유로 인한 서비스의 중단(운영자의
                고의 과실이 없는 디스크 장애, 시스템 다운 등)으로 인하여 사전
                통지가 불가능한 경우 사후에 통지합니다. 제3항의 경우 회사는
                사용하지 않았거나 사용기간이 남아 있는 유료 서비스에 대하여 이
                약관 및 관련 법령이 정하는 바에 따라 환불합니다.
              </p>

              <h2>제 11 조 (정보의 제공 및 광고의 게재)</h2>
              <p>
                회사는 회원이 서비스 이용 중 필요하다고 인정되는 다양한 정보를
                서비스 내 공지사항, 서비스 화면, 서비스 메시지, 이메일 등의
                방법으로 회원에게 제공할 수 있습니다. 다만, 회원은 관련법에 따른
                거래 관련 정보 및 고객 문의 등에 대한 답변 등을 제외하고는
                언제든지 위 정보제공에 대해서 수신 거절을 할 수 있습니다. 회사는
                서비스의 운영과 관련하여 서비스 화면, 서비스 메시지, 홈페이지
                등에 광고를 게재할 수 있습니다. 회사가 제공하는 서비스 중의 배너
                또는 링크 등을 통해 타인이 제공하는 광고나 서비스에 연결될 수
                있습니다. 위 제3항에 따라 타인이 제공하는 광고나 서비스에 연결될
                경우 해당 영역에서 제공하는 서비스는 회사의 서비스 영역이
                아니므로 회사가 신뢰성, 안정성 등을 보장하지 않으며, 그로 인한
                회원의 손해에 대하여도 회사는 책임을 지지 않습니다. 다만, 회사가
                고의 또는 중과실로 손해의 발생을 용이하게 하거나 손해 방지를
                위한 조치를 취하지 아니한 경우에는 그러하지 아니합니다.
              </p>

              <h2>제 12 조 (등급의 관리)</h2>
              <p>
                회사는 회원의 등급을 구분하고 이용시간, 이용횟수, 제공 서비스의
                범위 등을 세분화하여 이용에 차등을 둘 수 있습니다. 등급은 회원이
                원할 때 회사의 허가를 받아 변경할 수 있습니다. 다만 유료 등급일
                경우 별도의 결제 등의 과정이 있을 수 있습니다. 회사와 유료에
                등급 관한 계약을 체결한 회원은 구매계약일로부터 7일 이내에
                별도의 수수료‧위약금 등의 부담 없이 청약철회를 할 수 있습니다.
                단, 회원이 유료 등급에 따라 이용가능한 서비스를 이용한 경우에는
                환불이 불가합니다. 유료 등급은 회원의 서비스 이용계약이 해지될
                경우 자동으로 해지됩니다. 제2항 및 제3항의 규정에 따라
                청약철회가 이루어질 경우 회사는 지체 없이 회원이 유료 등급이
                되기 위해 결제한 방법과 동일한 방법으로 대금을 환불하여야 하며,
                동일한 방법으로 환불이 불가한 경우 이를 사전에 회원에게
                고지합니다. 이 경우 최종 환불은 회원이 유료 등급이 되기 위하여
                결제한 결제 수단에 따라 일정이 달라질 수 있습니다.
              </p>

              <h2>제 13 조 (청약 철회 및 환불 등)</h2>
              <p>
                회사와 유료 서비스에 관한 건별 계약을 체결한 회원은
                구매계약일로부터 7일 이내에 별도의 수수료‧위약금 등의 부담 없이
                청약철회를 할 수 있습니다. 단, 회원이 해당 유료 서비스를 이용한
                경우에는 환불이 불가합니다. 회사와 유료 서비스에 관한 정기 결제
                계약을 체결한 회원은 최초 결제일로부터 7일 이내에 별도의
                수수료‧위약금 등의 부담 없이 청약철회를 할 수 있습니다. 단,
                회원이 해당 유료 서비스를 이용한 경우에는 환불이 불가합니다.
                회사와 유료 서비스에 관한 정기 결제 계약을 체결한 회원은, 최초
                결제일로부터 7일이 지난 후라고 하더라도, 향후 정기 결제를 취소할
                수 있습니다. 이 경우 익월부터 정기 결제가 취소되며 이미 요금을
                지불한 기간 동안은 유료 서비스를 이용할 수 있습니다. 회원은
                제2항 내지 제3항에도 불구하고 구매한 유료 서비스의 내용이
                표시․광고의 내용과 다르거나 구매계약의 내용과 다르게 이행된
                경우에 해당 서비스가 이용 가능하게 된 날부터 3개월 이내, 그
                사실을 안 날 또는 알 수 있었던 날부터 30일 이내에 청약철회를 할
                수 있습니다. 회원이 청약철회를 할 경우 회사는 구매내역을
                확인합니다. 또한 회사는 회원의 정당한 철회 사유를 확인하기 위해
                회원에게서 제공받은 정보를 통해 회원에게 연락할 수 있으며,
                추가적인 증빙을 요구할 수 있습니다. 본조의 규정에 따라
                청약철회가 이루어질 경우 회사는 지체 없이 회원의 유료 서비스를
                회수하고 회원이 유료 서비스의 구매대금을 결제한 방법과 동일한
                방법으로 대금을 환불하여야 하며, 동일한 방법으로 환불이 불가한
                경우 이를 사전에 회원에게 고지합니다. 이 경우 최종 환불은 회원이
                유료 서비스의 구매 대금을 결제한 결제 수단에 따라 일정이 달라질
                수 있습니다. 미성년자가 유료 서비스 구매계약을 체결하는 경우,
                회사는 법정대리인의 동의가 없으면 미성년자 본인 또는
                법정대리인이 그 계약을 취소할 수 있다는 내용을 고지하며,
                미성년자가 법정대리인의 동의 없이 구매계약을 체결한 때에는
                미성년자 본인 또는 법정대리인은 회사에 그 계약을 취소할 수
                있습니다. 다만, 미성년자가 법정대리인이 범위를 정하여 처분을
                허락한 재산으로 서비스를 구매한 경우 또는 미성년자가 속임수로써
                자기를 성년자로 믿게 하거나 법정대리인의 동의가 있는 것으로 믿게
                한 경우에는 취소할 수 없습니다. 서비스 구매계약의 당사자가
                미성년자인지 여부는 결제가 진행된 모바일 기기, 결제 실행자 정보,
                결제 수단 명의자 등을 근거로 판단합니다. 또한 회사는 정당한
                취소인지를 확인하기 위해 미성년자 및 법정대리인임을 증명할 수
                있는 서류의 제출을 요청할 수 있습니다. 이벤트, 프로모션 등을
                통해 할인된 금액으로 서비스를 구매한 경우에는 환불은 불가할 수
                있습니다. 이벤트, 프로모션 등을 통해 무상으로 제공받은 경품,
                포인트(적립금, 캐쉬 등 명칭은 불문함), 교환권, 서비스 등의
                유효기간 연장 및 환불은 불가할 수 있습니다. 회사는 과오납금이
                발생하는 경우 과오납금을 회원에게 환급합니다. 다만, 과오납금이
                회사의 고의 또는 과실 없이 회원의 과실로 인하여 발생한 경우에는
                그 환급에 소요되는 실제 비용은 합리적인 범위 내에서 회원이
                부담합니다. 회사는 과오납금의 환급을 처리하기 위해 회원에게서
                제공받은 정보를 통해 회원에게 연락할 수 있으며, 필요한 정보의
                제공을 요청할 수 있습니다. 회사는 회원으로부터 환급에 필요한
                정보를 받은 날부터 3영업일 이내에 환급합니다.
              </p>
              <h2>제 14 조 (콘텐츠의 저작권 및 관리 등)</h2>
              <p>
                회원이 서비스 내에 문장, 이미지, 동영상 등을 게시한 콘텐츠의
                저작권은 저작권법에 의해 보호를 받으며, 회사가 작성한 저작물에
                대한 저작권과 기타 지식재산권은 회사에 귀속합니다. 회원은 자신이
                서비스 내에 게시한 콘텐츠를 회사가 국내•외에서 다음의 목적으로
                사용하는 것을 허락합니다. ① 콘텐츠의 크기를 변환하거나
                단순화하는 등의 방식으로 수정하는 것 ② 회사에서 운영하는 다른
                사이트에 콘텐츠를 복제, 전송, 전시하는 것(다만, 회원이 게시물의
                복제, 전송, 전시에 동의하지 아니할 경우에는 그러하지 않습니다.)
                ③ 회사의 서비스를 홍보하기 위한 목적으로 미디어, 통신사 등에게
                콘텐츠의 내용을 보도, 방영하게 하는 것(단, 이 경우 회사는 회원의
                개별 동의 없이 미디어, 통신사 등에게 게시물 또는 회원 정보를
                제공하지 않습니다.) 전항의 규정에도 불구하고, 회사가 콘텐츠를
                해당 목적 이외에 상업적 목적으로 사용할 경우에는 사전에 전화,
                메일 등의 방법으로 회원의 동의를 얻습니다. 이 경우 회사는
                회원에게 별도의 보상을 제공합니다. 회원이 서비스에 콘텐츠를
                게시하는 것은 다른 회원이 콘텐츠를 서비스 내에서 사용하거나
                회사가 검색결과로 사용하는 것을 허락한 것으로 봅니다. 회원이
                이용계약을 해지하거나 해지되는 경우에도 회원이 서비스에 게시한
                콘텐츠는 존속됩니다. 다만, 회원이 게시중단 및 삭제 등을 요청한
                경우 회사는 관련법에 따라 조처를 하여야 합니다. 회사는 서비스
                운영 정책상 또는 회사가 운영하는 사이트 간의 통합 등을 하는 경우
                콘텐츠의 내용을 변경하지 아니하고 콘텐츠의 게재 위치를 변경•이전
                또는 공유로 서비스할 수 있으며, 게시물의 변경•이전 또는 공유를
                하는 경우에는 사전에 공지합니다. 회원의 콘텐츠가 "정보통신망법"
                및 "저작권법" 등 관련법에 위반되는 내용을 포함하는 경우,
                권리자는 관련법이 정한 절차에 따라 해당 게시물의 게시중단 및
                삭제 등을 요청할 수 있으며, 회사는 관련법에 따라 조처를 하여야
                합니다. 회사는 전항에 따른 권리자의 요청이 없는 경우라도
                권리침해가 인정될 만한 사유가 있거나 기타 회사 정책 및 관련법에
                위반되는 경우에는 관련법에 따라 해당 콘텐츠에 대해 임시조치 등을
                취할 수 있습니다. 회사는 회사를 위한 마케팅자료(서비스소개서,
                홈페이지 등)에 서비스를 이용한 회원을 고객명단에 포함하여
                서비스를 판매하거나 선전할 목적으로 회원을 위해 수행한 일을
                공개하고자 하는 경우에는 회원의 명칭(법인명, 사업자명, 쇼핑몰명
                등을 의미하며 이에 한하지 아니함), 회원을 표시하는 로고, 회원을
                표시하는 로고명, 이용 서비스
              </p>

              <h2>제 15 조 (권리의 귀속)</h2>
              <p>
                서비스에 대한 저작권 및 지식재산권은 회사에 귀속됩니다. 단,
                회원의 콘텐츠 및 제휴계약에 따라 제공된 저작물 등은 제외합니다.
                회사가 제공하는 서비스의 디자인, 회사가 만든 텍스트, 그래픽 등
                회사가 제공하는 서비스에 관련된 지식재산권은 대한민국 및 외국의
                법령을 근거로 하여 회사가 보유하고 있거나 회사에 소유권 또는
                사용권이 있습니다. 회원은 이 약관으로 인하여 서비스를 소유하거나
                서비스에 관한 저작권을 보유하게 되는 것이 아니라, 회사로부터
                서비스의 이용을 허락받게 되는 바, 서비스는 정보 취득 또는
                개인용도로만 제공되는 형태로 회원이 이용할 수 있습니다. 회원은
                명시적으로 허락된 내용을 제외하고는 서비스를 통해 얻어지는 회원
                상태정보를 영리 목적으로 사용, 복사, 유통하는 것을 포함하여
                회사가 만든 텍스트, 그래픽 등의 회원 상호 간 전송기능 등을
                복사하거나 유통할 수 없습니다. 회사는 서비스와 관련하여 회원에게
                회사가 정한 이용조건에 따라 아이디, 콘텐츠 등을 이용할 수 있는
                이용권만을 부여하며, 회원은 이를 양도, 판매, 담보제공 등의
                처분행위를 할 수 없습니다.
              </p>

              <h2>제 16 조 (데이터의 활용)</h2>
              <p>
                회사는 데이터를 다음과 같이 이용할 수 있습니다. ① 데이터의 분석
                및 통계 작성 ② 데이터의 복제, 수정, 각색, 공연, 전시, 방송,
                배포, 대여, 공중송신, 2차적 저작물 작성, 기타 이용 ③ 데이터를
                기술 개발 기타 서비스 개선을 위한 목적으로 학술, 연구기관
                업체에게 제공 ④ 부가 서비스의 개발 및 제공, 보고서 작성 및 판매
                위 제1항에 규정에 따라 회사가 회원의 데이터를 이용할 수 있는
                조건은 아래와 같습니다. ① 데이터 이용 매체/플랫폼 - 현재 알려져
                있거나 앞으로 개발된 회사가 운영하는 모든 매체, 장비, 기술을
                포함함. ② 데이터 이용 용도 - 상업적 또는 비상업적 이용을
                구분하지 않음. ③ 데이터 이용 범위 - 국내 및 국외에서의 복제,
                수정, 각색, 공연, 전시, 방송, 배포, 대여, 공중송신, 2차적 저작물
                작성, 기타 이용 ④ 데이터 이용 조건 - 비독점적, 지속적인 무상의
                권리 전항의 규정에도 불구하고, 회사는 데이터에 회원의 개인정보나
                신상정보가 포함될 경우에는 사전에 전화, 메일 등의 방법으로
                회원의 동의를 얻습니다. 본 조는 회사가 서비스를 운영하는 동안
                유효하며, 회원 탈퇴 후에도 지속적으로 적용됩니다.
              </p>

              <h2>제 17 조 (계약해제, 해지 등)</h2>
              <p>
                회원은 언제든지 서비스 내 "탈퇴하기" 화면을 통하여 이용계약 해지
                신청을 할 수 있으며, 회사는 관련법 등이 정하는 바에 따라 이를
                즉시 처리하여야 합니다. 회원이 계약을 해지할 경우, 관련법 및
                개인정보 처리방침에 따라 회사가 회원 정보를 보유하는 경우를
                제외하고는 해지 즉시 회원의 모든 데이터는 소멸합니다. 회사는
                회원이 이 약관 및 그에 따른 운영정책, 서비스 정책에서 금지하는
                행위를 하는 등 본 계약을 유지할 수 없는 중대한 사유가 있는
                경우에는 상당한 기간 전에 최고하고 기간을 정하여 서비스 이용을
                중지하거나 이용계약을 해지할 수 있습니다. 회사는 최근의 서비스
                이용일부터 연속하여 1년 동안 회사의 서비스를 이용하지 않은
                회원(이하 “휴면계정”이라 합니다)의 개인정보를 보호하기 위해
                이용계약을 해지하고 회원의 개인정보 파기 등의 조치를 취할 수
                있습니다. 이 경우 조치일 30일 전까지 계약해지, 개인정보 파기
                등의 조치가 취해진다는 사실 및 파기될 개인정보 등을 회원에게
                통지합니다.
              </p>

              <h2>제 18 조 (손해배상)</h2>
              <p>
                회사 또는 회원은 이 약관을 위반하여 상대방에게 손해를 입힌
                경우에는 그 손해를 배상할 책임이 있습니다. 다만, 고의 또는
                과실이 없는 경우에는 그러하지 아니 합니다 회사가 개별서비스
                제공자와 제휴 계약을 맺고 회원에게 개별서비스를 제공하는 경우에
                회원이 이 개별서비스 이용약관에 동의를 한 뒤 개별서비스 제공자의
                고의 또는 과실로 인해 회원에게 손해가 발생한 경우에 그 손해에
                대해서는 개별서비스 제공자가 책임을 집니다.
              </p>

              <h2>제 19 조 (이용제한 등)</h2>
              <p>
                회사는 회원이 이 약관의 의무를 위반하거나 서비스의 정상적인
                운영을 방해한 경우, 경고, 일시 정지, 영구이용정지 등으로 서비스
                이용을 단계적으로 제한할 수 있습니다. 회사는 전항에도 불구하고,
                회원 정보의 허위내용 기재, 전화번호 도용, 저작권법 및
                컴퓨터프로그램 보호법을 위반한 불법프로그램의 제공 및 운영방해,
                정보통신망법을 위반한 불법 통신 및 해킹, 악성 프로그램의 배포,
                접속 권한 초과행위 등과 같이 관련법을 위반한 경우에는 즉시
                영구이용정지를 할 수 있습니다. 본 항에 따른 영구 이용정지 시
                서비스 이용을 통해 획득한 혜택 등도 모두 소멸하며, 회사는 이에
                대해 별도로 보상하지 않습니다. 회사는 회원이 계속해서 3개월 이상
                로그인하지 않는 경우, 회원 정보의 보호 및 운영의 효율성을 위해
                이용을 제한할 수 있습니다. 본 조의 이용제한 범위 내에서 제한의
                조건 및 세부내용은 회사의 이용제한정책에서 정하는 바에 의합니다.
                본 조에 따라 서비스 이용을 제한하거나 계약을 해지하는 경우에는
                회사는 서비스 메시지, 이메일 등의 방법으로 통지합니다. 회원은 본
                조에 따른 이용제한 등에 대해 회사가 정한 절차에 따라 이의신청을
                할 수 있습니다. 이때 이의가 정당하다고 회사가 인정하는 경우
                회사는 즉시 회원이 서비스를 이용할 수 있도록 합니다.
              </p>
              <h2>제 20 조 (책임제한)</h2>
              <p>
                회사는 천재지변 또는 이에 따르는 불가항력으로 인하여 서비스를
                제공할 수 없는 경우에는 서비스 제공에 관한 책임이 면제됩니다.
                회사는 회원의 귀책사유로 인한 서비스 이용의 장애에 대하여는
                책임을 지지 않습니다. 회사는 회원이 서비스와 관련하여 게재한
                정보, 자료, 사실의 신뢰도, 정확성 등의 내용에 관하여는 책임을
                지지 않습니다. 회사는 회원 간 또는 회원과 제3자 상호 간에
                서비스를 매개로 하여 거래 등을 한 경우에는 해당 거래에 대한
                책임이 면제됩니다. 회사는 무료로 제공되는 서비스 이용과 관련하여
                관련법에 특별한 규정이 없는 한 책임을 지지 않습니다. 회사는
                제3자가 서비스 내 화면 또는 링크된 웹사이트를 통하여 광고한 제품
                또는 서비스의 내용과 품질에 대하여 감시할 의무 기타 어떠한
                책임도 지지 아니합니다. 회사는 회원이 서비스를 이용하여 기대하는
                효용을 얻지 못한 것에 대하여 책임을 지지 않으며 서비스에 대한
                선택 또는 이용으로 발생하는 손해 등에 대해서는 책임이
                면제됩니다. 회사는 회원의 등급, 적립금 등의 손실에 대하여 책임을
                지지 않습니다. 다만, 회사의 고의 또는 과실에 의한 경우에는
                그러하지 아니합니다. 회사는 회원이 모바일 기기 비밀번호 등을
                관리하지 않아 발생하는 제3자 결제 및 회원이 계좌번호를
                오기재하여 발생하는 피해에 대해 책임을 지지 않습니다. 다만,
                회사의 고의 또는 과실에 의한 경우에는 그러하지 아니합니다. 회사
                및 회사의 임직원 그리고 대리인은 다음의 상황에서 발생하는 손해에
                대한 책임을 지지 아니합니다. ① 회원 상태정보의 허위 또는
                부정확성에 기인하는 손해 ② 그 성질과 경위를 불문하고 서비스에
                대한 접속 및 서비스의 이용과정에서 발생하는 개인적인 손해 ③
                서버에 대한 제3자의 모든 불법적인 접속 또는 서버의 불법적인
                이용으로부터 발생하는 손해 ④ 서버에 대한 전송 또는 서버로부터의
                전송에 대한 제3자의 모든 불법적인 방해 또는 중단행위로부터
                발생하는 손해 ⑤ 제3자가 서비스를 이용하여 불법적으로 전송,
                유포하거나 전송, 유포되도록 한 모든 바이러스, 스파이웨어 및 기타
                악성 프로그램으로 인한 손해 ⑥ 전송된 데이터의 오류 및 생략,
                누락, 파괴 등으로 발생하는 손해 ⑦ 회원 간의 회원 상태정보 등록
                및 서비스 이용 과정에서 발생하는 명예훼손 기타 불법행위로 인한
                각종 민형사상 책임
              </p>

              <h2>제 21 조 (준거법 및 재판관할)</h2>
              <p>
                회사와 회원 간 제기된 소송은 대한민국 법을 준거법으로 합니다.
                회사와 회원 간 발생한 분쟁에 관한 소송은 민사소송법상의 법원을
                관할법원으로 합니다.
              </p>

              <h2>제 22 조 (회원의 고충처리 및 분쟁해결)</h2>
              <p>
                회사는 회원의 편의를 고려하여 회원의 의견이나 불만을 제시하는
                방법을 서비스 내 관련 번호로 안내합니다. 회사는 이러한 회원의
                의견이나 불만을 처리하기 위한 전담인력을 운영합니다. 회사는
                회원으로부터 제기되는 의견이나 불만이 정당하다고 객관적으로
                인정될 경우에는 합리적인 기간 내에 이를 신속하게 처리합니다.
                다만, 처리에 장기간이 소요되는 경우에는 회원에게 장기간이
                소요되는 사유와 처리일정을 서비스 내 공지, 이메일 발송, SMS
                발송을 통해 통지합니다.
              </p>

              <h2>부 칙</h2>
              <p>이 약관은 2024년 10월 27일부터 적용됩니다.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default policyOfService;
