import FooterStyle from "@components/Footer/FooterStyle.module.scss";
import { ReactComponent as Logo } from "@assets/logo/text.svg";
import { useAppNavigate } from "@hooks/navigate/navigate";
function Footer() {
  const { goToPrivacyHandlePolicy, goToTermsOfService } = useAppNavigate();
  return (
    <div id="footer" className={FooterStyle.footerBox}>
      <div className={FooterStyle.top}>
        <Logo className={FooterStyle.logo} />
      </div>
      <div className={FooterStyle.middle}>
        <p>주식회사 엠플리파이 대표 : 전유상 </p>
        <p>주소 : 서울시 강남구 강남대로 122길 61-4, 2층</p>
        <p>우편번호 : 06115, 엠플리파이</p>
        <p>전화번호 : 02-515-1867</p>
        <p>사업자등록번호 : 468-87-03126</p>
        <p>통신 판매업 신고번호 : 제 2024-서울강남-06516호</p>
      </div>

      <div className={FooterStyle.footerBottom}>
        <ul className={FooterStyle.footerBottomList}>
          <li onClick={() => goToTermsOfService(false)}>이용약관</li>
          <li onClick={() => goToPrivacyHandlePolicy(false)}>
            개인정보처리방침
          </li>
        </ul>
      </div>
    </div>
  );
}

export default Footer;
