import styles from "@components/Home/HomeStyle.module.scss";
import purpleImage from "@images/setting_purple.png";
import blueImage from "@images/setting_blue.png";
import orangeImage from "@images/setting_orange.png";

const ThirdSlideItem = () => {
  return (
    <div className={styles.thirdSlideBox}>
      <section className={styles.thirdSlideTextarea}>
        <span className={styles.thirdSlideTitle}>
          모든 광고 성과를 한눈에 확인하고
          <br />
          매출 향상에 필요한 인사이트를 제공합니다.
        </span>
        <span className={styles.thirdSlideDescription}>
          플레이스 특화형 인사이트와 효율적 순위 관리 기능을 제공하여 사장님들의
          성장을 도와드립니다.
        </span>
      </section>

      <section className={styles.thirdSlideImage}>
        <img
          className={styles.purpleImage}
          src={purpleImage}
          alt="purpleImage"
        />
        <img className={styles.blueImage} src={blueImage} alt="blueImage" />
        <img
          className={styles.orangeImage}
          src={orangeImage}
          alt="orangeImage"
        />
      </section>
    </div>
  );
};

export default ThirdSlideItem;
