import MembershipStyle from "@components/Membership/MyMembershipStyle.module.scss";
import PropTypes from "prop-types";

function MembershipCard({
  membershipName,
  isSubscribed,
  membershipCost,
  placeCount,
  keywordCount,
  alarmCount,
  onEventTrigger,
}) {
  return (
    <div className={MembershipStyle.membershipCardBox}>
      <div className={MembershipStyle.membershipTitle}>
        <h1 className={MembershipStyle.membershipName}>{membershipName}</h1>
        <h2 className={MembershipStyle.subtitle}>
          순위 로그와 블로그,리뷰,저장 수를 한눈에!
        </h2>
        <span className={MembershipStyle.membershipCost}>
          {membershipCost}원
        </span>
      </div>

      <div className={MembershipStyle.membershipContent}>
        <span>실시간 순위분석</span>
        <span>등록가능한 플레이스 {placeCount}건</span>

        {membershipName !== "Premium" ? (
          <span>
            플레이스 별 등록가능한 키워드 <br />
            {keywordCount}건(총 {keywordCount * placeCount}개)
          </span>
        ) : (
          <span>
            키워드 {keywordCount * placeCount}개를 원하는
            <br />
            플레이스에 자유롭게 등록
          </span>
        )}

        {membershipName !== "Basic" ? (
          <span>
            순위로그+세부로그 분석 <br />
            (저장,리뷰,블로그 수)
          </span>
        ) : null}

        <span>순위 알림 수 {alarmCount}건</span>
      </div>

      <button
        disabled={isSubscribed}
        className={MembershipStyle.boxButton}
        onClick={() => onEventTrigger(membershipName)}
      >
        {membershipName}
        {isSubscribed ? " 구독중" : " 구독하기"}
      </button>
    </div>
  );
}

MembershipCard.propTypes = {
  membershipName: PropTypes.string.isRequired,
  isSubscribed: PropTypes.bool.isRequired,
  membershipCost: PropTypes.string.isRequired,
  placeCount: PropTypes.number.isRequired,
  keywordCount: PropTypes.number.isRequired,
  alarmCount: PropTypes.number.isRequired,
};

export default MembershipCard;
