import AuthContext from "@utils/contexts/AuthContext";
import { useContext } from "react";
import { useDispatch } from "react-redux";
import { useAppNavigate } from "@hooks/navigate/navigate";
import {
  fetchMembershipStart,
  fetchMembershipInitial,
} from "@features/membership/membershipSlice";
import { setMembershipData } from "@utils/functions/membership/membership";
import { logoutApi, addPhoneNumber } from "@utils/api/login/login";
export const useLoginHandlers = () => {
  const dispatch = useDispatch();
  const { login, logout } = useContext(AuthContext);
  const { goToFirstLoginBenefits, goToRankingSearch, goToHome } =
    useAppNavigate();

  const handleSimpleLogin = async (loginFunction, errorMessage) => {
    try {
      dispatch(fetchMembershipStart());
      const userData = await loginFunction();
      await setMembershipData(userData, dispatch);
      navigateAfterLogin(userData.isFirstLogin);
      login();
    } catch (error) {
      console.error("🚀 ~ handleSimpleLogin ~ error:", error);
      if (error.customTitleMessage) {
        alert(error.customTitleMessage + "\n" + error.customSubMessage);
      } else {
        console.error(errorMessage, error);
        alert(errorMessage);
      }
      dispatch(fetchMembershipInitial());
      logout();
    }
  };

  const navigateAfterLogin = (isFirstLogin) => {
    if (isFirstLogin) {
      goToFirstLoginBenefits(true);
    } else {
      goToRankingSearch({ isReplace: true });
    }
  };

  const handleLogout = async () => {
    try {
      dispatch(fetchMembershipStart());
      const response = await logoutApi();
      console.log("🚀 ~ handleLogout ~ response:", response);
      goToHome(true);
      dispatch(fetchMembershipInitial());
      logout();
    } catch (error) {
      console.error("로그아웃 중 오류 발생:", error);
      // 400 이상 에러일 경우, 서버 측 문제로 로그아웃을 진행하지 않음
      if (!(error.response && error.response.status >= 400)) {
        dispatch(fetchMembershipInitial());
        logout();
      }
    }
  };

  const handleAddPhoneNumber = async ({
    email,
    name,
    phoneNumber,
    socialProvider,
    socialId,
    refreshToken,
    birthDate,
  }) => {
    try {
      await addPhoneNumber({
        email,
        name,
        phoneNumber,
        socialProvider,
        socialId,
        refreshToken,
        birthDate,
      });
    } catch (error) {
      console.error("🚀 ~ handleAddPhoneNumber ~ error:", error);
      alert("추가 정보 저장 중 오류가 발생했습니다.");
      throw error;
    }
  };

  return {
    handleSimpleLogin,
    navigateAfterLogin,
    handleLogout,
    handleAddPhoneNumber,
  };
};
