export class PaymentMethodDTO {
  constructor({ cardNumber = "", cardCompanyName = "" }) {
    this.cardNumber = cardNumber;
    this.cardCompanyName = cardCompanyName;
  }

  // GET methods
  getCardNumber() {
    return this.cardNumber;
  }

  getCardCompanyName() {
    return this.cardCompanyName;
  }

  // SET methods
  setCardNumber(value) {
    this.cardNumber = value;
  }

  setCardCompanyName(value) {
    this.cardCompanyName = value;
  }
}
