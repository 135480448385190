export const API_ERROR_TYPE = {
  REFRESH_TOKEN_EXPIRED: "/errors/authentication/refresh/expired",
  REFRESH_TOKEN_INVALID: "/errors/authentication/refresh/invalid",
  REFRESH_TOKEN_HEADER_MISSING:
    "/errors/authentication/header/refreshtoken/missing",
  ACCESS_TOKEN_HEADER_MISSING:
    "/errors/authentication/header/accesstoken/missing",
  ACCESS_TOKEN_EXPIRED: "/errors/authentication/expired",
  ACCESS_TOKEN_INVALID: "/errors/authentication/invalid",
  REFRESH_TOKEN_FAILED: "/errors/authentication/refresh/failed",
  ACCESS_TOKEN_FAILED: "/errors/authentication/failed",
  SERVER_ERROR: "/errors/internal-server-error",
  CONFLICT_KAKAO_USER: "/errors/conflict/kakao",
  CONFLICT_NAVER_USER: "/errors/conflict/naver",
  EXCEED_POSSIBLE_REGISTRATION_NUMBER:
    "/errors/exceed-possible-registration-number",
};
