export const MEMBERSHIP_DATA = {
  basic: {
    membershipName: "Basic",
    membershipCost: "13,900",
    membershipCostForPay: 13900,
    placeCount: 2,
    keywordCount: 5,
    alarmCount: 5,
    isSubscribed: true,
    isOwner: false,
  },
  standard: {
    membershipName: "Standard",
    membershipCost: "25,900",
    membershipCostForPay: 25900,
    placeCount: 5,
    keywordCount: 6,
    alarmCount: 10,
    isSubscribed: true,
    isOwner: false,
  },
  premium: {
    membershipName: "Premium",
    membershipCost: "38,900",
    membershipCostForPay: 38900,
    placeCount: 10,
    keywordCount: 6,
    alarmCount: 10,
    isSubscribed: true,
    isOwner: false,
  },
  owner: {
    membershipName: "Owner",
    membershipCost: "0",
    membershipCostForPay: 0,
    placeCount: 1000000,
    keywordCount: 1000000,
    alarmCount: 1000000,
    isSubscribed: true,
    isOwner: true,
  },
};
