import { useState, useRef, useEffect } from "react";
import MembershipStyle from "@pages/MyMembership/MyMembership.module.scss";
import MembershipCardList from "@components/Membership/MembershipCardList";
import UpgradeMembershipCard from "@components/Mypage/MyMembership/UpgradeMembershipCard";
import { MEMBERSHIP_DATA } from "@utils/constants/membership/membership";
import PayGuideBox from "@components/Membership/PayGuideBox";
import PaymentFormBox from "@components/Mypage/MyMembership/PaymentFormBox";
import textAnimation from "@assets/styles/textAnimation.module.scss";
import { useSelector } from "react-redux";

function MyMembershipFromMypage() {
  const [showMembershipInfo, setShowMembershipInfo] = useState(null);
  const userSubscriptionType = useSelector(
    (state) => state.membership.subscriptionType
  );
  const handleEvent = (data) => {
    console.log("🚀 ~ handleEvent ~ data:", data);
    const lowerCaseData = data.toLowerCase();
    setShowMembershipInfo(() => lowerCaseData);
  };

  const [isPayBoxVisible, setIsPayBoxVisible] = useState(false);
  // PaymentFormBox를 보여줄지 결정하는 조건
  // 결제 박스가 보이거나, 사용자의 구독 유형이 "free"가 아닌 경우에만 보여줌
  const shouldShowPaymentFormBox =
    isPayBoxVisible ||
    (userSubscriptionType !== "free" && userSubscriptionType);
  const payGuideBoxRef = useRef(null);

  // 결제 버튼 클릭 시 스크롤 이동
  const scrollToPayGuideBox = () => {
    setIsPayBoxVisible(true);
    if (payGuideBoxRef.current) {
      payGuideBoxRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  // 결제 버튼이 보일 때 스크롤 이동
  useEffect(() => {
    if (isPayBoxVisible && payGuideBoxRef.current) {
      payGuideBoxRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [isPayBoxVisible]);

  return (
    <div className={MembershipStyle.membershipBox}>
      <div className={MembershipStyle.textItems}>
        <h1 className={MembershipStyle.title}>캐치랭크 멤버십 혜택</h1>
      </div>

      <div className={`${MembershipStyle.contentBox} ${textAnimation.fadeIn}`}>
        <MembershipCardList onEventTrigger={handleEvent} />
      </div>

      {showMembershipInfo && (
        <div
          className={`${MembershipStyle.membershipInfoBox} ${textAnimation.fadeIn}`}
        >
          <div>
            <UpgradeMembershipCard
              subscriptionType={showMembershipInfo}
              isShowCurrentMembershipInfo={true}
              handlePayButtonClick={scrollToPayGuideBox}
            />

            <span className={MembershipStyle.payText}>
              {MEMBERSHIP_DATA[showMembershipInfo].membershipCost}원으로
              구독하여 혜택을 받아보세요!
            </span>
          </div>

          <div className={MembershipStyle.payGuideBox}>
            <PayGuideBox subscriptionType={showMembershipInfo} />
          </div>

          {shouldShowPaymentFormBox && (
            <PaymentFormBox
              ref={payGuideBoxRef}
              selectMebershipTypeForPay={showMembershipInfo}
            />
          )}
        </div>
      )}
    </div>
  );
}

export default MyMembershipFromMypage;
