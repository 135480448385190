import React, { useState, useContext, useEffect } from "react";
import styles from "@components/Header/HeaderStyle.module.scss";
import { ReactComponent as PlogLogo } from "@assets/logo/text.svg";
import AuthContext from "@utils/contexts/AuthContext";
import { useAppNavigate } from "@hooks/navigate/navigate";
import { useLoginHandlers } from "@hooks/login/useLoginHandlers";
import RANKING_SEARCH_TYPE from "@utils/constants/place/rangkintSearch";
function AuthHeader() {
  // 탭 정보 배열
  const tabs = [
    { name: "실시간 순위분석", route: "/rankingSearch" },
    { name: "등록한 플레이스", route: "/registeredPlace" },
    { name: "멤버십 안내", route: "/mypage/membership" },
    { name: "마이페이지", route: "/mypage" },
  ];

  const boxItems = [
    { name: "나의 멤버십", route: "/mypage/myMembership" },
    { name: "회원정보", route: "/mypage/modifyMemberInfo" },
  ];
  const [activeTab, setActiveTab] = useState(); // 초기 활성 탭 설정

  const { isLoggedIn, login, logout } = useContext(AuthContext); // Context에서 값 추출
  const { handleLogout } = useLoginHandlers();
  const [activeMypageItem, setactiveMypageItem] = useState(); // 마이페이지 박스 표시 여부
  const {
    goToRankingSearch,
    goToRegisteredPlace,
    goToMembership,
    goToMyMembership,
    goToModifyMemberInfo,
    goToHome,
  } = useAppNavigate();

  useEffect(() => {
    const currentPath = window.location.pathname;
    console.log("🚀 ~ useEffect ~ currentPath:", currentPath);
    const allItems = [...tabs, ...boxItems];
    console.log("🚀 ~ useEffect ~ allItems:", allItems);
    const matchedTab = allItems.find((item) => currentPath === item.route);
    if (matchedTab) {
      console.log("🚀 ~ useEffect ~ matchedTab:", matchedTab);
      handleTabClick(matchedTab);
    }
  }, []); // 컴포넌트가 마운트될 때 한 번 실행

  // 탭 클릭 핸들러
  const handleTabClick = (item, isMypage = false) => {
    console.log(item);
    setActiveTab(item.route);

    switch (item.route) {
      case tabs[0].route:
        goToRankingSearch({
          isReplace: true,
          rankingSearchType: RANKING_SEARCH_TYPE.DEFAULT,
        });
        setactiveMypageItem(null);
        break;
      case tabs[1].route:
        goToRegisteredPlace();
        setactiveMypageItem(null);
        break;
      case tabs[2].route:
        goToMembership();
        setactiveMypageItem(null);
        break;
      case tabs[3].route:
        // setShowMypageBox(!showMypageBox);
        break;
      case boxItems[0].route:
        setActiveTab(tabs[3].route);
        setactiveMypageItem(item.route);
        goToMyMembership();
        break;
      case boxItems[1].route:
        setActiveTab(tabs[3].route);
        setactiveMypageItem(item.route);
        goToModifyMemberInfo();
        break;

      default:
        console.log("Unknown route:", item.route);
        break;
    }
  };

  return (
    <header className={styles.headerContainer}>
      <div className={styles.headerContentContainer}>
        <div className={styles.headerLeftContainer}>
          <PlogLogo className={styles.headerLogo} onClick={goToRankingSearch} />
        </div>
        <div className={styles.headerRightContainer}>
          <div className={styles.headerTabContainer}>
            {tabs.map((tab, index) => (
              <div
                className={`${styles.headerTabItem} ${
                  tab.route === "/mypage" ? styles.mypageTab : ""
                }`}
                key={index}
              >
                <button
                  key={tab.route}
                  onClick={() => handleTabClick(tab, tab.route === "/mypage")}
                  className={`${styles.headerTab} ${
                    activeTab === tab.route ? styles.active : ""
                  } ${tab.route === "/mypage" ? styles.mypageTab : ""}`}
                >
                  {tab.name}
                </button>

                {tab.route === "/mypage" && (
                  <div className={styles.hoverSensingBox}>
                    <div className={styles.mypageBox}>
                      {boxItems.map((item) => (
                        <a
                          key={item.route}
                          onClick={() => handleTabClick(item, true)}
                          className={`${styles.mypageBoxItem} ${
                            activeMypageItem === item.route
                              ? styles.selected
                              : ""
                          }`}
                        >
                          {item.name}
                        </a>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            ))}
          </div>

          <button
            className={styles.headerLogoutButton}
            onClick={() => handleLogout(false)}
          >
            {isLoggedIn ? "로그아웃" : "로그인"}
          </button>
        </div>
      </div>
    </header>
  );
}

export default AuthHeader;
