// src/dto/PlaceInfoDTO.js
import { BasePlaceInfo } from "@dto/place/BasePlaceInfo";

export class PlaceInfoDTO extends BasePlaceInfo {
  constructor({ registrationPlaceId = "", ...baseProps }) {
    super(baseProps);
    this.registrationPlaceId = registrationPlaceId;
  }

  getRegistrationPlaceId() {
    return this.registrationPlaceId;
  }

  setRegistrationPlaceId(value) {
    this.registrationPlaceId = value;
  }
}

// src/dto/KeywordRankDTO.js
export class KeywordRankDTO {
  constructor({ keyword = "", ranking = 0 }) {
    this.keyword = keyword;
    this.ranking = ranking;
  }

  getKeyword() {
    return this.keyword;
  }

  setKeyword(value) {
    this.keyword = value;
  }

  getRanking() {
    return this.ranking;
  }

  setRanking(value) {
    this.ranking = value;
  }
}
