import styles from "@pages/Mypage/MyMembership/MyMembership.module.scss";
import MymbershipCardList from "@components/Mypage/MyMembership/MymbershipCardList.js";
import PaymentFormBox from "@components/Mypage/MyMembership/PaymentFormBox.js";
import { useSelector } from "react-redux";
import textAnimation from "@assets/styles/textAnimation.module.scss";
const MyMebership = () => {
  const membershipData = useSelector((state) => state.membership);

  if (!membershipData.userId) {
    console.log(
      "🚀 ~ MyMebership ~ membershipData.userId:",
      membershipData.userId
    );
    return <div>Loading...</div>;
  }

  return (
    <div className={styles.myMembershipBox}>
      <h1 className={styles.title}>나의 멤버십</h1>

      <div className={`${styles.membershipCardList} ${textAnimation.fadeIn}`}>
        <MymbershipCardList />
      </div>

      <div className={`${styles.paymentFormBox} ${textAnimation.fadeIn}`}>
        <PaymentFormBox />
      </div>
    </div>
  );
};

export default MyMebership;
