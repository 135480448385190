import CommonStyle from "@components/Common/Modal/Dialogs/ModalStyle.module.scss";
import PropTypes from "prop-types";
const SingleConfirmModal = ({ open, onClose, children, title, cancelText }) => {
  if (!open) return null;

  return (
    <div className={CommonStyle.confirmModalOverlay}>
      <div className={CommonStyle.confirmModal}>
        <div className={CommonStyle.confirmModalContent}>
          {title ? (
            <h2 className={CommonStyle.confirmModalTitle}>{title}</h2>
          ) : null}
          {children ? (
            <div className={CommonStyle.modalContent}>{children}</div>
          ) : null}
          <div className={CommonStyle.confirmModalButton}>
            <button onClick={onClose}>{cancelText}</button>
          </div>
        </div>
      </div>
    </div>
  );
};

SingleConfirmModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.node,
  title: PropTypes.node,
  cancelText: PropTypes.string.isRequired,
};

export default SingleConfirmModal;
