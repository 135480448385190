import style from "@components/RankingSearch/RankingSearchStyle.module.scss";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { usePlaceHandlers } from "@hooks/place/usePlaceHandlers";
import { SearchResultPlaceDTO } from "@dto/place/SearchPlaceListDTO";
import { MonthlyCountDTO } from "@dto/place/MonthlyCountDTO";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import SingleConfirmModal from "@components/Common/Modal/Dialogs/SingleConfirmModal";
import { API_ERROR_TYPE } from "@utils/constants/api/errorType";
import noImage from "@assets/images/no_image.png";
import textAnimation from "@assets/styles/textAnimation.module.scss";

const StoreInfoCard = React.memo(
  ({ placeInfo, monthlyCount, functionName }) => {
    const functionType = functionName;
    const { keyword } = useParams();

    const [openSingleConfirmModal, setOpenSingleConfirmModal] = useState(false);
    const [singleConfirmModalTitle, setSingleConfirmModalTitle] = useState("");
    const [isButtonDisabled, setIsButtonDisabled] = useState(
      placeInfo.getIsRegistered()
    );
    const membershipData = useSelector((state) => state.membership);
    const { handleRegisterPlace } = usePlaceHandlers();

    if (!placeInfo.getId() || !placeInfo.getName()) {
      return null; // placeId와 name이 없으면 렌더링하지 않음
    }

    const setAndPostPlaceInfo = async () => {
      try {
        const combinedInfo = {
          userId: membershipData.userId,
          rank: placeInfo.getRank(),
          placeId: placeInfo.getId(),
          category: placeInfo.getCategory(),
          keywordName: keyword,
          placeName: placeInfo.getName(),
          placeImageUrl: placeInfo.getImageUrl(),
          functionType, // 추가할 단일 속성
          blogReviewCount: placeInfo.getBlogCafeReviewCount(),
          visitorReviewCount: placeInfo.getVisitorReviewCount(),
          saveCount: placeInfo.getSaveCount(),
          monthlyPcSearchCount: monthlyCount.getMonthlyPcSearchCount(),
          monthlyMobileSearchCount: monthlyCount.getMonthlyMobileSearchCount(),
          monthlyBlogCount: monthlyCount.getMonthlyBlogCount(),
        };
        console.log("🚀 ~ setAndPostPlaceInfo ~ combinedInfo:", combinedInfo);

        await handleRegisterPlace(combinedInfo);
        setIsButtonDisabled(true);
      } catch (error) {
        if (409 === error.status) {
          if (
            API_ERROR_TYPE.EXCEED_POSSIBLE_REGISTRATION_NUMBER ===
            error.response.data.type
          ) {
            setOpenSingleConfirmModal(true);
            setSingleConfirmModalTitle(
              "등록 가능한 플레이스 수를 초과했습니다."
            );
          }
        }

        console.log("🚀 ~ setAndPostPlaceInfo ~ error:", error);
      }
    };

    return (
      <div
        className={`${textAnimation.fadeIn} ${style.storeInfoCard}`}
        key={placeInfo.getId()}
      >
        {placeInfo.getImageUrl() ? (
          <img
            src={placeInfo.getImageUrl() || noImage}
            alt={placeInfo.getName() || "noImage"}
            className={style.storeInfoCardImage}
          />
        ) : (
          <img
            src={noImage}
            alt="noImage"
            className={style.storeInfoCardImage}
          />
        )}
        <div className={style.storeInfoCardContent}>
          <div className={style.storeInfoCardContentHeader}>
            <p>{placeInfo.getName() || "이름없음"}</p>
            <p className={style.category}>
              {placeInfo.getCategory() || "카테고리없음"}
            </p>
          </div>

          <div className={style.storeInfoCardContentMiddle}>
            <div className={style.storeInfoCardContentMiddleItem}>
              방문자 리뷰
              <p>{placeInfo.getVisitorReviewCount() || "-"}</p>
            </div>
            <div className={style.storeInfoCardContentMiddleItem}>
              블로그 리뷰
              <p>{placeInfo.getBlogCafeReviewCount() || "-"}</p>
            </div>
            <div className={style.storeInfoCardContentMiddleItem}>
              저장수
              <p>{placeInfo.getSaveCount() || "-"}</p>
            </div>
          </div>

          <div className={style.storeInfoCardContentFooter}>
            <p className={style.rank}>{placeInfo.getRank() || "-"}위</p>

            <p>PC : {placeInfo.getPcRank() || "-"}</p>

            <p>Mobile : {placeInfo.getMobileRank() || "-"}</p>
          </div>
        </div>
        {membershipData.subscriptionType && (
          <button
            className={style.storeInfoCardButton}
            disabled={isButtonDisabled}
            onClick={() => {
              setAndPostPlaceInfo(placeInfo, monthlyCount);
            }}
          >
            {isButtonDisabled ? "등록완료" : "등록"}
          </button>
        )}
        <SingleConfirmModal
          open={openSingleConfirmModal}
          setOpen={setOpenSingleConfirmModal}
          onClose={() => setOpenSingleConfirmModal(false)}
          cancelText="확인"
          title={singleConfirmModalTitle}
        />
      </div>
    );
  }
);

StoreInfoCard.propTypes = {
  placeInfo: PropTypes.instanceOf(SearchResultPlaceDTO).isRequired,
  monthlyCount: PropTypes.instanceOf(MonthlyCountDTO).isRequired,
  functionName: PropTypes.string.isRequired,
};

export default StoreInfoCard;
