import { useState, useEffect } from "react";
import MypageStyle from "@components/Mypage/MyMembership/MymbershipStyle.module.scss";
import { ReactComponent as CardIcon } from "@assets/icons/card_icon.svg";
import PropTypes from "prop-types";
import { MEMBERSHIP_DATA } from "@utils/constants/membership/membership";
import PaymentModal from "@components/Common/Modal/NicePay/PaymentModal";
const PayButtons = ({ membershipDataKeyForPay = null }) => {
  useEffect(() => {}, []);
  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);
  const amt = MEMBERSHIP_DATA[membershipDataKeyForPay].membershipCostForPay;
  const goodsName = membershipDataKeyForPay;

  // SignData 생성

  const handleCardButtonClick = () => {
    setIsPaymentModalOpen(true);
  };

  return (
    <div className={MypageStyle.payButtons}>
      {isPaymentModalOpen && (
        <PaymentModal
          amt={amt}
          subscriptionType={goodsName}
          closeModal={() => setIsPaymentModalOpen(false)}
        />
      )}
      <button
        className={MypageStyle.cardButton}
        onClick={handleCardButtonClick}
      >
        <CardIcon />
        <span>신용카드</span>
      </button>
    </div>
  );
};

export default PayButtons;

PayButtons.propTypes = {
  membershipDataKeyForPay: PropTypes.string.isRequired,
};
