import findEmailPasswordStyles from "./FindEmailPassword.module.scss"; // 스타일 시트 임포트
import GuideTextContainer from "@components/FindEmailPassword/GuideTextContainer";
import FindEmailCompleteButtons from "@components/FindEmailPassword/FindEmailCompleteButtons";
import SwitchButtons from "@components/FindEmailPassword/SwitchButtons";
import {
  readSessionStorage,
  removeSessionStorage,
} from "@utils/webStorage/sessionStorage";
import { useEffect } from "react";
const FindEmailComplete = () => {
  const email = readSessionStorage("email");
  useEffect(() => {
    console.log(email);
    return () => {
      removeSessionStorage("email");
    };
  }, []);

  return (
    <div className={findEmailPasswordStyles.findEmailPasswordContainer}>
      <GuideTextContainer
        title="이메일 / 비밀번호 찾기"
        subtitle="휴대전화 번호 정보와 일치하는 이메일입니다."
      />
      <div className={findEmailPasswordStyles.findEmailCompleteBox}>
        <SwitchButtons
          activeButtonName="email"
          onTabChange={() => {}}
          isFindEmailComplete={true}
        />

        <h2 className={findEmailPasswordStyles.emailText}>
          {email
            ? `이메일 : ${email} 입니다.`
            : "해당 이메일 계정이 존재하지 않습니다."}
        </h2>
        <FindEmailCompleteButtons email={email} />
      </div>
    </div>
  );
};

export default FindEmailComplete;
