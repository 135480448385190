import InputField from "@components/SignUp/InputField";
import commonUserStyles from "@components/Common/User/CommonUserStyle.module.scss";

const AuthCodeInput = ({
  code,
  onChange,
  onSubmit,
  isCheckValid,
  time,
  isPhoneAuthSent,
  isCheckEmailDuplication,
}) => {
  return (
    <div className={commonUserStyles.authContentBox}>
      <div className={commonUserStyles.authContainer}>
        <InputField
          name="code"
          value={code}
          onChange={onChange}
          placeholder="인증번호 입력"
          pattern="^[0-9]{6}$"
          error={isCheckValid === false ? "인증번호가 일치하지 않습니다." : ""}
          check={isCheckValid === true ? "인증번호가 일치합니다." : ""}
        />
        <button
          className={commonUserStyles.authButton}
          onClick={onSubmit}
          disabled={time <= 0 || code.length !== 6 || isCheckEmailDuplication}
        >
          확인
        </button>
      </div>
      {isPhoneAuthSent &&
        isCheckValid === null && // 인증 결과가 없을 때만 시간 표시
        (time > 0 ? (
          <span className={commonUserStyles.authTimer}>
            {new Date(time * 1000).toISOString().substr(14, 5)}
          </span>
        ) : (
          <span className={commonUserStyles.authTimer}>
            시간이 만료되었습니다
          </span>
        ))}
    </div>
  );
};

export default AuthCodeInput;
