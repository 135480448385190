import { Routes, Route } from "react-router-dom";
import Home from "@pages/Home/Home";
import MainLayout from "@layouts/MainLayoutWithoutFooter";
import Login from "@pages/Login/Login";
import EmailLogin from "@pages/Login/EmailLogin";
import SignUp from "@pages/SignUp/SignUp";
import SignUpComplete from "@pages/SignUp/SignUpComplete";
import FindEmailPassword from "@pages/FindEmailPassword/FindEmailPassword";
import FindEmailComplete from "@pages/FindEmailPassword/FindEmailComplete";
import FindPasswordComplete from "@pages/FindEmailPassword/FindPasswordComplete";
import AddPhoneNumber from "@pages/SignUp/AddPhoneNumber";
import CompleteDeleteAccount from "@pages/Mypage/MyMembership/CompleteDeleteAccount";
import MainLayoutWithoutFooter from "@layouts/MainLayoutWithoutFooter.js";
import PrivacyPolicy from "@pages/Agreements/PrivacyPolicy";
import MarketingConsent from "@pages/Agreements/MarketingConsent";
import TermsOfService from "@pages/Agreements/TermsOfService";
import CompleteDeleteMembership from "@pages/Mypage/MyMembership/CompleteDeleteMembership";

function UnAuthenticatedApp() {
  return (
    <Routes>
      <Route path="/" element={<MainLayoutWithoutFooter />}>
        <Route path="/" element={<Home />} />
        <Route path="/" element={<MainLayout />}>
          <Route path="/login">
            <Route index element={<Login />} />
            <Route path="emailLogin" element={<EmailLogin />} />
            <Route path="signup" element={<SignUp />} />
          </Route>
          <Route path="/signup">
            <Route index element={<SignUp />} />
            <Route path="complete" element={<SignUpComplete />} />
            <Route path="addPhoneNumber" element={<AddPhoneNumber />} />
          </Route>
          <Route path="/findEmailPassword">
            <Route path=":isEmail" element={<FindEmailPassword />} />
            <Route path="findEmailComplete" element={<FindEmailComplete />} />
            <Route
              path="findPasswordComplete"
              element={<FindPasswordComplete />}
            />
          </Route>

          <Route path="/mypage">
            <Route
              path="completeDeleteMembership"
              element={<CompleteDeleteMembership />}
            />
          </Route>
        </Route>
        <Route path="/mypage">
          <Route
            path="completeDeleteAccount"
            element={<CompleteDeleteAccount />}
          />
        </Route>
        <Route path="*" element={<Login />} />
      </Route>

      <Route path="/agreements">
        <Route path="privacyPolicy" element={<PrivacyPolicy />} />
        <Route path="marketingConsent" element={<MarketingConsent />} />
        <Route path="termsOfService" element={<TermsOfService />} />
      </Route>
    </Routes>
  );
}

export default UnAuthenticatedApp;
