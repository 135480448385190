import styles from "@pages/Login/Login.module.scss";
import { MEMBERSHIP_DATA } from "@utils/constants/membership/membership";
import { useAppNavigate } from "@hooks/navigate/navigate";
import fireworkVideo from "@assets/videoes/firework3.webm";

const FirstLoginBenefits = () => {
  const { goToRankingSearch } = useAppNavigate();

  const subscriptionType = "standard";
  const membershipDataKey = "standard";
  return (
    <div className={styles.firstLoginBenefits}>
      <video className={styles.fireworkIcon} autoPlay muted loop>
        <source src={fireworkVideo} type="video/webm" />
      </video>

      <div className={styles.textBox}>
        <h1 className={styles.title}>신규가입자 혜택</h1>
        <h2 className={styles.subtitle}>
          스텐다드 구독권을 2주간 무료로 제공해드립니다.
          <br />
          <span className={styles.cost}>25,900원</span>
          <span className={styles.arrow}>→</span>
          <span className={styles.blueText}>0원</span>
        </h2>
      </div>

      <div className={styles.benefitBox}>
        <span>실시간 순위분석</span>
        <span>
          순위 알림 수 {MEMBERSHIP_DATA[membershipDataKey].alarmCount}건
        </span>

        <div className={styles.overOneItems}>
          <span>
            등록가능한 플레이스
            {MEMBERSHIP_DATA[membershipDataKey].placeCount}건
          </span>

          {subscriptionType !== "premium" ? (
            <span>
              플레이스 별 등록가능한 키워드 <br />
              {MEMBERSHIP_DATA[membershipDataKey].keywordCount}건(총{" "}
              {MEMBERSHIP_DATA[membershipDataKey].keywordCount *
                MEMBERSHIP_DATA[membershipDataKey].placeCount}
              개)
            </span>
          ) : (
            <span>
              키워드{" "}
              {MEMBERSHIP_DATA[membershipDataKey].keywordCount *
                MEMBERSHIP_DATA[membershipDataKey].placeCount}
              개를 원하는
              <br />
              플레이스에 자유롭게 등록
            </span>
          )}
        </div>

        {subscriptionType !== "basic" ? (
          <span>
            순위로그+세부로그 분석 <br />
            (저장,리뷰,블로그 수)
          </span>
        ) : null}
      </div>

      <button
        className={styles.goToRankingButton}
        onClick={() => goToRankingSearch()}
      >
        내 플레이스 순위 분석하러가기
      </button>
    </div>
  );
};

export default FirstLoginBenefits;
