import axiosRetry from "axios-retry";

export const setAxiosRetry = (axiosInstance) => {
  axiosRetry(axiosInstance, {
    retries: 3, // 재시도 횟수
    retryDelay: (retryCount) => {
      return retryCount * 1000; // 재시도 간격 (밀리초)
    },
    retryCondition: (error) => {
      // 재시도 조건 설정 (기본적으로 idempotent 요청만 재시도)
      return error.response && error.response.status >= 500;
    },
  });
};
