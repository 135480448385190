import React, { useState, useEffect } from "react";
import InputField from "@components/SignUp/InputField";
import Checkbox from "@components/SignUp/CheckBox";
import signUpStyles from "@components/SignUp/SignUpStyle.module.scss";
import { VALIDATIONS } from "@utils/constants/validation/fieldValidations";
import { useUserHandler } from "@hooks/user/useUserHandler";
import { useCountdownTimer } from "@hooks/common/useCountdownTimer";
import { API_ERROR_TYPE } from "@utils/constants/api/errorType";
import SingleConfirmModal from "@components/Common/Modal/Dialogs/SingleConfirmModal";
import { VERIFICATION_TYPE } from "@utils/constants/api/verificationType";
import { useAppNavigate } from "@hooks/navigate/navigate";

function SignupForm() {
  const {
    handleCheckEmailDuplication,
    handleSignupByEmail,
    handleSendAuthCode,
    handleCheckAuthCode,
  } = useUserHandler();
  const { goToTermsOfService, goToPrivacyPolicy, goToMarketingConsent } =
    useAppNavigate();
  const { time, startTimer, clearTimer } = useCountdownTimer(240);
  // formData 상태를 사용하여 폼 데이터를 관리합니다.
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    confirmPassword: "",
    name: "",
    birthdate: "",
    phoneNumber: "",
    authCode: "",
  });
  const [isCheckValid, setIsCheckValid] = useState({
    isCheckEmailDuplication: null, // 이메일 중복 여부
    isCheckAgeConsent: null, // 만 14세 이상 동의
    isCheckTermsOfService: null, // 서비스 이용약관 동의
    isCheckPrivacyPolicy: null, // 개인정보 수집/이용 동의
    isCheckPhoneAuth: null, // 휴대폰 인증 상태
    isCheckPasswordMatch: null, // 비밀번호 일치 여부
  });

  const [choiceAgreement, setChoiceAgreement] = useState({
    isCheckMarketing: null, // 마케팅 동의
  });

  const [modalTitle, setModalTitle] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [errorMessages, setErrorMessages] = useState({
    emailCheck:
      isCheckValid.isCheckEmailDuplication === false
        ? "이미 가입된 계정입니다."
        : "",
    authCodeMessage:
      isCheckValid.isCheckPhoneAuth === false
        ? "인증번호가 일치하지 않습니다"
        : "",
  });

  const [checkMessages, setCheckMessages] = useState({
    emailCheck:
      isCheckValid.isCheckEmailDuplication === true
        ? "사용 가능한 이메일입니다."
        : "",
    authCodeMessage:
      isCheckValid.isCheckPhoneAuth === true ? "인증번호가 일치합니다." : "",
  });

  useEffect(() => {
    setCheckMessages((prev) => ({
      ...prev,
      emailCheck:
        isCheckValid.isCheckEmailDuplication === true
          ? "사용 가능한 이메일입니다."
          : "",
      authCodeMessage:
        isCheckValid.isCheckPhoneAuth === true ? "인증번호가 일치합니다." : "",
      passwordMatch:
        isCheckValid.isCheckPasswordMatch === true
          ? "비밀번호가 일치합니다."
          : "",
    }));

    setErrorMessages((prev) => ({
      ...prev,
      emailCheck:
        isCheckValid.isCheckEmailDuplication === false
          ? "이미 가입된 계정입니다."
          : "",
      authCodeMessage:
        isCheckValid.isCheckPhoneAuth === false
          ? "인증번호가 일치하지 않습니다"
          : "",
      passwordMatch:
        isCheckValid.isCheckPasswordMatch === false
          ? "비밀번호가 일치하지 않습니다"
          : "",
    }));
  }, [
    isCheckValid.isCheckEmailDuplication,
    isCheckValid.isCheckPhoneAuth,
    isCheckValid.isCheckPasswordMatch,
  ]);

  // formValid 상태를 사용하여 폼의 전체 유효성을 관리합니다.

  // 휴대폰 인증번호 발송 여부 -> 인증번호 입력칸 visible 여부
  const [isPhoneAuthSent, setPhoneAuthSent] = useState(false);
  const [resendCount, setResendCount] = useState(0);
  // 비밀번호 일치 여부를 확인하는 함수입니다.
  const isPasswordMatch = formData.password === formData.confirmPassword;

  // "모두 동의" 체크박스를 관리하는 함수입니다.
  const handleAllAgree = (e) => {
    const checked = e.target.checked;
    // 모든 개별 체크박스의 상태를 "모두 동의" 체크박스의 상태로 설정합니다.
    setIsCheckValid((prev) => ({
      ...prev,
      isCheckAgeConsent: checked,
      isCheckTermsOfService: checked,
      isCheckPrivacyPolicy: checked,
    }));
    setChoiceAgreement((prev) => ({
      ...prev,
      isCheckMarketing: checked,
    }));
    console.log("choiceAgreement", choiceAgreement);
  };

  const checkAllAgree = () => {
    if (
      isCheckValid.isCheckAgeConsent &&
      isCheckValid.isCheckTermsOfService &&
      isCheckValid.isCheckPrivacyPolicy &&
      choiceAgreement.isCheckMarketing
    ) {
      return true;
    }
    return false;
  };

  // 폼 제출 시 호출되는 함수입니다.
  const handleSubmit = (e) => {
    try {
      e.preventDefault();
      const form = e.target.form; // form 요소에 접근

      if (form.checkValidity()) {
        // form 내의 모든 입력값이 유효한지 검사

        // 생년월일의 형식을 .에서 -로 변환
        const formattedData = {
          ...formData,
          birthdate: formData.birthdate.replace(/\./g, "-"),
        };

        console.log("formattedData", formattedData);
        handleSignupByEmail(formattedData);
      } else {
        form.reportValidity(); // 유효하지 않은 입력에 대해 사용자에게 알림
      }
    } catch (error) {
      console.error("🚀 ~ handleSubmit ~ error:", error);
    }

    // 여기에 회원가입 처리 로직을 추가할 수 있습니다.
  };

  // 이메일 입력 필드의 유효성을 검사하여 버튼을 비활성화합니다.
  const isEmailInputValid = () => {
    const emailInput = document.querySelector('input[name="email"]');
    return emailInput ? emailInput.checkValidity() : false;
  };

  // 인증번호 전송 버튼 클릭 이벤트
  const handlePhoneAuthSend = async (e) => {
    e.preventDefault();
    if (resendCount < 4) {
      setPhoneAuthSent(true);
      setResendCount(resendCount + 1);
      startTimer();
      setIsCheckValid((prev) => ({
        ...prev,
        isCheckPhoneAuth: null,
      }));
      try {
        await handleSendAuthCode({
          phoneNumber: formData.phoneNumber,
          verificationType: VERIFICATION_TYPE.REGISTER,
        });
      } catch (error) {
        console.log("🚀 ~ handlePhoneAuthSend ~ error:", error);
        if (error && error.status === 409) {
          setPhoneAuthSent(false);
          if (
            error.response &&
            API_ERROR_TYPE.CONFLICT_KAKAO_USER === error.response.data.type
          ) {
            setModalTitle("이미 카카오에 가입되어 있는 계정입니다.");

            setIsModalOpen(true);
          } else if (
            error.response &&
            API_ERROR_TYPE.CONFLICT_NAVER_USER === error.response.data.type
          ) {
            setModalTitle("이미 네이버에 가입되어 있는 계정입니다.");
            setIsModalOpen(true);
          }
        }
      }
    }
  };

  const handleVerifyAuthCode = async (e) => {
    e.preventDefault();
    clearTimer();
    try {
      await handleCheckAuthCode({
        phoneNumber: formData.phoneNumber,
        verificationType: VERIFICATION_TYPE.REGISTER,
        verificationCode: formData.authCode,
      });
      setIsCheckValid((prev) => ({
        ...prev,
        isCheckPhoneAuth: true,
      }));
    } catch (error) {
      console.error("🚀 ~ handleVerifyAuthCode ~ error:", error);
      if (error && (error.status === 401 || error.response.status === 404)) {
        setIsCheckValid((prev) => ({
          ...prev,
          isCheckPhoneAuth: false,
        }));
      } else {
        alert("오류가 발생했습니다. 다시 시도해주세요.");
      }
    }
  };

  const handleCallCheckEmailDuplication = async (e) => {
    e.preventDefault();
    try {
      const response = await handleCheckEmailDuplication(formData.email);
      setIsCheckValid((prev) => ({
        ...prev,
        isCheckEmailDuplication: true,
      }));
      console.log(checkMessages);
      console.log(formData);
    } catch (error) {
      if (error && error.status === 409) {
        setIsCheckValid((prev) => ({
          ...prev,
          isCheckEmailDuplication: false,
        }));
      } else {
        setIsCheckValid((prev) => ({
          ...prev,
          isCheckEmailDuplication: null,
        }));
      }

      console.error("🚀 ~ handleCallCheckEmailDuplication ~ error:", error);
    }
  };

  // 개별 체크박스의 상태를 업데이트하는 함수입니다.
  const handleCheckBoxChange = (e) => {
    const { name, checked } = e.target; // 이벤트로부터 이름과 체크 상태를 가져옵니다.
    setIsCheckValid((prev) => ({
      ...prev,
      [name]: checked, // 해당 체크박스의 상태를 업데이트합니다.
    }));

    setChoiceAgreement((prev) => ({
      ...prev,
      [name]: checked,
    }));
  };

  const handleInputChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;

    // 생년월일 입력 처리
    if (name === "birthdate") {
      // 숫자만 남기고 나머지 제거
      let formattedValue = value.replace(/\D/g, "");

      // 포맷팅: YYYY.MM.DD
      if (formattedValue.length > 4) {
        formattedValue =
          formattedValue.slice(0, 4) + "." + formattedValue.slice(4);
      }
      if (formattedValue.length > 7) {
        formattedValue =
          formattedValue.slice(0, 7) + "." + formattedValue.slice(7, 9);
      }

      setFormData((prev) => ({
        ...prev,
        [name]: formattedValue,
      }));
      return;
    }

    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleConfirmPasswordBlur = () => {
    console.log("formData", formData);
    setIsCheckValid((prev) => ({
      ...prev,
      isCheckPasswordMatch: formData.password === formData.confirmPassword,
    }));
  };

  const isFormValid = () => {
    return (
      isCheckValid.isCheckEmailDuplication &&
      isCheckValid.isCheckAgeConsent &&
      isCheckValid.isCheckTermsOfService &&
      isCheckValid.isCheckPrivacyPolicy &&
      isCheckValid.isCheckPhoneAuth &&
      isCheckValid.isCheckPasswordMatch
    );
  };

  const isFormFilled = () => {
    return Object.values(formData).every((value) => value.trim() !== "");
  };

  return (
    <form onSubmit={handleSubmit} className={signUpStyles.signupFormContainer}>
      <div className={signUpStyles.emailContainer}>
        <InputField
          label="이메일"
          type="email"
          name="email"
          value={formData.email}
          onChange={handleInputChange}
          placeholder="이메일을 입력해 주세요."
          isRequired={true}
          error={errorMessages.emailCheck}
          check={checkMessages.emailCheck}
        />
        <button
          className={signUpStyles.commonButton}
          disabled={!isEmailInputValid()}
          onClick={handleCallCheckEmailDuplication}
        >
          중복 확인
        </button>
      </div>

      <div className={signUpStyles.passwordContainer}>
        <InputField
          label="비밀번호"
          type="password"
          name="password"
          value={formData.password}
          onChange={handleInputChange}
          placeholder="비밀번호 (영문,소문자와 숫자포함 8~12자)"
          isRequired={true}
          pattern={VALIDATIONS.password.pattern}
        />
        <InputField
          type="password"
          name="confirmPassword"
          value={formData.confirmPassword}
          onChange={handleInputChange}
          placeholder="비밀번호를 한번 더 입력해 주세요."
          pattern={VALIDATIONS.password.pattern}
          isRequired={true}
          error={errorMessages.passwordMatch}
          check={checkMessages.passwordMatch}
          onBlur={handleConfirmPasswordBlur} // 포커스 해제 시 호출
        />
      </div>
      <InputField
        label="이름"
        name="name"
        value={formData.name}
        onChange={handleInputChange}
        placeholder="이름을 입력해 주세요."
        pattern={VALIDATIONS.name.pattern}
        isRequired={true}
      />
      <InputField
        label="생년월일"
        type="text" // 여기서 type을 text로 설정합니다.
        name="birthdate"
        value={formData.birthdate}
        onChange={handleInputChange}
        placeholder="YYYY.MM.DD"
        pattern="^[0-9]{4}.[0-9]{2}.[0-9]{2}$"
        isRequired={true}
      />

      <div className={signUpStyles.phoneAuthBox}>
        <div className={signUpStyles.phoneNumberBox}>
          <InputField
            label="휴대폰 번호"
            name="phoneNumber"
            value={formData.phoneNumber}
            onChange={handleInputChange}
            placeholder="숫자만 입력해 주세요."
            pattern={VALIDATIONS.phoneNumber.pattern}
            disabled={isPhoneAuthSent}
            isRequired={true}
          />

          <button
            className={signUpStyles.commonButton}
            onClick={handlePhoneAuthSend}
            disabled={
              (formData.phoneNumber.length !== 10 &&
                formData.phoneNumber.length !== 11) ||
              resendCount >= 4
            }
          >
            {/* TODO : 발송 로직이 성공적으로 실되면 버튼 비활성화 하기 */}
            {resendCount > 0 ? "재발송" : "인증번호 발송"}
          </button>
        </div>

        {isPhoneAuthSent && (
          <div className={signUpStyles.authTextBox}>
            <div className={signUpStyles.phoneNumberBox}>
              <InputField
                name="authCode"
                value={formData.authCode}
                onChange={handleInputChange}
                placeholder="인증번호를 입력해 주세요."
                isRequired={true}
                error={errorMessages.authCodeMessage}
                check={checkMessages.authCodeMessage}
              />
              <button
                onClick={handleVerifyAuthCode}
                className={signUpStyles.phoneAuthButton}
                disabled={time <= 0 || formData.authCode.length !== 6}
              >
                인증번호 확인
              </button>
            </div>
            {isCheckValid.isCheckPhoneAuth === null &&
              (time > 0 ? (
                <span className={signUpStyles.authTimer}>
                  {new Date(time * 1000).toISOString().substr(14, 5)}
                </span>
              ) : (
                <span className={signUpStyles.authTimer}>
                  시간이 만료되었습니다
                </span>
              ))}
          </div>
        )}
      </div>
      <div className={signUpStyles.agreementContainer}>
        <Checkbox
          label="모두 동의합니다."
          isChecked={checkAllAgree()}
          onChange={(e) => handleAllAgree(e)}
          isRequired={false}
        />
        <div className={signUpStyles.agreementDivider}></div>
        <Checkbox
          label="만 14세 이상입니다."
          isChecked={isCheckValid.isCheckAgeConsent}
          onChange={handleCheckBoxChange}
          isRequired={true}
          name="isCheckAgeConsent"
        />{" "}
        <Checkbox
          label={
            <>
              <strong>서비스 이용약관</strong>에 동의합니다
            </>
          }
          isChecked={isCheckValid.isCheckTermsOfService}
          onChange={handleCheckBoxChange}
          isRequired={true}
          name="isCheckTermsOfService"
          clickable={true}
          onClick={() => goToTermsOfService(false)}
        />{" "}
        <Checkbox
          label={
            <>
              <strong>개인정보 수집/이용</strong>에 동의합니다
            </>
          }
          isChecked={isCheckValid.isCheckPrivacyPolicy}
          onChange={handleCheckBoxChange}
          isRequired={true}
          name="isCheckPrivacyPolicy"
          clickable={true}
          onClick={() => goToPrivacyPolicy(false)}
        />
        <Checkbox
          label={
            <>
              <strong>광고성 정보 수신 및 마케팅 활용</strong>에 동의합니다
              (선택)
            </>
          }
          isChecked={choiceAgreement.isCheckMarketing}
          onChange={handleCheckBoxChange}
          isRequired={false}
          name="isCheckMarketing"
          clickable={true}
          onClick={() => goToMarketingConsent(false)}
        />
      </div>
      <button
        className={signUpStyles.signupButton}
        type="submit"
        onClick={handleSubmit}
        disabled={!isFormValid() || !isFormFilled()}
      >
        회원가입
      </button>

      <SingleConfirmModal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        title={modalTitle}
        cancelText="확인"
      ></SingleConfirmModal>
    </form>
  );
}

export default SignupForm;
