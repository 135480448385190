// src/dto/PlaceDTO.js
import { MonthlyCountDTO } from "@dto/place/MonthlyCountDTO";
import { BasePlaceInfo } from "@dto/place/BasePlaceInfo";

export class SearchPlaceListDTO {
  constructor({ monthlyCount = {}, placeList = [], functionType = "" }) {
    this.monthlyCount = new MonthlyCountDTO(monthlyCount ?? {});
    this.placeList = (placeList ?? []).map(
      (place) => new SearchResultPlaceDTO(place)
    );
    this.functionType = functionType ?? "";
  }

  // GET methods
  getMonthlyCount() {
    return this.monthlyCount;
  }

  getPlaceList() {
    return this.placeList;
  }

  getFunctionType() {
    return this.functionType;
  }

  // SET methods
  setMonthlyCount(value) {
    this.monthlyCount = new MonthlyCountDTO(value);
  }

  setPlaceList(value) {
    this.placeList = value.map((place) => new SearchResultPlaceDTO(place));
  }

  setFunctionType(value) {
    this.functionType = value;
  }
}

export class SearchResultPlaceDTO extends BasePlaceInfo {
  constructor({
    id = "",
    blogCafeReviewCount = 0,
    rank = 0,
    pcRank = "-",
    mobileRank = "-",
    isRegistered = false,
    ...baseProps
  } = {}) {
    super(baseProps);
    this.id = id;
    this.blogCafeReviewCount = parseInt(blogCafeReviewCount) || 0;
    this.rank = parseInt(rank) || 0;
    this.pcRank = pcRank;
    this.mobileRank = mobileRank;
    this.isRegistered = isRegistered;
  }

  // GET methods
  getId() {
    return this.id;
  }

  getBlogCafeReviewCount() {
    return this.blogCafeReviewCount;
  }

  getRank() {
    return this.rank;
  }

  getPcRank() {
    return this.pcRank;
  }

  getMobileRank() {
    return this.mobileRank;
  }

  getIsRegistered() {
    return this.isRegistered;
  }

  // SET methods
  setId(value) {
    this.id = value;
  }

  setBlogCafeReviewCount(value) {
    this.blogCafeReviewCount = value;
  }

  setRank(value) {
    this.rank = value;
  }

  setPcRank(value) {
    this.pcRank = value;
  }

  setMobileRank(value) {
    this.mobileRank = value;
  }

  setIsRegistered(value) {
    this.isRegistered = value;
  }
}
