import style from "@components/DetailRegisteredPlace/DetailRegisteredPlaceStyle.module.scss";
import { KeywordRank, PlaceRank } from "@dto/place/KeywordDetialDTO";
import PropTypes from "prop-types";
import { map, chunk } from "lodash";
import React, { useState } from "react";
import { usePlaceHandlers } from "@hooks/place/usePlaceHandlers";
import textAnimation from "@assets/styles/textAnimation.module.scss";
const RankingTable = ({
  keywordRankList,
  toggleAlarm,
  isVisibleMore,
  registrationPlaceId,
  placeInfo,
  placeRankList, // 추가된 부분
}) => {
  const [showNotification, setShowNotification] = useState(false);
  const { handleToggleNotification } = usePlaceHandlers();
  // 애니메이션 속도를 CSS 변수로 설정
  const animationStyle = {
    "--animation-duration": "0.6s",
  };

  const getTotalMonthlySearchCount = () => {
    const total =
      keywordRankList.getMonthlyCount().getMonthlyPcSearchCount() +
      keywordRankList.getMonthlyCount().getMonthlyMobileSearchCount();
    return total.toLocaleString(); // 쉼표 추가
  };

  const switchNotification = async (switchAlarm) => {
    try {
      await handleToggleNotification(
        !switchAlarm,
        keywordRankList.getKeywordName(),
        registrationPlaceId
      );
      toggleAlarm();
      showTemporaryNotification(switchAlarm);
    } catch (error) {
      console.log("🚀 ~ switchNotification ~ error:", error);
    }
  };

  const showTemporaryNotification = (switchAlarm) => {
    if (switchAlarm) {
      setShowNotification(true);

      const timer = setTimeout(() => {
        setShowNotification(false);
      }, 2000);

      return () => clearTimeout(timer);
    }
  };

  if (0 === keywordRankList.getDateRankList().length) {
    return null;
  }

  return (
    <div
      className={`${textAnimation.slideFadeUp} ${style.rankingTableBox}`}
      style={animationStyle}
    >
      <table className={style.table}>
        <thead>
          <tr>
            <th colSpan="10" className={style.mainHeader}>
              <div className={style.headerBox}>
                <div className={style.keywordBox}>
                  <span className={style.keyword}>
                    {keywordRankList.getKeywordName() || "키워드 없음"}
                  </span>
                  <div className={style.divider}></div>
                  <span>{placeInfo.getPlaceName() || "이름 없음"}</span>{" "}
                  {/* 변경된 부분 */}
                </div>
                {/* 알람 아이콘 (활성화 상태에 따라 변경) */}

                <div className={style.rightBox}>
                  <span>월 검색량 {getTotalMonthlySearchCount()}</span>
                  <div
                    className={`${style.alarmIcon} ${
                      keywordRankList.getNotification() ? style.active : ""
                    }`}
                    onClick={() =>
                      switchNotification(
                        keywordRankList.getNotification() || false
                      )
                    }
                  >
                    {showNotification && (
                      <div className={style.notification}>알림이 꺼졌어요.</div>
                    )}
                  </div>
                </div>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          {/* 테이블 본문: rankItems 배열을 10개씩 나누어 각 행에 표시 */}
          {chunk(
            keywordRankList.getDateRankList(),
            isVisibleMore ? 12 : 13
          ).map((chunkedItems, index) => (
            <tr
              key={index}
              className={`${style.tableRow} ${
                isVisibleMore ? style.moreVisibleTableRow : ""
              }`}
            >
              {map(chunkedItems, (item, index) => (
                <td
                  key={index}
                  className={`${style.tableItemBox} ${
                    isVisibleMore ? style.moreVisibletableItemBox : ""
                  }`}
                >
                  {/* 날짜 및 순위 정보 표시 */}
                  <span>{item.getFormatDate() || "날짜 없음"}</span>
                  {isVisibleMore ? (
                    <>
                      <div className={style.tagAndRankBox}>
                        <span className={style.rankTag}>순</span>
                        <span className={style.keywordRank}>
                          {0 === item.getRank() ? "-" : item.getRank() || "-"}
                        </span>
                      </div>
                      {/* 저장수, 리뷰수, 블로그수는 placeRankList에서 가져옵니다 */}
                      {placeRankList
                        .filter(
                          (placeRank) => placeRank.getDate() === item.getDate()
                        )
                        .map((placeRank, idx) => (
                          <React.Fragment key={idx}>
                            <div className={style.tagAndRankBox}>
                              <span className={style.saveTag}>저</span>
                              <span className={style.saveTagText}>
                                {placeRank.getFormatSaveCount() || "-"}
                              </span>
                            </div>
                            <div className={style.tagAndRankBox}>
                              <span className={style.visitorReviewTag}>리</span>
                              <span className={style.visitorReviewTagText}>
                                {placeRank.getReviewCount() || "-"}
                              </span>
                            </div>
                            <div className={style.tagAndRankBox}>
                              <span className={style.blogReviewTag}>블</span>
                              <span className={style.blogReviewTagText}>
                                {placeRank.getBlogCount() || "-"}
                              </span>
                            </div>
                          </React.Fragment>
                        ))}
                    </>
                  ) : (
                    <span className={style.keywordRank}>
                      {0 === item.getRank()
                        ? "-"
                        : item.getRank() || "-" + "위"}
                    </span>
                  )}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

RankingTable.propTypes = {
  keywordRankList: PropTypes.instanceOf(KeywordRank).isRequired,
  toggleAlarm: PropTypes.func.isRequired,
  isVisibleMore: PropTypes.bool.isRequired,
  placeRankList: PropTypes.arrayOf(PropTypes.instanceOf(PlaceRank)).isRequired, // 추가된 부분
};

export default RankingTable;
