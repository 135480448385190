export class MembershipInfoDTO {
  constructor({ subscriptionInfo, paymentMethod = null }) {
    this.subscriptionInfo = subscriptionInfo;
    this.paymentMethod = paymentMethod;
  }

  // Getter and Setter for subscriptionInfo
  getSubscriptionInfo() {
    return this.subscriptionInfo;
  }

  setSubscriptionInfo(subscriptionInfo) {
    this.subscriptionInfo = subscriptionInfo;
  }

  // Getter and Setter for paymentMethod
  getPaymentMethod() {
    return this.paymentMethod;
  }

  setPaymentMethod(paymentMethod) {
    this.paymentMethod = paymentMethod;
  }
}
