import MembershipStyle from "@components/Mypage/AccountSettings/AccountStyle.module.scss";
import { useSelector } from "react-redux";
import { MEMBERSHIP_DATA } from "@utils/constants/membership/membership";
import ConfirmDialog from "@components/Common/Modal/Dialogs/ConfirmDialog";
import { useState } from "react";
import { useMembershipHandlers } from "@hooks/membership/useMembershipHandlers";
import { setMembershipData } from "@utils/functions/membership/membership";
import { useDispatch } from "react-redux";
import { useAppNavigate } from "@hooks/navigate/navigate";
const MembershipCancelBox = () => {
  const membershipData = useSelector((state) => state.membership);
  const { handleCancelMembership } = useMembershipHandlers();
  const { goToCompleteDeleteMembership } = useAppNavigate();
  const dispatch = useDispatch();

  const [isModalOpen, setIsModalOpen] = useState(false); // 모달 상태를 관리하는 상태 변수와 setter 함수
  if (membershipData.subscriptionType === null) {
    return <div>현재 구독중인 멤버십이 없습니다.</div>;
  }

  const handleCancel = () => {
    setIsModalOpen(true);
    console.log("cancel");
  };

  const handleClose = () => {
    setIsModalOpen(false);
  };

  const handleConfirm = async () => {
    try {
      await handleCancelMembership();
      await setMembershipData(
        {
          subscriptionInfo: {
            type: "",
            startDate: "",
            endDate: "",
          },
          isFreeTrial: false,

          resetSubscriptionType: true,
        },
        dispatch
      );
      goToCompleteDeleteMembership();
    } catch (error) {
      console.error("🚀 ~ handleConfirm ~ error:", error);
    }
  };

  return (
    <div className={MembershipStyle.membershipCancelBox}>
      <h1 className={MembershipStyle.title}>멤버십 해지</h1>

      <div className={MembershipStyle.cancelInfoBox}>
        <span className={MembershipStyle.subscriptionType}>
          {MEMBERSHIP_DATA[membershipData.subscriptionType].membershipName}
        </span>

        <span className={MembershipStyle.cancelInfo}>
          <span className={MembershipStyle.redText}>
            {membershipData.isFreeTrial === true
              ? "무료 체험 중 "
              : `이용 금액 ${
                  MEMBERSHIP_DATA[membershipData.subscriptionType]
                    .membershipCost
                }원`}
          </span>
          ({membershipData.startDate} ~{membershipData.endDate})
        </span>

        {membershipData.isFreeTrial === true ? null : (
          <button
            className={MembershipStyle.cancelButton}
            onClick={handleCancel}
          >
            해지
          </button>
        )}
      </div>

      <span className={MembershipStyle.warning}>
        *해지 시 구독 만료일까지 서비스를 이용 가능하며 이후 정기결제는
        이루어지지 않습니다.
      </span>

      <ConfirmDialog
        open={isModalOpen}
        onClose={handleClose}
        onConfirm={handleConfirm}
        title={
          <span>
            정말로 멤버십을 해지하시겠어요? <br />
            구독을 해지하시면 모든 로그 기록이 삭제됩니다.
          </span>
        }
        cancelText="취소"
        confirmText="해지"
      ></ConfirmDialog>
    </div>
  );
};

export default MembershipCancelBox;
