import {
  fetchMembershipStart,
  fetchMembershipInitial,
} from "@features/membership/membershipSlice";
import {
  subscribeMembershipWithNicePay,
  getMembershipInfo,
  cancelMembership,
  exitUser,
} from "@utils/api/membership/membership";
import { useDispatch } from "react-redux";
import { setMembershipData } from "@utils/functions/membership/membership";

export const useMembershipHandlers = () => {
  const dispatch = useDispatch();
  const handleSubscribeMembership = async (formData) => {
    try {
      console.log("🚀 ~ handleSubscribeMembership ~ formData:", formData);
      dispatch(fetchMembershipStart());
      const response = await subscribeMembershipWithNicePay(formData);
      console.log("🚀 ~ handleSubscribeMembership ~ response:", response);
      await setMembershipData(response.data, dispatch);
      window.location.reload();
    } catch (error) {
      console.error("🚀 ~ handleSubscribeMembership ~ error:", error);
      dispatch(fetchMembershipInitial());
      if (error.response && error.response.status === 400) {
        alert("필수 입력 항목을 확인하고 다시 시도해주세요.");
      } else if (error.response && error.response.status === 409) {
        alert("이미 구독 중인 멤버십이 존재합니다.");
      } else {
        alert("결제 실패 다시 시도해주세요");
      }
      window.location.reload();
    }
  };

  const handleGetMembershipInfo = async () => {
    try {
      const data = await getMembershipInfo();
      return data;
    } catch (error) {
      console.log("🚀 ~ handleGetMembershipInfo ~ error:", error);
      if (error && error.status === 404) {
        throw error;
      } else {
        alert("멤버십 정보를 불러오는데 실패했습니다.");
      }
      throw error;
    }
  };

  const handleCancelMembership = async () => {
    try {
      await cancelMembership();
    } catch (error) {
      console.error("🚀 ~ handleCancelMembership ~ error:", error);
      if (error && error.status === 404) {
        alert("구독중인 멤버십 정보를 찾을 수가 없습니다.");
      } else {
        alert("멤버십 해지 중 오류가 발생했습니다. 다시 시도해주세요.");
      }
      throw error;
    }
  };

  const handleExitUser = async () => {
    try {
      await exitUser();
    } catch (error) {
      console.error("🚀 ~ handleExitUser ~ error:", error);
      alert("회원탈퇴중 오류가 발생했습니다. 다시 시도해주세요.");
      throw error;
    }
  };

  return {
    handleSubscribeMembership,
    handleGetMembershipInfo,
    handleCancelMembership,
    handleExitUser,
  };
};
