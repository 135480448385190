import styles from "@layouts/layout.module.scss";
import { Outlet } from "react-router-dom";
function MainLayoutWithoutFooter() {
  return (
    <div className={styles.containerWithoutFooter}>
      <main className={styles.mainBox}>
        <Outlet />
      </main>
    </div>
  );
}

export default MainLayoutWithoutFooter;
