import style from "@components/DetailRegisteredPlace/DetailRegisteredPlaceStyle.module.scss";
import { useState } from "react";
import { map } from "lodash";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import ConfirmDialog from "@components/Common/Modal/Dialogs/ConfirmDialog";
import SingleConfirmModal from "@components/Common/Modal/Dialogs/SingleConfirmModal";
import { usePlaceHandlers } from "@hooks/place/usePlaceHandlers";
import { API_ERROR_TYPE } from "@utils/constants/api/errorType";
import { PlaceInfo } from "@dto/place/KeywordDetialDTO";
import OverlayLoader from "@components/Common/Loader/OverlayLoader";
import textAnimation from "@assets/styles/textAnimation.module.scss";
const KeywordManager = ({
  onRegisterKeyword,
  keywordRankList,
  onRemoveKeyword,
  placeInfo,
  onKeywordClick,
}) => {
  const { registrationPlaceId } = useParams();
  const animationStyle = {
    "--animation-duration": "0.8s",
  };
  const [keyword, setKeyword] = useState("");
  const [selectedKeywordName, setSelectedKeywordName] = useState(null);
  const { handleDeleteKeyword, handleRegisterKeyword } = usePlaceHandlers();
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [confirmDialogTitle, setConfirmDialogTitle] = useState("");
  const [openSingleConfirmModal, setOpenSingleConfirmModal] = useState(false);
  const [singleConfirmModalTitle, setSingleConfirmModalTitle] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleRegisterClick = async () => {
    if (keyword.trim() === "") {
      alert("키워드를 입력하세요");
      return;
    }
    try {
      setIsLoading(true);
      const data = await handleRegisterKeyword({
        keywordName: keyword,
        placeId: placeInfo.getPlaceId() || "",
        placeName: placeInfo.getPlaceName() || "",
      });

      onRegisterKeyword(data); // 상위 컴포넌트 메서드 호출
    } catch (error) {
      console.log("🚀 ~ handleRegisterClick ~ error:", error);
      if (error && error.response.status === 409) {
        if (
          API_ERROR_TYPE.EXCEED_POSSIBLE_REGISTRATION_NUMBER ===
          error.response.data.type
        ) {
          setSingleConfirmModalTitle("등록 가능한 키워드 수를 초과했습니다.");
        } else {
          setSingleConfirmModalTitle("이미 등록된 키워드입니다.");
        }
        setOpenSingleConfirmModal(true);
      }
    } finally {
      setIsLoading(false);
      setKeyword(""); // 입력 필드 초기화
    }
  };

  const handleRemoveKeyword = (keywordName) => {
    setSelectedKeywordName(keywordName);
    setOpenConfirmDialog(true);
    setConfirmDialogTitle(
      <span>
        선택한 키워드 로그 값이 영구 삭제됩니다. <br /> 정말 삭제하시겠습니까 ?
      </span>
    );
  };

  const handleConfirmDialog = async () => {
    console.log("🚀 ~ handleConfirmDialog ~");

    if (registrationPlaceId && selectedKeywordName) {
      console.log(
        "🚀 ~ handleConfirmDialog ~ registrationPlaceId:",
        registrationPlaceId
      );
      try {
        await handleDeleteKeyword({
          registrationPlaceId: registrationPlaceId,
          keyword: selectedKeywordName,
        });
        onRemoveKeyword(selectedKeywordName);
        setOpenConfirmDialog(false);
      } catch (error) {
        console.log("🚀 ~ handleConfirmDialog ~ error:", error);
      }
    }
  };

  return (
    <div
      className={`${textAnimation.fadeIn} ${style.keywordManagerBox}`}
      style={animationStyle}
    >
      {isLoading && <OverlayLoader />}
      <div className={style.keywordManagerTitle}>
        키워드 등록
        <br />
        <span className={style.keywordManagerSubTitle}>
          <span className={style.keywordManagerSubTitleBold}>
            지역 + 카테고리
          </span>
          로 검색 시 정확한 키워드를 검색할 수 있어요!
        </span>
      </div>

      <div className={style.keywordInputBox}>
        <input
          className={style.keywordInput}
          type="text"
          value={keyword}
          onChange={(e) => setKeyword(e.target.value)}
          placeholder="지역+카테고리로 키워드를 검색해 보세요.(ex. 이태원 이자카야)"
        />
        <button onClick={handleRegisterClick} className={style.registerButton}>
          등록
        </button>
      </div>

      <div
        className={`${textAnimation.fadeIn} ${style.keywordListBox}`}
        style={animationStyle}
      >
        {map(keywordRankList, (data, index) => (
          <div key={index} className={style.keywordItem}>
            <span
              onClick={() => onKeywordClick(data.keywordName)}
              className={style.keywordName}
            >
              {data.keywordName}
            </span>
            <button
              onClick={() => handleRemoveKeyword(data.keywordName)}
              className={style.removeButton}
            ></button>
          </div>
        ))}
      </div>

      <span className={style.announcementContent}>
        * 한번 삭제된 키워드 로그는 복구가 불가능하니, 삭제 전에 신중히 확인해
        주시기 바랍니다.
        <br />* 하루에 2번 업데이트되므로 최종 변경되는 순위로 로그값이
        보여집니다.
      </span>

      <ConfirmDialog
        open={openConfirmDialog}
        onClose={() => setOpenConfirmDialog(false)}
        onConfirm={handleConfirmDialog}
        title={confirmDialogTitle}
        cancelText="취소"
        confirmText="삭제"
      ></ConfirmDialog>

      <SingleConfirmModal
        open={openSingleConfirmModal}
        onClose={() => setOpenSingleConfirmModal(false)}
        title={singleConfirmModalTitle}
        cancelText="확인"
      ></SingleConfirmModal>
    </div>
  );
};

KeywordManager.propTypes = {
  onRegisterKeyword: PropTypes.func.isRequired,

  onRemoveKeyword: PropTypes.func.isRequired,
  placeInfo: PropTypes.instanceOf(PlaceInfo),
  onKeywordClick: PropTypes.func.isRequired,
};

export default KeywordManager;
