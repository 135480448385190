import { API_URL } from "@utils/constants/api/api";
import axios from "axios";
import { handle500Error } from "@utils/api/axios/handle500Errors";
import { handle401Error } from "@utils/api/axios/handle401Errors";

export const defaultAxiosInstance = axios.create({
  //config 기본값 설정
  baseURL: API_URL,
  headers: {
    "Content-Type": "application/json",
  },
  withCredentials: true, // 쿠키를 포함하여 요청
});

// 요청 인터셉터 추가하기
defaultAxiosInstance.interceptors.request.use(
  function (config) {
    // 요청이 전달되기 전에 작업 수행
    console.log("🚀 ~ 요청 인터셉터 ~ config:", config);
    return config;
  },
  function (error) {
    // 요청 오류가 있는 작업 수행
    console.error("🚀 ~ 요청 인터셉터 에러:", error);
    return Promise.reject(error);
  }
);

// 응답 인터셉터 추가하기
defaultAxiosInstance.interceptors.response.use(
  function (response) {
    console.log("🚀 ~ 응답 인터셉터 ~ response:", response);
    return response;
  },
  async function (error) {
    console.log("🚀 ~ error.response.data:", error.response.data);

    if (error.response && error.response.status === 401) {
      try {
        const response = await handle401Error(error, defaultAxiosInstance);
        if (response) {
          return response;
        }
      } catch (handleError) {
        console.error("🚀 ~ handle401Error 실패:", handleError);
        return Promise.reject(handleError);
      }
    } else if (error.response && error.response.status === 404) {
    } else if (error.response && error.response.status === 500) {
      handle500Error(error);
    }
    return Promise.reject(error);
  }
);
