import Input from "@components/Mypage/AccountSettings/Input";
import style from "@components/Common/User/CommonUserStyle.module.scss";
const AuthCodeInputMypageInput = ({
  authNumber,
  handleInputChange,
  handleCheckAuthNumber,
  isCheckValid,
  time,
  isPhoneAuthSent,
}) => {
  return (
    <div className={style.authCodeInputMypageInputBox}>
      <div className={style.phoneNumberBox}>
        <Input
          label="휴대폰 인증번호"
          name="authNumber"
          type="text"
          placeholder="인증번호를 입력해 주세요."
          value={authNumber}
          onChange={handleInputChange}
          required={true}
          error={isCheckValid === false ? "인증번호가 일치하지 않습니다." : ""}
          check={isCheckValid === true ? "인증번호가 일치합니다." : ""}
        />

        <button
          className={style.checkAuthNumberButton}
          onClick={handleCheckAuthNumber}
          disabled={time <= 0 || authNumber.length !== 6}
        >
          확인
        </button>
      </div>
      {isPhoneAuthSent &&
        isCheckValid === null && // 인증 결과가 없을 때만 시간 표시
        (time > 0 ? (
          <span className={style.authTimer}>
            {new Date(time * 1000).toISOString().substr(14, 5)}
          </span>
        ) : (
          <span className={style.authTimer}>시간이 만료되었습니다</span>
        ))}
    </div>
  );
};

export default AuthCodeInputMypageInput;
