import InputField from "@components/SignUp/InputField";
import commonUserStyles from "@components/Common/User/CommonUserStyle.module.scss";

const PhoneNumberInput = ({
  phoneNumber,
  onChange,
  onSendAuthCode,
  resendCount,
}) => {
  return (
    <div className={commonUserStyles.phoneNumberInputBox}>
      <InputField
        name="phoneNumber"
        value={phoneNumber}
        onChange={onChange}
        placeholder="휴대전화 번호"
        pattern="^[0-9]{10,11}$"
      />
      <button
        className={commonUserStyles.authButton}
        disabled={
          (phoneNumber.length !== 10 && phoneNumber.length !== 11) ||
          resendCount >= 4
        }
        onClick={onSendAuthCode}
      >
        {resendCount > 0 ? "재발송" : "인증번호 발송"}
      </button>
    </div>
  );
};

export default PhoneNumberInput;
