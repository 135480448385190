export const VALIDATIONS = {
  email: {
    label: "이메일",
    type: "email",
    placeholder: "이메일(아이디)을 입력해 주세요.",
  },
  phoneNumber: {
    label: "휴대번호",
    type: "text",
    placeholder: "휴대폰 번호를 입력해 주세요.",
    pattern: "[0-9]{10,11}",
  },

  changePhoneNumber: {
    label: "휴대번호 변경",
    type: "text",
    placeholder: "변경할 휴대폰 번호를 입력해 주세요.",
    pattern: "[0-9]{10,11}",
  },
  name: {
    label: "이름",
    type: "text",
    maxLength: 30,
    pattern: "[가-힣]{2,5}$",
  },

  password: {
    label: "비밀번호",
    type: "password",
    minLength: 8,
    maxLength: 12,
    // 영어 대문자 소문자, 숫자,  조합 8~16자
    pattern: "(?=.*[A-Z])(?=.*[a-z])(?=.*\\d)[A-Za-z\\d@$!%*#?&]{8,16}",
  },
  placeKeyword: {
    label: "플레이스 키워드",
    type: "text",
    placeholder: "지역+카테고리로 검색해보세요.(ex. 이태원맛집, 강남카페)",
  },
  placeOrUrl: {
    label: "이름 또는 플레이스 URL",
    type: "text",
    placeholder: "이름 또는 플레이스 URL 링크를 입력해보세요.",
    pattern:
      "(^[a-zA-Z가-힣\\s]+$)|(^https?:\\/\\/m\\.place\\.naver\\.com\\/[\\w\\-]+\\/\\d+(\\/\\w+)*(\\/?\\?\\S*)?$)",
  },

  url: {
    label: "플레이스 URL",
    type: "url",
    placeholder: "플레이스 URL 링크를 입력해보세요.",
    pattern:
      "^https?:\\/\\/m\\.place\\.naver\\.com\\/[\\w\\-]+\\/\\d+(\\/\\w+)*(\\/?\\?\\S*)?$",
  },

  // 정규 표현식 설명:
  // ^https?:\\/\\/m\\.place\\.naver\\.com\\/[\\w\\-]+\\/\\d+(\\/\\w+)*(\\/?\\?\\S*)?$
  // 1. ^https?:\\/\\/: 'http' 또는 'https'로 시작하고, '://'를 포함
  // 2. m\\.place\\.naver\\.com\\/: 'm.place.naver.com/' 도메인
  // 3. [\\w\\-]+: 'restaurant'와 같은 단어 문자(알파벳, 숫자, 밑줄)와 하이픈이 포함된 문자열
  // 4. \\/\\d+: 슬래시 뒤에 하나 이상의 숫자 (예: '1621388960')
  // 5. (\\/\\w+)*: 슬래시 뒤에 단어 문자가 오는 형식이 0번 이상 반복 (예: '/home')
  // 6. (\\/?\\?\\S*)?: 선택적으로 슬래시와 쿼리 파라미터 (예: '?entry=pll')
  // 7. $: 문자열의 끝
};
