import {
  searchPlace,
  registerPlace,
  getRegisteredPlace,
  deleteRegisteredPlaces,
  getRegisteredPlaceDetail,
  getRegisteredPlaceTableInfo,
  toggleNotification,
  deleteKeyword,
  registerKeyword,
} from "@utils/api/place/place";
import { API_ERROR_TYPE } from "@utils/constants/api/errorType";
export const usePlaceHandlers = () => {
  const handleSearchPlace = async ({
    keyword,
    placeNameOrUrl,
    limit,
    page,
  }) => {
    try {
      console.log(
        "🚀 ~ handleSearchPlace ~ keyword, placeNameOrUrl, limit, page:",
        keyword,
        placeNameOrUrl,
        limit,
        page
      );
      const data = await searchPlace({
        keyword,
        placeNameOrUrl,
        limit,
        page,
      });
      console.log("🚀 ~ handleSearchPlace ~ data:", data);
      return data;
    } catch (error) {
      if (error && error.status === 400) {
        alert(
          "입력 정보를 다시 확인해 주시기 바랍니다. 모든 필수 항목이 올바르게 작성되었는지 확인해 주세요."
        );
      }
      throw error;
    }
  };

  const handleRegisterPlace = async (placeDataObject) => {
    try {
      const data = await registerPlace(placeDataObject);
      console.log("🚀 ~ handleRegisterPlace ~ data:", data);
      return data;
    } catch (error) {
      console.log("🚀 ~ handleRegisterPlace ~ error:", error);
      if (error && error.status === 500) {
        alert("서버 에러가 발생했습니다. 잠시 후 다시 시도해 주세요.");
      } else if (error && error.status === 409) {
        if (
          API_ERROR_TYPE.EXCEED_POSSIBLE_REGISTRATION_NUMBER ===
          error.response.data.type
        ) {
          console.log("등록 가능한 키워드 수를 초과.");
        }
      } else {
        alert("장소 등록에 실패했습니다. 잠시 후 다시 시도해 주세요.");
      }
      throw error;
    }
  };

  const handleGetRegisteredPlace = async () => {
    try {
      const data = await getRegisteredPlace();
      return data;
    } catch (error) {
      if (error.response.status === 401) {
        // alert("로그인이 필요합니다.");
      } else if (error && error.status === 404) {
        return [];
      } else {
        alert(
          "등록된 플레이스 조회에 실패했습니다. 잠시 후 다시 시도해 주세요."
        );
      }
      throw error;
    }
  };

  const handleDeleteRegisteredPlaces = async (registrationPlaceIds) => {
    console.log(
      "🚀 ~ handleDeleteRegisteredPlaces ~ registrationPlaceIds:",
      registrationPlaceIds
    );
    try {
      const data = await deleteRegisteredPlaces(registrationPlaceIds);
      console.log("🚀 ~ handleDeleteRegisteredPlaces ~ data:", data);
      return data;
    } catch (error) {
      console.log("🚀 ~ handleDeleteRegisteredPlaces ~ error:", error);
      if (error.response.status === 401) {
      } else {
        alert("플레이스 삭제에 실패했습니다. 잠시 후 다시 시도해 주세요.");
      }
      throw error;
    }
  };

  const handleGetRegisteredPlaceDetail = async ({ registrationPlaceId }) => {
    try {
      const data = await getRegisteredPlaceDetail({ registrationPlaceId });
      return data;
    } catch (error) {
      console.log("🚀 ~ handleGetRegisteredPlaceDetail ~ error:", error);
      if (error.response.status === 401) {
      } else {
        alert("플레이스 상세 조회에 실패했습니다. 잠시 후 다시 시도해 주세요.");
      }
      throw error;
    }
  };

  const handleGetRegisteredPlaceTableInfo = async ({
    placeId,
    registrationPlaceId,
  }) => {
    console.log(
      "🚀 ~ usePlaceHandlers ~ registrationPlaceId:",
      registrationPlaceId
    );
    console.log("🚀 ~ usePlaceHandlers ~ placeId:", placeId);
    try {
      const data = await getRegisteredPlaceTableInfo({
        placeId,
        registrationPlaceId,
      });
      console.log("🚀 ~ handleGetRegisteredPlaceTableInfo ~ data:", data);
      return data;
    } catch (error) {
      console.log("🚀 ~ handleGetRegisteredPlaceTableInfo ~ error:", error);
      if (error.response.status === 404) {
        return {};
      } else {
        alert(
          "플레이스 테이블 정보 조회에 실패했습니다. 잠시 후 다시 시도해 주세요."
        );
      }
      throw error;
    }
  };

  const handleToggleNotification = async (
    toggle,
    keywordName,
    registrationPlaceId
  ) => {
    console.log(
      "🚀 ~ handleToggleNotification ~ toggle, keywordName, placeId:",
      toggle,
      keywordName,
      registrationPlaceId
    );
    try {
      const data = await toggleNotification(
        toggle,
        keywordName,
        registrationPlaceId
      );
      console.log("🚀 ~ handleToggleNotification ~ data:", data);
      return data;
    } catch (error) {
      if (error.response.status === 409) {
        alert("등록 알림 수를 초과했습니다.");
      } else {
        alert("알림 설정에 실패했습니다. 잠시 후 다시 시도해 주세요.");
      }
      console.log("🚀 ~ handleToggleNotification ~ error:", error);
      throw error;
    }
  };

  const handleDeleteKeyword = async ({ registrationPlaceId, keyword }) => {
    try {
      console.log(
        "🚀 ~ handleDeleteKeyword ~ registrationPlaceId , keyword:",
        registrationPlaceId,
        keyword
      );
      const data = await deleteKeyword({ registrationPlaceId, keyword });
      console.log("🚀 ~ handleDeleteKeyword ~ data:", data);
      return data;
    } catch (error) {
      console.log("🚀 ~ handleDeleteKeyword ~ error:", error);
      alert("키워드 삭제에 실패했습니다. 잠시 후 다시 시도해 주세요.");
      throw error;
    }
  };

  const handleRegisterKeyword = async ({ keywordName, placeId, placeName }) => {
    try {
      const data = await registerKeyword({ keywordName, placeId, placeName });
      return data;
    } catch (error) {
      console.log("🚀 ~ handleRegisterKeyword ~ error:", error);
      if (error && error.response.status === 409) {
        console.log(
          "🚀 ~ handleRegisterKeyword  등록가능한 키워드 수를 초과 또는 중복된 키워드"
        );
      } else if (error && error.response.status === 404) {
        alert("구독 중인 멤버십이 없습니다. 구독 상태를 확인해 주세요");
      } else {
        alert("키워드 등록에 실패했습니다. 잠시 후 다시 시도해 주세요.");
      }
      throw error;
    }
  };

  return {
    handleSearchPlace,
    handleRegisterPlace,
    handleGetRegisteredPlace,
    handleDeleteRegisteredPlaces,
    handleGetRegisteredPlaceDetail,
    handleGetRegisteredPlaceTableInfo,
    handleToggleNotification,
    handleDeleteKeyword,
    handleRegisterKeyword,
  };
};
