//이미지
import loginStyle from "@pages/Login/Login.module.scss";
import LoginButtons from "@components/Login/LoginButtons";
import AdditionalLinks from "@components/Login/AdditionalLinks";
function LoginPage({ isHome = false }) {
  return (
    <div
      className={
        isHome
          ? `${loginStyle.loginContainer} ${loginStyle.home}`
          : loginStyle.loginContainer
      }
    >
      <div className={loginStyle.loginText}>
        지금 로그인하고 다양한 <br /> 기능을 바로 경험해보세요!
      </div>
      <div
        className={`${loginStyle.loginCommonContainer} ${loginStyle.loginItemContainer}`}
      >
        <LoginButtons />
        <AdditionalLinks />
      </div>
    </div>
  );
}

export default LoginPage;
