import styles from "@pages/Mypage/MyMembership/MyMembership.module.scss";
import { useAppNavigate } from "@hooks/navigate/navigate";
import { EXIT_REASON } from "@utils/constants/user/exitReason";
import { useUserHandler } from "@hooks/user/useUserHandler";
import ConfirmDialog from "@components/Common/Modal/Dialogs/ConfirmDialog";
import { useState } from "react";
import { useHandleLoginState } from "@hooks/user/useLogout";
import { useMembershipHandlers } from "@hooks/membership/useMembershipHandlers";
const ExitSurvey = () => {
  const { goToCompleteDeleteAccount } = useAppNavigate();
  const { handleExitUserReason } = useUserHandler();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { handleExitUser } = useMembershipHandlers();
  const { handleLogout } = useHandleLoginState();

  const handleExit = async () => {
    console.log("회원탈퇴");
    const selectedRadio = document.querySelector(
      'input[name="exit-survey"]:checked'
    );
    if (!selectedRadio) {
      alert("떠나시는 이유를 선택해주세요.");
      return;
    }
    setIsModalOpen(true);
  };

  const handleConfirm = async () => {
    setIsModalOpen(false);
    const selectedRadio = document.querySelector(
      'input[name="exit-survey"]:checked'
    );
    const index = parseInt(selectedRadio.id.split("-").pop(), 10);
    const reason = EXIT_REASON[index];
    try {
      await handleExitUserReason({ index, reason });
      await handleExitUser();
      handleLogout();
      goToCompleteDeleteAccount();
    } catch (error) {
      console.error("🚀 ~ handleConfirm ~ error:", error);
    }
  };

  const handleClose = () => {
    setIsModalOpen(false);
  };
  return (
    <div className={styles.exitSurveyBox}>
      <h1 className={styles.title}>회원 탈퇴</h1>
      <div className={styles.exitSurveyBox}>
        <h2 className={styles.subTitle}>떠나시는 이유가 있을까요?</h2>
        <div className={styles.line} />
        <div className={styles.exitReasonsBox}>
          {Object.entries(EXIT_REASON).map(([index, reason]) => (
            <div key={index} className={styles.reason}>
              <input
                type="radio"
                name="exit-survey"
                id={`exit-survey-${index}`}
              />
              <label htmlFor={`exit-survey-${index}`}>{reason}</label>
            </div>
          ))}
          <textarea
            className={styles.reasonInput}
            placeholder="직접입력(20자이상)"
          />
        </div>
      </div>
      <button className={styles.exitButton} onClick={handleExit}>
        회원탈퇴
      </button>

      <ConfirmDialog
        open={isModalOpen}
        onClose={handleClose}
        onConfirm={handleConfirm}
        title={
          <>
            탈퇴하시면 모든 데이터가 사라집니다. <br /> 정말로 탈퇴하시겠어요?
          </>
        }
        cancelText="취소"
        confirmText="탈퇴"
      />
    </div>
  );
};
export default ExitSurvey;
