export class MonthlyCountDTO {
  constructor({
    monthlyPcSearchCount = 0,
    monthlyMobileSearchCount = 0,
    monthlyBlogCount = 0,
  }) {
    this.monthlyPcSearchCount = parseInt(monthlyPcSearchCount) || 0;
    this.monthlyMobileSearchCount = parseInt(monthlyMobileSearchCount) || 0;
    this.monthlyBlogCount = parseInt(monthlyBlogCount) || 0;
  }

  // GET methods
  getMonthlyPcSearchCount() {
    return this.monthlyPcSearchCount;
  }

  getMonthlyMobileSearchCount() {
    return this.monthlyMobileSearchCount;
  }

  getMonthlyBlogCount() {
    return this.monthlyBlogCount;
  }

  // SET methods
  setMonthlyPcSearchCount(value) {
    this.monthlyPcSearchCount = value;
  }

  setMonthlyMobileSearchCount(value) {
    this.monthlyMobileSearchCount = value;
  }

  setMonthlyBlogCount(value) {
    this.monthlyBlogCount = value;
  }
}
