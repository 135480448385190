import loginStyle from "@components/Login/LoginStyle.module.scss";
import { Link } from "react-router-dom";
import URL_PATH from "@utils/constants/paths/routes";

function AdditionalLinks() {
  return (
    <div className={loginStyle.additionalLinksContainer}>
      <Link
        to={`${URL_PATH.findEmailPassword.replace(":isEmail", "true")}`}
        className={loginStyle.additionalLink}
      >
        이메일 찾기
      </Link>
      <div className={loginStyle.line}></div>
      <Link
        to={`${URL_PATH.findEmailPassword.replace(":isEmail", "false")}`}
        className={loginStyle.additionalLink}
      >
        비밀번호 찾기
      </Link>
      <div className={loginStyle.line}></div>
      <Link to={URL_PATH.signup} className={loginStyle.additionalLink}>
        회원가입
      </Link>
    </div>
  );
}

export default AdditionalLinks;
