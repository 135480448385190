import { useContext } from "react";
import { useDispatch } from "react-redux";
import { fetchMembershipInitial } from "@features/membership/membershipSlice";
import AuthContext from "@utils/contexts/AuthContext";

export const useHandleLoginState = () => {
  const dispatch = useDispatch();
  const { logout } = useContext(AuthContext);
  const handleLogout = () => {
    dispatch(fetchMembershipInitial());
    logout();
  };

  return { handleLogout };
};
