import {
  fetchMembershipSuccess,
  fetchMembershipFailure,
} from "@features/membership/membershipSlice";

export async function setMembershipData(data, dispatch) {
  console.log("🚀 ~ setMembershipData ~ data:", data);
  try {
    const subscriptionInfo = data.subscriptionInfo || {};
    console.log("🚀 ~ setMembershipData ~ subscriptionInfo:", subscriptionInfo);
    dispatch(
      fetchMembershipSuccess({
        resetSubscriptionType: data.resetSubscriptionType,
        subscriptionType: subscriptionInfo.type || null,
        isFreeTrial: data.isFreeTrial || null,
        startDate: subscriptionInfo.startDate || null,
        endDate: subscriptionInfo.endDate || null,
        userId: data.userId || null,
        name: data.name || null,
        email: data.email || null,
        phoneNumber: data.phoneNumber || null,
      })
    ); // 로딩 성공 액션 디스패치
  } catch (err) {
    dispatch(fetchMembershipFailure(err)); // 로딩 실패 액션 디스패치
  }
}

export const setMembershipDataKeyForUpgrade = (subscriptionType) => {
  if (!subscriptionType) {
    return "standard"; // 기본값 설정
  }
  switch (subscriptionType) {
    case "standard":
      return "premium";
    case "premium":
      return "premium";
    default:
      return "standard";
  }
};

export const setMembershipDataKey = (subscriptionType) => {
  console.log(
    "🚀 ~ setMembershipDataKey ~ subscriptionType:",
    subscriptionType
  );
  if (!subscriptionType) {
    return "standard"; // 기본값 설정
  }
  switch (subscriptionType) {
    case "free":
      return "standard";

    default:
      return subscriptionType;
  }
};
