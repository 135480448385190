import { useState } from "react";
import { useCountdownTimer } from "@hooks/common/useCountdownTimer";
import { useUserHandler } from "@hooks/user/useUserHandler";

import { VERIFICATION_TYPE } from "@utils/constants/api/verificationType";
import { useAppNavigate } from "@hooks/navigate/navigate";
import { createSessionStorage } from "@utils/webStorage/sessionStorage";
import { useLoginHandlers } from "@hooks/login/useLoginHandlers";

export const useAuthCode = (verificationType) => {
  const {
    handleSendAuthCode,
    handleSendAuthCodeForPhoneChange,
    handleCheckAuthCode,
    handleCheckAuthCodeForPhoneChange,
  } = useUserHandler();
  // 인증번호 전송 횟수
  const [resendCount, setResendCount] = useState(0);
  // 인증번호 전송 여부
  const [isPhoneAuthSent, setPhoneAuthSent] = useState(false);
  const { time, startTimer, clearTimer } = useCountdownTimer(240);
  const [isCheckValid, setIsCheckValid] = useState(null);
  const {
    goToFindEmailComplete,
    goToFindPasswordComplete,
    goToSignupComplete,
  } = useAppNavigate();
  const { handleAddPhoneNumber } = useLoginHandlers();

  const sendAuthCode = async ({
    name = null,
    email = null,
    phoneNumber,
    newPhoneNumber = null,
  }) => {
    if (resendCount < 4) {
      setIsCheckValid(null);
      setPhoneAuthSent(true);
      setResendCount(resendCount + 1);
      startTimer();

      try {
        if (VERIFICATION_TYPE.PHONE_CHANGE === verificationType) {
          await handleSendAuthCodeForPhoneChange({
            phoneNumber,
            newPhoneNumber,
            verificationType,
          });
        } else {
          await handleSendAuthCode({
            name,
            email,
            phoneNumber,
            verificationType,
          });
        }
      } catch (error) {
        console.error("Error sending auth code:", error);
        throw error;
      }
    }
  };

  const sendAuthCodeForAddPhoneNumber = async ({
    email,
    name,
    phoneNumber,
    socialProvider,
    socialId,
    refreshToken,
    birthDate,
  }) => {
    try {
      await handleAddPhoneNumber({
        email,
        name,
        phoneNumber,
        socialProvider,
        socialId,
        refreshToken,
        birthDate,
      });
      goToSignupComplete();
    } catch (error) {
      console.error("Error sending auth code:", error);
      throw error;
    }
  };

  const checkAuthCode = async ({
    name = null,
    phoneNumber,
    code,
    email = null,
    newPhoneNumber = null,
  }) => {
    try {
      if (VERIFICATION_TYPE.PHONE_CHANGE === verificationType) {
        const data = await handleCheckAuthCodeForPhoneChange({
          phoneNumber,
          newPhoneNumber,
          verificationType,
          verificationCode: code,
        });
        clearTimer();
        setIsCheckValid(true);
      } else {
        const data = await handleCheckAuthCode({
          name,
          phoneNumber,
          email,
          verificationType,
          verificationCode: code,
        });
        clearTimer();
        setIsCheckValid(true);
        goToPageAfterCheckAuthCode(data);
      }
    } catch (error) {
      console.error("Error checking auth code:", error);
      if (error && (error.status === 401 || error.response.status === 404)) {
        setIsCheckValid(false);
        clearTimer();
      } else {
        setIsCheckValid(null);
      }
    }
  };

  const goToPageAfterCheckAuthCode = (data) => {
    if (VERIFICATION_TYPE.EMAIL_SEARCH === verificationType) {
      createSessionStorage("email", data.email);
      goToFindEmailComplete();
    } else if (VERIFICATION_TYPE.PW_SEARCH === verificationType) {
      goToFindPasswordComplete();
    }
  };

  return {
    resendCount,
    isPhoneAuthSent,
    time,
    isCheckValid,
    sendAuthCode,
    sendAuthCodeForAddPhoneNumber,
    checkAuthCode,
  };
};
