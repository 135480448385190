import { useDispatch } from "react-redux";
import { checkEmailDuplication } from "@utils/api/user/user";
import {
  signupByEmail,
  sendAuthCode,
  sendAuthCodeForPhoneChange,
  checkAuthCode,
  checkAuthCodeForPhoneChange,
  changeUserInfo,
  exitUserReason,
} from "@utils/api/user/user";
import { useAppNavigate } from "@hooks/navigate/navigate";
export const useUserHandler = () => {
  const dispatch = useDispatch();
  const { goToSignupComplete } = useAppNavigate();

  const handleCheckEmailDuplication = async (email) => {
    try {
      console.log("🚀 ~ handleCheckEmailDuplication ~ email:", email);
      const response = await checkEmailDuplication(email);
      return response.data;
    } catch (error) {
      console.error("🚀 ~ handleCheckEmailDuplication ~ error:", error);
      if (error.response && error.response.status === 400) {
        alert("이메일이 누락되었거나 형식이 올바르지 않습니다.");
      }
      throw error;
    }
  };

  const handleSignupByEmail = async (signupData) => {
    try {
      await signupByEmail(signupData);
      goToSignupComplete();
    } catch (error) {
      console.error("🚀 ~ handleSignupByEmail ~ error:", error);
      if (error.customTitleMessage) {
        alert(`${error.customTitleMessage}\n${error.customSubMessage}`);
      } else if (error.response && error.response.status === 400) {
        // 400 에러일 경우, 필수 입력 항목 확인 요청
        alert("필수 입력 항목을 확인하고 다시 시도해주세요.");
      } else {
        console.error("회원가입 중 오류 발생", error);
        alert("회원가입 중 오류 발생");
      }
    }
  };

  const handleSendAuthCode = async ({
    name = null,
    email = null,
    phoneNumber,
    verificationType,
  }) => {
    try {
      await sendAuthCode({
        name,
        email,
        phoneNumber,
        verificationType,
      });
    } catch (error) {
      console.error("🚀 ~ handleSendAuthCode ~ error:", error);
      if (error.customTitleMessage) {
        alert(`${error.customTitleMessage}\n${error.customSubMessage}`);
      } else if (error.response && error.response.status === 400) {
        alert("필수 입력 항목을 확인하고 다시 시도해주세요.");
      } else if (error && (error.status === 409 || error.status === 404)) {
        console.log("handleSendAuthCode 409 or 404");
      } else {
        console.error("회원가입 중 오류 발생", error);
        alert("회원가입 중 오류 발생");
      }
      throw error;
    }
  };

  const handleCheckAuthCode = async ({
    phoneNumber,
    verificationType,
    verificationCode,
    name = null,
    email = null,
  }) => {
    try {
      const response = await checkAuthCode({
        phoneNumber,
        verificationType,
        verificationCode,
        name,
        email,
      });
      console.log("response", response);
      return response;
    } catch (error) {
      console.error("🚀 ~ handleCheckAuthCode ~ error:", error);
      if (error.response && error.response.status === 400) {
        alert("전화번호 또는 인증코드가 누락되었습니다.");
      } else if (error.response && error.response.status === 404) {
        // alert("제공된 전화번호에 대한 인증 정보를 찾을 수 없습니다.");
      } else if (error.response && error.response.status === 401) {
      } else if (error.response && error.response.status === 429) {
        alert(
          "인증 코드 검증 시도가 너무 많습니다. 잠시 후 다시 시도해 주세요"
        );
      }
      throw error;
    }
  };

  const handleSendAuthCodeForPhoneChange = async ({
    phoneNumber,
    newPhoneNumber,
    verificationType,
  }) => {
    try {
      await sendAuthCodeForPhoneChange({
        phoneNumber,
        newPhoneNumber,
        verificationType,
      });
    } catch (error) {
      console.error("🚀 ~ handleSendAuthCodeForPhoneChange ~ error:", error);
      if (error.customTitleMessage) {
        alert(`${error.customTitleMessage}\n${error.customSubMessage}`);
      } else if (error.response && error.response.status === 400) {
        alert("필수 입력 항목을 확인하고 다시 시도해주세요.");
      }
      throw error;
    }
  };

  const handleCheckAuthCodeForPhoneChange = async ({
    phoneNumber,
    newPhoneNumber,
    verificationType,
    verificationCode,
  }) => {
    try {
      const data = await checkAuthCodeForPhoneChange({
        phoneNumber,
        newPhoneNumber,
        verificationType,
        verificationCode,
      });
      console.log("🚀 ~ useUserHandler ~ data:", data);
      return data;
    } catch (error) {
      console.error("🚀 ~ handleCheckAuthCodeForPhoneChange ~ error:", error);
      if (error.customTitleMessage) {
        alert(`${error.customTitleMessage}\n${error.customSubMessage}`);
      } else if (error && error.status === 429) {
        alert(
          "인증 코드 검증 시도가 너무 많습니다. 잠시 후 다시 시도해 주세요"
        );
      } else {
        alert(
          "인증코드가 누락되었거나 제공된 전화번호에 대한 인증 정보를 찾을 수 없습니다."
        );
      }
      throw error;
    }
  };

  const handleChangeUserInfo = async ({
    currentPassword = null,
    newPassword = null,
    phoneNumber = null,
  }) => {
    try {
      const data = await changeUserInfo({
        currentPassword,
        newPassword,
        phoneNumber,
      });
      console.log("🚀 ~ useUserHandler ~ data:", data);
      return data;
    } catch (error) {
      console.error("🚀 ~ handleChangeUserInfo ~ error:", error);
      if (error && error.status === 404) {
        throw error;
      } else {
        alert("회원정보 변경 중 오류가 발생했습니다. 다시 시도해주세요.");
      }
      throw error;
    }
  };

  const handleExitUserReason = async ({ index, reason }) => {
    try {
      await exitUserReason({ index, reason });
    } catch (error) {
      console.error("🚀 ~ handleExitUserReason ~ error:", error);
      throw error;
    }
  };

  return {
    handleCheckEmailDuplication,
    handleSignupByEmail,
    handleSendAuthCode,
    handleCheckAuthCode,
    handleSendAuthCodeForPhoneChange,
    handleCheckAuthCodeForPhoneChange,
    handleChangeUserInfo,
    handleExitUserReason,
  };
};
