import style from "@pages/DetailRegisteredPlace/DetailRegisteredPlace.module.scss";
import RegisterFormContainer from "@components/DetailRegisteredPlace/RegisterFormContainer";
import PlaceInfoFetcher from "@components/DetailRegisteredPlace/PlaceInfoFetcher";
import { useState } from "react";
const DetailView = () => {
  const [registerPlaceIdFromChild, setRegisterPlaceIdFromChild] = useState(""); // PlaceInfoFetcher에서 데이터를 받아옴

  return (
    <div className={style.detailView}>
      <h1>상세 플레이스</h1>
      <div className={style.placeInfoFetcher}>
        <PlaceInfoFetcher setRegisterPlaceId={setRegisterPlaceIdFromChild} />
      </div>
      <div className={style.divider}></div>

      {registerPlaceIdFromChild && ( // PlaceInfoFetcher에서 데이터를 받은 후에만 표시
        <div className={style.registerFormContainer}>
          <RegisterFormContainer
            registrationPlaceId={registerPlaceIdFromChild}
          />
        </div>
      )}
    </div>
  );
};

export default DetailView;
