import { MonthlyCountDTO } from "@dto/place/MonthlyCountDTO";
export class KeywordDetailDTO {
  constructor({
    placeInfo = { placeId: "", placeName: "" },
    placeRankList = [],
    keywordRankList = [],
  }) {
    this.placeInfo = new PlaceInfo(placeInfo);
    this.placeRankList = placeRankList.map((item) => new PlaceRank(item));
    this.keywordRankList = keywordRankList.map((item) => new KeywordRank(item));
  }

  // GET methods
  getPlaceInfo() {
    return this.placeInfo;
  }

  getPlaceRankList() {
    return this.placeRankList;
  }

  getKeywordRankList() {
    return this.keywordRankList;
  }

  // SET methods
  setPlaceInfo(value) {
    this.placeInfo = new PlaceInfo(value);
  }

  setPlaceRankList(value) {
    this.placeRankList = value.map((item) => new PlaceRank(item));
  }

  setKeywordRankList(value) {
    this.keywordRankList = value.map((item) => new KeywordRank(item));
  }
}

export class PlaceInfo {
  constructor({ placeId = "", placeName = "" }) {
    this.placeId = placeId;
    this.placeName = placeName;
  }

  // GET methods
  getPlaceId() {
    return this.placeId;
  }

  getPlaceName() {
    return this.placeName;
  }

  // SET methods
  setPlaceId(value) {
    this.placeId = value;
  }

  setPlaceName(value) {
    this.placeName = value;
  }
}

export class PlaceRank {
  constructor({
    date = "",
    saveCount = "0",
    visitorReviewCount = 0,
    blogReviewCount = 0,
  }) {
    this.date = date;
    this.saveCount = saveCount;
    this.formatSaveCount = this.formatSaveCount(saveCount);
    this.visitorReviewCount = parseInt(visitorReviewCount, 10); // int를 Int로 변환
    this.blogReviewCount = parseInt(blogReviewCount, 10); // int를 Int로 변환
  }

  formatSaveCount(saveCount) {
    // '+' 기호 제거
    const cleanSaveCount = saveCount.replace(/[,+]/g, "");
    console.log(
      "🚀 ~ PlaceRank ~ formatSaveCount ~ cleanSaveCount:",
      cleanSaveCount
    );

    const num = parseInt(cleanSaveCount, 10);
    console.log("🚀 ~ PlaceRank ~ formatSaveCount ~ num:", num);
    if (num >= 10000) {
      return (num / 10000).toFixed(1) + "만";
    } else if (num >= 1000) {
      return (num / 1000).toFixed(1) + "천";
    }
    return num.toString();
  }

  getFormatSaveCount() {
    return this.formatSaveCount;
  }

  // GET methods
  getDate() {
    return this.date;
  }

  getSaveCount() {
    return this.saveCount;
  }

  getReviewCount() {
    return this.visitorReviewCount;
  }

  getBlogCount() {
    return this.blogReviewCount;
  }

  // SET methods
  setDate(value) {
    this.date = value;
  }

  setSaveCount(value) {
    this.saveCount = value;
  }

  setReviewCount(value) {
    this.visitorReviewCount = parseInt(value, 10); // int를 Int로 변환
  }

  setBlogCount(value) {
    this.blogReviewCount = parseInt(value, 10); // int를 Int로 변환
  }
}

export class KeywordRank {
  constructor({
    keywordName = "",
    dateRankList = [],
    notification = false,
    monthlyCount = {},
  }) {
    this.keywordName = keywordName;
    this.dateRankList = dateRankList.map((item) => new DateRank(item));
    this.notification = notification;
    this.monthlyCount = new MonthlyCountDTO(monthlyCount);
  }

  // GET methods
  getKeywordName() {
    return this.keywordName;
  }

  getDateRankList() {
    return this.dateRankList;
  }

  getNotification() {
    return this.notification;
  }

  getMonthlyCount() {
    return this.monthlyCount;
  }

  // SET methods
  setKeywordName(value) {
    this.keywordName = value;
  }

  setDateRankList(value) {
    this.dateRankList = value.map((item) => new DateRank(item));
  }

  setNotification(value) {
    this.notification = value;
  }

  setMonthlyCount(value) {
    this.monthlyCount = new MonthlyCountDTO(value);
  }
}

export class DateRank {
  constructor({ date = "", rank = 0 }) {
    this.date = date; // 원본 날짜 저장
    this.formatDate = this.formatDate(this.date); // 변환된 날짜 저장
    this.rank = parseInt(rank, 10); // int를 Int로 변환
  }

  formatDate(date) {
    if (!date || !date.includes("-")) {
      return date;
    }
    const dateObj = new Date(date);
    const month = String(dateObj.getMonth() + 1).padStart(2, "0");
    const day = String(dateObj.getDate()).padStart(2, "0");
    const weekdays = ["일", "월", "화", "수", "목", "금", "토"];
    const weekday = weekdays[dateObj.getDay()];

    return `${month}/${day}(${weekday})`;
  }

  // GET methods
  getFormatDate() {
    return this.formatDate;
  }

  getDate() {
    return this.date;
  }

  getRank() {
    return this.rank;
  }

  // SET methods
  setDate(value) {
    this.date = value;
  }

  setRank(value) {
    this.rank = parseInt(value, 10); // int를 Int로 변환
  }

  setFormatDate(value) {
    this.formatDate = value;
  }
}
