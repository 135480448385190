import { defaultAxiosInstance } from "@utils/api/axios/defaultInstance";
import { loginAxiosInstance } from "@utils/api/axios/loginInstance";
import API_PATH from "@utils/constants/api/apiPath";
import { API_URL } from "@utils/constants/api/api";

// 쿠키 설정 예시
export const autoLogin = async () => {
  try {
    console.log("🚀 ~ autoLogin ~ autoLogin:");
    const response = await defaultAxiosInstance.post(API_PATH.autoLogin);
    console.log("🚀 ~ autoLogin ~ response:", response);
    return response.data; // 성공 시, 사용자 정보와 새로운 토큰 반환
  } catch (error) {
    console.log("🚀 ~ autoLogin ~ error:", error);
    throw new Error("자동로그인 실패");
  }
};

export const logoutApi = async () => {
  try {
    console.info("logoutApi 호출됨");
    await defaultAxiosInstance.post(API_PATH.logout);
  } catch (error) {
    console.error("🚀 ~ logoutApi ~ error:", error);
    throw error;
  }
};

export const emailLogin = async ({ email, password }) => {
  console.log("🚀 ~ emailLogin ~ email, password:", email, password);
  try {
    const response = await loginAxiosInstance.post(API_PATH.emailLogin, {
      email: email,
      password: password,
    });
    return response.data;
  } catch (error) {
    console.error("🚀 ~ emailLogin ~ error:", error);
    throw error;
  }
};

export const kakaoLogin = async () => {
  console.log("🚀 ~ kakaoLogin ~ kakaoLogin:");
  try {
    const response = await loginAxiosInstance.post(API_PATH.kakaoLogin);
    return response.data;
  } catch (error) {
    console.error("🚀 ~ kakaoLogin ~ error:", error);
    throw error;
  }
};

export const naverLogin = async () => {
  console.log("🚀 ~ naverLogin ~ naverLogin:");
  try {
    const response = await loginAxiosInstance.post(API_PATH.naverLogin);
    return response.data;
  } catch (error) {
    console.error("🚀 ~ naverLogin ~ error:", error);
    throw error;
  }
};

export const addPhoneNumber = async ({
  email,
  name,
  phoneNumber,
  socialProvider,
  socialId,
  refreshToken,
  birthDate,
}) => {
  try {
    await loginAxiosInstance.post(API_PATH.addPhoneNumber, {
      email,
      name,
      phoneNumber,
      socialProvider,
      socialId,
      refreshToken,
      birthDate,
    });
  } catch (error) {
    console.error("🚀 ~ addPhoneNumber ~ error:", error);
    throw error;
  }
};

export const handleKakaoLogin = () => {
  try {
    const KAKAO_CLIENT_ID = process.env.REACT_APP_KAKAO_CLIENT_ID;
    const REDIRECT_URI = `${API_URL}${API_PATH.kakaoLoginCallback}`;
    const authUrl = `https://kauth.kakao.com/oauth/authorize?response_type=code&client_id=${encodeURIComponent(
      KAKAO_CLIENT_ID
    )}&redirect_uri=${encodeURIComponent(REDIRECT_URI)}`;
    window.location.href = authUrl;
  } catch (error) {
    alert("카카오 로그인 중 오류 발생");
    console.error("🚀 ~ handleKakaoLogin ~ error:", error);
  }
};
