import URL_PATH from "@utils/constants/paths/routes";
import { API_ERROR_TYPE } from "@utils/constants/api/errorType";
import { refreshAccessToken } from "@utils/api/user/user";

/**
 * refreshToken 만료된 경우 -
 * 인증번호 잘못된 경우 -
 * accessToken 만료된 경우 -
 * refreshToken 재발급 받아야 하는 경우 홈화면(로그인 유도)으로 이동
 * @param {*} error
 * @param {*} axiosInstance
 * @returns
 */

export const handle401Error = async (error, axiosInstance) => {
  console.log("🚀 ~ handle401Error ~ error:", error);
  const errorType = error.response.data.type; // 중복 제거를 위한 변수 선언
  console.log("🚀 ~ handle401Error ~ errorType:", errorType);
  if (
    API_ERROR_TYPE.REFRESH_TOKEN_INVALID === errorType ||
    API_ERROR_TYPE.REFRESH_TOKEN_EXPIRED === errorType ||
    API_ERROR_TYPE.REFRESH_TOKEN_FAILED === errorType ||
    API_ERROR_TYPE.REFRESH_TOKEN_HEADER_MISSING === errorType
  ) {
    // 현재 페이지가 홈화면이거나 서버 에러 페이지인 경우 이동하지 않음
    const currentPath = window.location.pathname;
    if (
      currentPath === URL_PATH.serverError ||
      currentPath === URL_PATH.notFound ||
      currentPath === URL_PATH.home
    ) {
      return;
    }
    window.location.replace(URL_PATH.home);
  } else if (
    API_ERROR_TYPE.ACCESS_TOKEN_INVALID === errorType ||
    API_ERROR_TYPE.ACCESS_TOKEN_EXPIRED === errorType ||
    API_ERROR_TYPE.ACCESS_TOKEN_FAILED === errorType ||
    API_ERROR_TYPE.ACCESS_TOKEN_HEADER_MISSING === errorType
  ) {
    // accessToken 재발급 받아야 하는 경우
    const originalRequest = error.config;
    console.log(
      "🚀 ~ handle401Error ~ originalRequest._retry:",
      originalRequest._retry
    );
    if (!originalRequest._retry) {
      originalRequest._retry = true;

      try {
        await refreshAccessToken(); // accessToken 재발급
        return axiosInstance(originalRequest); // 원래 요청 재시도
      } catch (refreshError) {
        console.error("🚀 ~ refreshAccessToken 발급 에러:", refreshError);
        return Promise.reject(refreshError);
      }
    }
  }
};
