import style from "@components/RegisteredPlace/RegisteredPlaceStyle.module.scss";
import RegisterStoreCard from "@components/RegisteredPlace/registerStoreCard";
import { useState, useEffect } from "react";
import { usePlaceHandlers } from "@hooks/place/usePlaceHandlers";
import Loader from "@components/Common/Loader/Loader";
import { useSelector } from "react-redux";
import {
  getAvailablePlaceCount,
  getKeywordUsage,
} from "@utils/functions/place/place";
import ConfirmDialog from "@components/Common/Modal/Dialogs/ConfirmDialog";
import textAnimation from "@assets/styles/textAnimation.module.scss";

const SearchableCardList = () => {
  const { handleGetRegisteredPlace, handleDeleteRegisteredPlaces } =
    usePlaceHandlers();
  const [isLoading, setIsLoading] = useState(true);
  const membershipData = useSelector((state) => state.membership);
  const [storeData, setStoreData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedIds, setSelectedIds] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showCheckBox, setShowCheckBox] = useState(false);

  useEffect(() => {
    getAndSetRegisteredPlace();
  }, []);

  const getAndSetRegisteredPlace = async () => {
    try {
      const data = await handleGetRegisteredPlace();
      console.log("🚀 ~ getAndSetRegisteredPlace ~ data:", data);
      setStoreData(data);
      setIsLoading(false);
    } catch (error) {
      if (error && error.status === 404) {
        setStoreData([]);
        setIsLoading(false);
      }
      console.log("🚀 ~ getAndSetRegisteredPlace ~ error:", error);
    }
  };

  const handleDeletePlace = (registrationPlaceIds) => {
    setStoreData((prevPlaces) =>
      prevPlaces.filter(
        (place) =>
          !registrationPlaceIds.includes(place.placeInfo.registrationPlaceId)
      )
    );
  };

  const toggleSelectId = (id) => {
    setSelectedIds((prevSelectedIds) => {
      if (prevSelectedIds.includes(id)) {
        return prevSelectedIds.filter((selectedId) => selectedId !== id);
      } else {
        return [...prevSelectedIds, id];
      }
    });
  };

  const deleteSelectedPlaces = async () => {
    try {
      await handleDeleteRegisteredPlaces(selectedIds);
      handleDeletePlace(selectedIds);
      setSelectedIds([]);
      setIsModalOpen(false);
    } catch (error) {
      console.log("🚀 ~ deleteSelectedPlaces ~ error:", error);
    }
  };

  const toggleCheckBoxVisibility = () => {
    setShowCheckBox((prev) => !prev);
  };

  const filteredStoreData = storeData.filter((store) =>
    store.placeInfo.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const { totalKeywordsAvailable, remainingKeywords } = getKeywordUsage(
    membershipData.subscriptionType,
    storeData
  );

  if (isLoading) {
    return (
      <div className={style.searchableCardList}>
        <Loader />
      </div>
    );
  }

  return (
    <div className={style.searchableCardList}>
      <div className={style.searchableCardListHeader}>
        <div className={style.availableInfoBox}>
          <span className={style.availablePlaceCount}>
            등록 가능한 플레이스 수 :{" "}
            {getAvailablePlaceCount(
              membershipData.subscriptionType,
              storeData.length || 0
            )}
          </span>

          <span className={style.availablePlaceCount}>
            등록 가능한 키워드 수 :{" "}
            <span className={style.redText}>{remainingKeywords || 0}</span>/
            {totalKeywordsAvailable || 0}
          </span>
        </div>

        <div className={style.searchInputBox}>
          <input
            type="text"
            placeholder="검색어를 입력하세요"
            onChange={(e) => {
              setSearchTerm(e.target.value);
            }}
          />
          <button className={style.searchButton}></button>
        </div>
      </div>

      <div className={`${textAnimation.fadeIn} ${style.cardList}`}>
        {filteredStoreData.length === 0 ? (
          <div className={style.noPlacesMessage}>
            등록한 플레이스가 없습니다.
          </div>
        ) : (
          filteredStoreData.map((store) => (
            <RegisterStoreCard
              key={store.placeInfo.placeId}
              placeInfo={store.placeInfo}
              keywordRankArray={store.keywordRankArray}
              toggleSelectId={toggleSelectId}
              isSelected={selectedIds.includes(
                store.placeInfo.registrationPlaceId
              )}
              showCheckBox={showCheckBox}
            />
          ))
        )}
      </div>

      {filteredStoreData.length > 0 && (
        <div className={style.buttonWrapper}>
          <button
            id="deleteButton"
            className={`${style.deleteButton} ${
              showCheckBox === false ? style.visible : ""
            }`}
            onClick={() => {
              toggleCheckBoxVisibility();
            }}
          >
            삭제
          </button>

          <button
            id="selectDeleteButton"
            onClick={() => {
              setIsModalOpen(true);
            }}
            className={`${style.deleteButton} ${
              showCheckBox === true ? style.visible : ""
            } ${selectedIds.length > 0 ? style.selectDelete : ""}`}
            disabled={selectedIds.length === 0}
          >
            선택삭제
          </button>
        </div>
      )}

      {isModalOpen && (
        <ConfirmDialog
          open={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          onConfirm={deleteSelectedPlaces}
          title={
            <span>
              선택한 플레이스 내역이 영구 삭제됩니다.
              <br />
              정말 삭제하시겠습니까?
            </span>
          }
          cancelText="취소"
          confirmText="삭제"
        />
      )}
    </div>
  );
};

export default SearchableCardList;
