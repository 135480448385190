import style from "@components/RankingSearch/RankingSearchStyle.module.scss";
import PropTypes from "prop-types";
import { MonthlyCountDTO } from "@dto/place/MonthlyCountDTO";
import { formatDateToYMD } from "@utils/functions/format/date";

const KeywordCountBox = ({ isNoData, monthlyCount }) => {
  const endDate = formatDateToYMD(new Date());
  const startDate = formatDateToYMD(
    new Date(new Date().setMonth(new Date().getMonth() - 1))
  );
  return (
    <div className={style.keywordCountBoxContainer}>
      {!isNoData ? (
        <>
          <span className={style.dateRange}>
            {startDate} ~ {endDate} 키워드 검색결과
          </span>

          <div className={style.keywordCountBox}>
            <div className={style.monthlySearchCount}>
              <span>월간 검색수</span>
              <span className={style.count}>
                {monthlyCount.getMonthlyMobileSearchCount() +
                  monthlyCount.getMonthlyPcSearchCount() || "-"}
              </span>
            </div>
            <div className={style.divider}></div>
            <div className={style.monthlyBlogCount}>
              <span>월간 블로그 발행량</span>
              <span className={style.count}>
                {monthlyCount.getMonthlyBlogCount() || "-"}
              </span>
            </div>
          </div>
        </>
      ) : (
        <div className={style.noDataKeywordCountBox}>
          해당 키워드 정보가 존재하지 않습니다.
        </div>
      )}
    </div>
  );
};

KeywordCountBox.propTypes = {
  isNoData: PropTypes.bool.isRequired,
  monthlyCount: PropTypes.instanceOf(MonthlyCountDTO).isRequired,
};

export default KeywordCountBox;
