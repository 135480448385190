// src/components/UI/Input.js
import MembershipStyle from "@components/Mypage/AccountSettings/AccountStyle.module.scss";
import PropTypes from "prop-types";

const Input = ({
  label,
  type = "text",
  name,
  value,
  onChange,
  placeholder,
  pattern,
  required = false,
  disabled = false,
  maxLength,
  autoComplete = "off",
  error,
  check,
  onBlur,
}) => {
  console.log("🚀 ~ value:", value);
  return (
    <div className={MembershipStyle.inputContainer}>
      {label && <label htmlFor={name}>{label}</label>}
      <div className={MembershipStyle.inputBox}>
        <input
          type={type}
          name={name}
          id={name}
          value={value}
          onChange={onChange}
          pattern={pattern}
          className={` ${error ? MembershipStyle.errorBorder : ""}`}
          placeholder={placeholder}
          required={required}
          disabled={disabled}
          maxLength={maxLength}
          autoComplete={autoComplete}
          onBlur={onBlur}
        />

        {error && <p className={MembershipStyle.errorMessage}>{error}</p>}
        {check && <p className={MembershipStyle.checkMessage}>{check}</p>}
      </div>
    </div>
  );
};

Input.propTypes = {
  label: PropTypes.string,
  type: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  pattern: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
};
export default Input;
