export const EXIT_REASON = {
  1: "가격이 부담 돼요.",
  2: "서비스가 마음에 들지 않아요.",
  3: "오류가 있어요.",
  4: "자주 사용하지 않아요.",
  5: "기능이 부족해요.",
  6: "도움이 되지 않아요.",
  7: "계정을 변경하려고 해요.",
  8: "기타",
};
