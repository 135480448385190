import { API_URL } from "@utils/constants/api/api";
import axios from "axios";
import { handle500Error } from "@utils/api/axios/handle500Errors";
import { setAxiosRetry } from "@utils/api/axios/axiosRetry";

export const loginAxiosInstance = axios.create({
  //config 기본값 설정
  baseURL: API_URL,
  headers: {
    "Content-Type": "application/json",
  },
  withCredentials: true, // 쿠키를 포함하여 요청
});

// 요청 인터셉터 추가하기
loginAxiosInstance.interceptors.request.use(
  function (config) {
    // 요청이 전달되기 전에 작업 수행
    console.log("🚀 ~ 요청 인터셉터 ~ config:", config);
    return config;
  },
  function (error) {
    // 요청 오류가 있는 작업 수행
    console.error("🚀 ~ 요청 인터셉터 에러:", error);
    return Promise.reject(error);
  }
);

// 응답 인터셉터 추가하기
loginAxiosInstance.interceptors.response.use(
  function (response) {
    // 2xx 범위에 있는 상태 코드는 이 함수를 트리거 합니다.
    console.log("🚀 ~ 응답 인터셉터 ~ response:", response);

    return response;
  },
  function (error) {
    if (error.response) {
      switch (error.response.status) {
        case 500:
          handle500Error(error);
          break;
      }
      return Promise.reject(error); // 에러를 호출한 쪽으로 전파
    } else {
      if (error.message.includes("Network Error")) {
        console.log("인터넷 연결 불안정");
        error.customTitleMessage = "연결 상태를 확인해주세요";
        error.customSubMessage =
          "인터넷 연결이 원활하지 않습니다.\n연결 상태를 확인해주세요. \n문제가 지속되면 관리자에게 문의해주세요.";
        console.log("🚀 ~ 응답 인터셉터 에러 ~ error:", error.customMessage);
      } else if (error.code === "ECONNABORTED") {
        console.log("요청 처리 시간이 초과되었습니다.");
        error.customTitleMessage = "요청이 지연되고 있습니다.";
        error.customSubMessage =
          "서버 응답 시간이 초과되었습니다.\n잠시 후 다시 시도해주세요. \n문제가 지속되면 관리자에게 문의해주세요.";
      } else {
        console.log("요청 처리 중 문제가 발생했습니다.");
        error.customTitleMessage = "요청 처리 중 오류가 발생했습니다.";
        error.customSubMessage =
          "서버와의 통신 중 문제가 발생했습니다.\n잠시 후 다시 시도해주세요. \n문제가 지속되면 관리자에게 문의해주세요.";
      }

      console.log("🚀 ~ 응답 인터셉터 에러 ~ error:", error);
      return Promise.reject(error);
    }
  }
);

setAxiosRetry(loginAxiosInstance);
