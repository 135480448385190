import findEmailPasswordStyles from "@components/FindEmailPassword/FindEmailPasswordStyle.module.scss";

const GuideTextContainer = ({ title, subtitle }) => {
  return (
    <div className={findEmailPasswordStyles.guideTextContainer}>
      <h1 className={findEmailPasswordStyles.title}>{title}</h1>
      {subtitle && (
        <p className={findEmailPasswordStyles.subtitle}>{subtitle}</p>
      )}
    </div>
  );
};

export default GuideTextContainer;
