import loginStyle from "@components/Login/LoginStyle.module.scss";
import { handleKakaoLogin } from "@utils/api/login/login";
function SimpleLoginButtons() {
  return (
    <div className={loginStyle.simpleLoginContainer}>
      <div className={loginStyle.simpleLoginButtonsContainer}>
        <div className={loginStyle.divisionLine}></div>
        <div className={loginStyle.simpleLoginText}>SNS 간편 로그인</div>
        <div className={loginStyle.divisionLine}></div>
      </div>
      <div className={loginStyle.circleIconContainer}>
        <button
          className={loginStyle.kakaoLoginCircleButton}
          onClick={handleKakaoLogin}
        ></button>
      </div>
    </div>
  );
}

export default SimpleLoginButtons;
