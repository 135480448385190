import findEmailPasswordStyles from "@pages/FindEmailPassword/FindEmailPassword.module.scss"; // 스타일 시트 임포트
import GuideTextContainer from "@components/FindEmailPassword/GuideTextContainer";
import FindEmailCompleteButtons from "@components/FindEmailPassword/FindEmailCompleteButtons";
import sendEmail from "@assets/images/send_email.png";

const FindPasswordComplete = () => {
  return (
    <div className={findEmailPasswordStyles.findEmailPasswordContainer}>
      <GuideTextContainer title="이메일 / 비밀번호 찾기" />
      <div className={findEmailPasswordStyles.findPasswordCompleteBox}>
        <img
          src={sendEmail}
          alt="sendEmail"
          className={findEmailPasswordStyles.sendEmailIcon}
        />
        <h2 className={findEmailPasswordStyles.guideText}>
          초기화된 비밀번호가 이메일로 발송되었습니다.
        </h2>

        <div
          className={findEmailPasswordStyles.findEmailButtonComponentWrapper}
        >
          <FindEmailCompleteButtons isFindPasswordComplete={true} />
        </div>
      </div>
    </div>
  );
};

export default FindPasswordComplete;
