import styles from "@components/Home/HomeStyle.module.scss";
import placeImage from "@assets/images/home_place_mock_up.png";

const FourthSlideItem = () => {
  return (
    <div className={styles.fourthSlideBox}>
      <section className={styles.fourthSlideTextarea}>
        <span className={styles.fourthSlideTitle}>
          등록한 플레이스의 순위를 한번에 확인해보세요!
        </span>
      </section>

      <section className={styles.fourthSlideImage}>
        <img src={placeImage} alt="placeImage" />
      </section>
    </div>
  );
};

export default FourthSlideItem;
