import styles from "@components/RankingSearch/RankingSearchStyle.module.scss";
import RankingSearchInput from "@components/RankingSearch/RankingSearchInput";
import { useState } from "react";
import { ReactComponent as SearchIcon } from "@icons/search_icon.svg"; // SVG 파일을 리액트 컴포넌트로 가져옵니다.
import { useAppNavigate } from "@hooks/navigate/navigate";
import { VALIDATIONS } from "@utils/constants/validation/fieldValidations";
import SingleConfirmModal from "@components/Common/Modal/Dialogs/SingleConfirmModal";
import textAnimation from "@assets/styles/textAnimation.module.scss";
import RANKING_SEARCH_TYPE from "@utils/constants/place/rangkintSearch";
import { useParams } from "react-router-dom";

const RankingSearchForm = () => {
  const { keyword, rankingSearchType } = useParams();
  const { goToRankingSearchResult } = useAppNavigate();
  const [placeKeyword, setPlaceKeyword] = useState({
    placeKeyword: keyword,
    placeNameOrUrl: "",
  });

  const {} = useParams();
  // formValid 상태를 사용하여 폼의 전체 유효성을 관리합니다.
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState("");
  const handleChange = (e) => {
    const { name, value } = e.target;
    setPlaceKeyword((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.target.form;
    // form 내의 모든 입력값이 유효한지 검사
    if (!form.checkValidity()) {
      setModalContent(
        <p>
          입력 정보를 다시 확인해 주시기 바랍니다.
          <br />
          모든 필수 항목이 올바르게 작성되었는지 확인해 주세요.
        </p>
      );
      setIsModalOpen(true);

      return;
    }

    goToRankingSearchResult({
      placeNameOrUrl: placeKeyword.placeNameOrUrl,
      page: 1,
      limit: 50,
      keyword: placeKeyword.placeKeyword,
    });
  };

  // rankingSearchType이 없거나 DEFAULT인 경우 true를 반환하는 함수
  const isDefaultSearchType = () => {
    return (
      !rankingSearchType || rankingSearchType === RANKING_SEARCH_TYPE.DEFAULT
    );
  };

  return (
    <form className={styles.rankingSearchFormContainer}>
      <div className={`${styles.inputContainer} ${textAnimation.slideFadeUp}`}>
        {isDefaultSearchType() && (
          <RankingSearchInput
            label="플레이스 키워드"
            name="placeKeyword"
            value={placeKeyword.placeKeyword}
            onChange={handleChange}
            placeholder="지역+카테고리로 검색해보세요.(ex. 이태원맛집, 강남카페)"
            required={true}
            autoComplete="off"
          />
        )}

        <RankingSearchInput
          label={
            isDefaultSearchType()
              ? VALIDATIONS.placeOrUrl.label
              : VALIDATIONS.url.label
          }
          type={
            isDefaultSearchType()
              ? VALIDATIONS.placeOrUrl.type
              : VALIDATIONS.url.type
          }
          name="placeNameOrUrl"
          value={placeKeyword.placeNameOrUrl}
          onChange={handleChange}
          pattern={
            isDefaultSearchType()
              ? VALIDATIONS.placeOrUrl.pattern
              : VALIDATIONS.url.pattern
          }
          placeholder={
            isDefaultSearchType()
              ? VALIDATIONS.placeOrUrl.placeholder
              : VALIDATIONS.url.placeholder
          }
          required={true}
          autoComplete="off"
        />
      </div>

      <p
        className={`${styles.rankingSearchFormNote} ${textAnimation.slideFadeUp}`}
      >
        *음식점 카테고리 외엔 저장 수를 제공되지 않습니다.
        <br />
        *이름으로 검색 시 키워드를 지역+카테고리로 입력해야 업장 순위를 더
        정확하게 파악할 수 있어요.
      </p>

      <button
        className={`${styles.rankingSearchFormButton} ${textAnimation.slideFadeUp}`}
        onClick={handleSubmit}
        type="submit"
      >
        <SearchIcon />
        검색
      </button>
      <SingleConfirmModal
        open={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
        }}
        children={modalContent}
        cancelText="확인"
      ></SingleConfirmModal>
    </form>
  );
};

export default RankingSearchForm;
