import { membershipAxiosInstance } from "@utils/api/axios/membershipInstance";
import API_PATH from "@utils/constants/api/apiPath";
import { API_URL } from "@utils/constants/api/api";

export const subscribeMembershipWithNicePay = async (data) => {
  console.log("🚀 ~ subscribeMembershipWithNicePay ~ data:", data);
  try {
    console.log("🚀 ~ subscribeMembershipWithNicePay ~ data:", data);
    const response = await membershipAxiosInstance.post(
      `${API_URL}${API_PATH.subscribeMembershipWithNicePay}`,
      data
    );
    console.log("🚀 ~ subscribeMembershipWithNicePay ~ response:", response);
    console.log(
      "🚀 ~ subscribeMembershipWithNicePay ~ response.data:",
      response.data
    );
    return response.data;
  } catch (error) {
    console.error("🚀 ~ subscribeMembershipWithNicePay ~ error:", error);
    throw error;
  }
};

export const getMembershipInfo = async () => {
  try {
    const response = await membershipAxiosInstance.get(
      `${API_URL}${API_PATH.getMembershipInfo}`
    );
    return response.data;
  } catch (error) {
    console.error("🚀 ~ getMembershipInfo ~ error:", error);
    throw error;
  }
};

export const cancelMembership = async () => {
  try {
    const response = await membershipAxiosInstance.delete(
      `${API_URL}${API_PATH.cancelMembership}`
    );
    return response.data;
  } catch (error) {
    console.error("🚀 ~ cancelMembership ~ error:", error);
    throw error;
  }
};

export const exitUser = async () => {
  try {
    const response = await membershipAxiosInstance.delete(
      `${API_URL}${API_PATH.exitUser}`
    );
    return response.data;
  } catch (error) {
    console.error("🚀 ~ exitUser ~ error:", error);
    throw error;
  }
};
