import { useState, useRef, useEffect } from "react";

export const useCountdownTimer = (initialTime) => {
  const [time, setTime] = useState(initialTime);
  const timerIdRef = useRef(null);

  const startTimer = () => {
    setTime(initialTime);
    if (timerIdRef.current) {
      clearInterval(timerIdRef.current);
    }

    timerIdRef.current = setInterval(() => {
      setTime((prevTime) => {
        if (prevTime <= 1) {
          clearInterval(timerIdRef.current);
          return 0;
        }
        return prevTime - 1;
      });
    }, 1000);
  };

  const clearTimer = () => {
    clearInterval(timerIdRef.current);
    timerIdRef.current = null;
  };

  useEffect(() => {
    return () => clearTimer();
  }, []);

  return { time, startTimer, clearTimer };
}
